import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import homebanner from "./../Assets/home-page.jpg";
import homebanner1 from "./../Assets/RB.JPG";
import { FaBookReader } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa";
import { FaCertificate } from "react-icons/fa";
import sucessStroy from "./../Assets/success-story.jpg";
import { GrUserExpert } from "react-icons/gr";
import { BiSupport } from "react-icons/bi";
import { AiFillSafetyCertificate } from "react-icons/ai";
import "./Homepage.css";
import Footer from "../Footer/Footer";
import QuizComponents from "./QuizComponents";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";

const Homepage = () => {
  const [Courses, setCourses] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const fetchCourses = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/course/get_All_course`
      );
      console.log("Fetched courses:", data.Courses); // Inspect the data
      setCourses(data?.Courses);
    } catch (error) {
      console.error("Error fetching courses:", error);
      toast({
        title: "Error fetching courses.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const viewCourse = (_id) => {
    navigate(`/get_course/${_id}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  const calculateDiscountedPrice = (price, offer) => {
    if (typeof price !== "number" || typeof offer !== "number") {
      console.error("Invalid price or offer value");
      return price;
    }
    return price - (price * offer) / 100;
  };

  const isOfferValid = (offerDate) => {
    if (!offerDate) return false;
    const today = new Date();
    const offerEndDate = new Date(offerDate);
    return today <= offerEndDate;
  };

  return (
    <>
      <Box position="fixed" top="0" left="0" right="0" zIndex="sticky">
        <Navbar />
      </Box>
      <Box marginTop="90px">
        <Box>
          {/* <Image src={homebanner} /> */}
          <Image src={homebanner1} />
        </Box>

        <Box>
          {" "}
          <Container maxW="container.xl" p={4}>
            <Button colorScheme="yellow" fontSize="2x1" mb={4}>
              Our Courses
            </Button>
            <Grid
              templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
              gap={6}
            >
              {Courses &&
                Courses.map((course, index) => (
                  <GridItem
                    key={index}
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    cursor="pointer"
                  >
                    <Image
                      src={course.image || "placeholder.jpg"}
                      alt={course.name}
                      width="100%"
                      height="180px"
                      objectFit="cover"
                    />
                    <VStack p={2} align="stretch">
                      <Text fontSize="xl" fontWeight="bold">
                        {course.name.slice(0, 70)}
                      </Text>
                      <VStack align="left" display="inline">
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          textAlign="left"
                          display="flex"
                        >
                          Price:
                          <Text as="s" color="red" marginLeft="5px">
                            {" "}
                            {course.price} {course.currency}
                          </Text>
                          {isOfferValid(course.offerDate) && (
                            <p style={{ marginLeft: "10px", color: "green" }}>
                              {" "}
                              {calculateDiscountedPrice(
                                course.price,
                                course.offers
                              ).toFixed(2)}{" "}
                              {course.currency}
                            </p>
                          )}
                        </Text>
                      </VStack>
                      <Flex justifyContent="space-between">
                        <Button
                          colorScheme="teal"
                          width="100%"
                          onClick={() => {
                            if (userInfo) {
                              viewCourse(course.slug);
                            } else {
                              toast({
                                title: "Please log in to view this course.",
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                              });
                              window.location.href = "/login";
                            }
                          }}
                        >
                          View Course
                        </Button>
                      </Flex>
                    </VStack>
                  </GridItem>
                ))}
            </Grid>
          </Container>
        </Box>

        {/* this is our featurs area*/}
        <Box>
          <Center>
            <div className="row" style={{ width: "100%", paddingTop: "150px" }}>
              <Text textAlign="center" fontSize="xx-large" fontWeight="700">
                Our Features
              </Text>{" "}
              <div
                className="col-md-4"
                style={{
                  background: "#e5e5e5",
                  padding: "20px",
                }}
              >
                <Center>
                  {" "}
                  <FaBookReader
                    style={{
                      width: "100px",
                      height: "100px",
                      color: "#f0a500",
                    }}
                  />
                </Center>
                <Text fontSize="x-large">Expert Tutors</Text>
                <p>
                  Learn from industry experts who bring real-world experience
                  into the classroom.
                </p>
              </div>
              <div
                className="col-md-4"
                style={{
                  background: "#e5e5e5",
                  padding: "20px",
                }}
              >
                <Center>
                  <FaLaptopCode
                    style={{
                      width: "100px",
                      height: "100px",
                      color: "#f0a500",
                    }}
                  />
                </Center>

                <Text fontSize="x-large">Interactive Courses</Text>
                <p>
                  Engage in interactive courses designed to keep you motivated
                  and on track.
                </p>
              </div>
              <div
                className="col-md-4"
                style={{
                  background: "#e5e5e5",
                  padding: "20px",
                }}
              >
                <Center>
                  <FaCertificate
                    style={{
                      width: "100px",
                      height: "100px",
                      color: "#f0a500",
                    }}
                  />
                </Center>
                <Text fontSize="x-large">Certified Programs</Text>
                <p>
                  Earn certificates to boost your resume and demonstrate your
                  skills with us.
                </p>
              </div>
            </div>
          </Center>
        </Box>

        {/* this is our Top Quiz area*/}
        <Box style={{ maxWidth: "100%", margin: "0 auto", padding: "10px" }}>
          <div className="row" style={{ width: "100%", paddingTop: "10px" }}>
            <QuizComponents />
          </div>
        </Box>

        {/* this is why chose us area*/}
        <Box style={{ maxWidth: "100%", margin: "0 auto", padding: "20px" }}>
          <div
            className="row chose-us"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: "100px",
            }}
          >
            <Text textAlign="center" fontSize="xx-large" fontWeight="700">
              Why Choose Us
            </Text>
            <div className="col-md-3" style={{ padding: "20px" }}>
              <Center>
                {" "}
                <GrUserExpert style={{ height: "100px", width: "100px" }} />
              </Center>
              <Text fontSize="x-large">Expertise</Text>
              <p>
                We have a team of experienced professionals who are experts in
                their fields, providing you with top-notch educational resources
                and guidance.
              </p>
            </div>
            <div className="col-md-3" style={{ padding: "20px" }}>
              <Center>
                <BiSupport style={{ height: "100px", width: "100px" }} />
              </Center>

              <Text fontSize="x-large">Support</Text>
              <p>
                Our dedicated support team is here to help you with any
                questions or issues you may have, ensuring a smooth learning
                experience.
              </p>
            </div>
            <div className="col-md-3" style={{ padding: "20px" }}>
              <Center>
                <AiFillSafetyCertificate
                  style={{ height: "100px", width: "100px" }}
                />
              </Center>

              <Text fontSize="x-large">Quality</Text>
              <p>
                We provide high-quality materials and tests that are regularly
                updated to keep up with the latest standards and trends in
                education.
              </p>
            </div>
          </div>
        </Box>

        {/* this is why chose us area*/}
        <Box style={{ maxWidth: "100%", margin: "0 auto", padding: "20px" }}>
          <div
            className="row chose-us"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: "100px",
            }}
          >
            <Text
              textAlign="center"
              fontSize="xx-large"
              fontWeight="700"
              paddingTop="60px"
            >
              Success Story
            </Text>
            <div className="col-md-2">
              <Image src={sucessStroy} width="100%" height="200px" />
            </div>
            <div className="col-md-8" style={{ padding: "20px" }}>
              <p>
                After struggling with traditional learning methods, we
                discovered GeniusGrid. The personalized learning paths and
                interactive tools transformed our study habits. Within months,
                our grades improved dramatically, and we regained our confidence
              </p>
              <p>
                Our journey with GeniusGrid didn't just help us excel
                academically; it also reignited our passion for learning. Today,
                we are not only a top student but also an enthusiastic advocate
                for innovative education, inspiring our peers to join GeniusGrid
                and experience the same success.
              </p>
            </div>
          </div>
        </Box>

        {/* this is learn more about uss area*/}
        <Box style={{ maxWidth: "100%", margin: "0 auto", padding: "20px" }}>
          <div
            className="row chose-us"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: "30px",
              paddingBottom: "30px",
            }}
          >
            <Text
              textAlign="center"
              fontSize="xx-large"
              fontWeight="700"
              paddingTop="60px"
            >
              Learn More About Us
            </Text>
            <Center>
              <div
                style={{
                  textAlign: "center",
                  width: "100vw",
                  height: "100vh",
                  marginTop: "20px",
                }}
              >
                <iframe
                  src="https://www.youtube.com/embed/KgcVsemXvk0?si=ubt9e6ACwntKMXE0"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ width: "100%", height: "100%" }}
                ></iframe>
              </div>
            </Center>
          </div>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Homepage;
