import React, { useState } from "react";
import {
  Box,
  Heading,
  Input,
  Button,
  Flex,
  useToast,
  Text,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { FaFacebook, FaTwitter, FaWhatsapp, FaCopy } from "react-icons/fa";

const Referral = () => {
  const [referralLink, setReferralLink] = useState(
    "https://geniusgrid.in/login"
  );
  const toast = useToast();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    toast({
      title: "Link copied!",
      description: "Referral link copied to clipboard.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box maxWidth="100%" height="100vh" p={4} textAlign="center">
      <Heading as="h1" mb={4}>
        Refer Your Friends
      </Heading>
      <Text mb={4}>
        Share your referral link with friends and earn rewards!
      </Text>
      <Input
        value={referralLink}
        isReadOnly
        mb={2}
        placeholder="Your referral link"
        size="md"
      />
      <Flex justify="center" mb={4}>
        <Button
          colorScheme="blue"
          onClick={copyToClipboard}
          leftIcon={<FaCopy />}
        >
          Copy Link
        </Button>
      </Flex>
      <Text mb={2}>Or share via:</Text>
      <Flex justify="center" gap={4}>
        <Tooltip label="Share on Facebook" aria-label="Share on Facebook">
          <IconButton
            as="a"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              referralLink
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            icon={<FaFacebook />}
            aria-label="Share on Facebook"
          />
        </Tooltip>
        <Tooltip label="Share on Twitter" aria-label="Share on Twitter">
          <IconButton
            as="a"
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
              referralLink
            )}&text=Check out this awesome service!`}
            target="_blank"
            rel="noopener noreferrer"
            icon={<FaTwitter />}
            aria-label="Share on Twitter"
          />
        </Tooltip>
        <Tooltip label="Share on WhatsApp" aria-label="Share on WhatsApp">
          <IconButton
            as="a"
            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
              referralLink
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            icon={<FaWhatsapp />}
            aria-label="Share on WhatsApp"
          />
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default Referral;
