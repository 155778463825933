import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-4">
              {" "}
              <div className="test-series">
                <h2>Test Series</h2>
                <p>
                  Join our comprehensive test series to prepare for your exams
                  with confidence. Our test series are designed by experts and
                  cover a wide range of topics to ensure you are well-prepared.
                </p>
                {/* <Link to="/test-seris" className="test-series-button">
                  Explore Test Series
                </Link> */}
              </div>
            </div>
            <div className="col-md-2">
              {" "}
              <div className="footer-section about-us">
                <h3>About Us</h3>
                <p>
                  GeniusGrid is committed to providing top-notch educational
                  resources to help students achieve their goals.
                </p>
              </div>
            </div>
            <div className="col-md-2">
              {" "}
              <div className="footer-section support">
                <h3>Support</h3>
                <ul>
                  <li>
                    <Link to="/about-us">About us</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Help Center</Link>
                  </li>
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Terms of Service</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              {" "}
              <div className="footer-section follow-us">
                <h3>Follow Us</h3>
                <ul className="social-media">
                  <li>
                    <a href="https://facebook.com" target="_blank">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com" target="_blank">
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a href="https://linkedin.com" target="_blank">
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2">
              {" "}
              <div className="footer-section contact">
                <h3>Contact</h3>
                <p>
                  Email:{" "}
                  <Link to="mailto:info@geniusgrid.in">
                    <p>info@geniusgrid.in</p>
                  </Link>
                </p>
                <p>
                  Phone:{""}
                  <Link to="tel:+919430715717">
                    <p>+91 9430715717</p>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2024 GeniusGrid. All Rights Reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
