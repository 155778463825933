import React, { useEffect } from "react";
import "./About.css";
import aboutbanner from "./../Assets/about-us6.jpg";
import { Box, Center } from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import mission2 from "./../Assets/mission2.png";
import vision2 from "./../Assets/vision2.png";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Box position="fixed" top="0" left="0" right="0" zIndex="sticky">
        <Navbar />
      </Box>{" "}
      <Box marginTop="90px">
        {" "}
        <div>
          {" "}
          <div className="about-us-container">
            <img src={aboutbanner} className="about-banner" />
          </div>
          <Center>
            {" "}
            <section className="about-focus">
              <img src={mission2} alt="Success Story" className="about-image" />
              <div className="about-para">
                <p>
                  At GeniusGrid, our mission is to provide quality education
                  accessible to all. We strive to create a platform where
                  learners can find the resources and support they need to
                  achieve their academic and professional goals.
                </p>
              </div>
            </section>{" "}
          </Center>
          <Center>
            <section className="about-focus">
              <img src={vision2} alt="Success Story" className="about-image" />
              <div className="about-para">
                <p>
                  At GeniusGrid, our vision is to revolutionize education by
                  providing accessible, high-quality learning experiences for
                  students of all backgrounds. We aim to bridge educational gaps
                  by offering a comprehensive, up-to-date curriculum that is
                  thoughtfully curated to meet the unique needs of students from
                  classes 5 to 12. Our commitment extends beyond just delivering
                  knowledge; we strive to create personalized learning journeys
                  that adapt to each student's pace and style, facilitated by
                  advanced technology and dedicated educators. We believe in
                  fostering an environment of integrity, inclusivity, and
                  excellence, where every learner has the opportunity to thrive
                  academically and personally.
                </p>
              </div>
            </section>
          </Center>
          <Center>
            <section className="about-focus">
              <div className="about-para">
                <h2>What We Offer </h2>
                <p1>Comprehensive Courses:</p1>
                <p>
                  We provide a wide range of courses covering essential subjects
                  for students from classes 5 to 12. Our curriculum is designed
                  to be both engaging and informative, ensuring that students
                  grasp key concepts effectively.
                </p>
                <p1>Live Classes and Webinars:</p1>
                <p>
                  Students can participate in live classes and webinars
                  conducted by expert teachers, allowing them to learn in
                  real-time and get their questions answered on the spot.
                </p>
                <p1>Doubt Clearing Sessions:</p1>
                <p>
                  We offer regular doubt-clearing sessions where students can
                  get personalized help on challenging topics, ensuring they
                  never fall behind.
                </p>
                <p1>Community and Support:</p1>
                <p>
                  Our platform fosters a strong sense of community among
                  learners, encouraging collaboration and peer support. Students
                  can join study groups, participate in discussions, and share
                  resources.
                </p>
              </div>
            </section>
          </Center>
        </div>
      </Box>
      <Footer />
    </>
  );
};

export default Aboutus;
