import React, { useEffect, useState } from "react";
import testimage from "./../Assets/testimage.jpg";
import axios from "axios";
import * as mod from "./../../url";
import {
  Box,
  Center,
  Image,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
  Heading,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

const quizzes = {
  gd1: [
    {
      question: "What is the next number in the sequence: 2, 6, 12, 20, ?",
      options: ["30", "28", "24", "22"],
      correct_answer: 0,
    },
    {
      question:
        "If the ratio of the ages of A and B is 3:4 and the sum of their ages is 56 years, what is B's age?",
      options: ["32 years", "34 years", "36 years", "30 years"],
      correct_answer: 0,
    },
    {
      question:
        "What is the area of a circle with a radius of 7 cm? (Use π = 22/7)",
      options: ["154 sq cm", "144 sq cm", "138 sq cm", "150 sq cm"],
      correct_answer: 0,
    },
    {
      question:
        "A man walks 10 km north and then 6 km east. How far is he from the starting point?",
      options: ["12 km", "10 km", "8 km", "14 km"],
      correct_answer: 0,
    },
    {
      question: "Which of the following numbers is a prime number?",
      options: ["29", "21", "35", "42"],
      correct_answer: 0,
    },
    {
      question: "What is the value of 3^3 - 2^2?",
      options: ["19", "20", "21", "22"],
      correct_answer: 0,
    },
    {
      question:
        "A clock shows the time as 3:15. What is the angle between the hour and minute hands?",
      options: ["7.5 degrees", "22.5 degrees", "30 degrees", "45 degrees"],
      correct_answer: 1,
    },
    {
      question:
        "Which of the following is the largest prime number less than 50?",
      options: ["47", "43", "41", "37"],
      correct_answer: 0,
    },
    {
      question:
        "If a boat covers 12 km upstream in 2 hours and the same distance downstream in 1 hour, what is the speed of the boat in still water?",
      options: ["6 km/h", "7 km/h", "8 km/h", "10 km/h"],
      correct_answer: 0,
    },
    {
      question: "Which Indian state has the longest coastline?",
      options: ["Gujarat", "Maharashtra", "Tamil Nadu", "Kerala"],
      correct_answer: 0,
    },
    {
      question:
        "The average of five numbers is 20. If one number is excluded, the average of the remaining four numbers is 18. What is the excluded number?",
      options: ["22", "24", "26", "28"],
      correct_answer: 1,
    },
    {
      question: "Find the value of x if 4x - 5 = 3x + 6",
      options: ["11", "12", "13", "14"],
      correct_answer: 0,
    },
    {
      question: "What is the LCM of 12 and 18?",
      options: ["36", "24", "30", "48"],
      correct_answer: 0,
    },
    {
      question: "Which river is known as the 'Sorrow of Bengal'?",
      options: ["Ganges", "Yamuna", "Brahmaputra", "Hooghly"],
      correct_answer: 2,
    },
    {
      question: "Find the missing number in the series: 7, 14, 28, ?, 112",
      options: ["56", "49", "48", "63"],
      correct_answer: 0,
    },
    {
      question:
        "What is the probability of drawing a red card from a standard deck of 52 cards?",
      options: ["1/2", "1/4", "1/3", "1/6"],
      correct_answer: 0,
    },
    {
      question: "What is the value of 7! / (5! × 2!)?",
      options: ["21", "35", "42", "56"],
      correct_answer: 3,
    },
    {
      question:
        "If the sum of the squares of two numbers is 340 and their product is 84, find the sum of the two numbers.",
      options: ["20", "22", "24", "26"],
      correct_answer: 1,
    },
    {
      question: "What is the square root of 144?",
      options: ["12", "14", "16", "18"],
      correct_answer: 0,
    },
    {
      question:
        "A student scores 60%, 70%, and 80% in three subjects. What is the average percentage?",
      options: ["70%", "72%", "74%", "75%"],
      correct_answer: 1,
    },
    {
      question: "If 2x - 3 = 5(x + 1) - 4x, what is the value of x?",
      options: ["-2", "2", "4", "-4"],
      correct_answer: 0,
    },
    {
      question:
        "A man invests in two schemes, one offering 10% simple interest and the other 12% simple interest. If he invests $2000 in each scheme, what is the total interest earned in one year?",
      options: ["$440", "$400", "$420", "$460"],
      correct_answer: 2,
    },
    {
      question: "What is the value of (3^4 - 2^5) / 5?",
      options: ["8", "6", "4", "10"],
      correct_answer: 0,
    },
    {
      question: "Find the value of x if log_2(x) + log_2(x - 3) = 5",
      options: ["31", "30", "32", "33"],
      correct_answer: 0,
    },
    {
      question:
        "A rectangle has its length increased by 20% and its width decreased by 10%. What is the percentage change in the area of the rectangle?",
      options: ["8% increase", "12% decrease", "10% increase", "10% decrease"],
      correct_answer: 3,
    },
    {
      question:
        "If the sum of the squares of two numbers is 170 and their difference is 6, what is their product?",
      options: ["60", "72", "80", "84"],
      correct_answer: 3,
    },
    {
      question:
        "A train 150 meters long passes a pole in 15 seconds. What is the speed of the train in km/h?",
      options: ["36 km/h", "54 km/h", "60 km/h", "72 km/h"],
      correct_answer: 1,
    },
    {
      question:
        "What is the median of the following data set: 12, 15, 22, 29, 30, 35, 40, 50?",
      options: ["26.5", "29", "30", "27.5"],
      correct_answer: 3,
    },
    {
      question:
        "A sum of money is invested at 8% simple interest. After 4 years, the interest earned is $240. What is the principal amount?",
      options: ["$750", "$800", "$850", "$900"],
      correct_answer: 1,
    },
    {
      question:
        "In a class, the average weight of 12 students is 45 kg. If the weight of the teacher is also included, the average weight becomes 44 kg. What is the weight of the teacher?",
      options: ["50 kg", "60 kg", "55 kg", "70 kg"],
      correct_answer: 3,
    },
    {
      question:
        "The ratio of two numbers is 5:7 and their difference is 18. What is the larger number?",
      options: ["63", "56", "54", "60"],
      correct_answer: "0",
    },
    {
      question:
        "What is the sum of the first 15 terms of the arithmetic series 3, 7, 11, ...?",
      options: ["990", "1050", "1200", "1155"],
      correct_answer: "3",
    },
    {
      question: "If 2x - 3y = 6 and 4x + 3y = 18, what is the value of x + y?",
      options: ["7", "8", "9", "10"],
      correct_answer: "0",
    },
    {
      question:
        "Find the value of x in the quadratic equation x^2 - 6x + 8 = 0.",
      options: ["2 and 4", "3 and 5", "1 and 8", "2 and 6"],
      correct_answer: "0",
    },
    {
      question:
        "In a right-angled triangle, if one angle is 30 degrees and the hypotenuse is 10 cm, what is the length of the opposite side?",
      options: ["5 cm", "6 cm", "7 cm", "8 cm"],
      correct_answer: "0",
    },
    {
      question:
        "What is the volume of a cylinder with radius 4 cm and height 10 cm? (Use π = 3.14)",
      options: ["502.4 cm³", "320 cm³", "400 cm³", "314 cm³"],
      correct_answer: "0",
    },
    {
      question: "If 2^x = 64, what is the value of x?",
      options: ["4", "5", "6", "7"],
      correct_answer: "2",
    },
    {
      question:
        "Find the number of ways to arrange the letters of the word 'SUCCESS'.",
      options: ["5040", "720", "1260", "10080"],
      correct_answer: "2",
    },
    {
      question:
        "A boat travels downstream at a speed of 15 km/h and upstream at a speed of 9 km/h. What is the speed of the boat in still water?",
      options: ["12 km/h", "13.5 km/h", "14 km/h", "11 km/h"],
      correct_answer: "0",
    },
    {
      question:
        "A man spends 25% of his salary on rent, 15% on food, and 10% on transportation. If he saves $1,500, what is his total salary?",
      options: ["$6,000", "$7,500", "$8,000", "$9,000"],
      correct_answer: "1",
    },
    {
      question:
        "A shopkeeper offers a discount of 20% on the marked price of an item. If the selling price of the item is $240, what was the marked price?",
      options: ["$300", "$320", "$280", "$350"],
      correct_answer: "0",
    },
    {
      question:
        "What is the probability of rolling a number greater than 4 on a standard six-sided die?",
      options: ["1/3", "1/2", "1/6", "2/3"],
      correct_answer: "0",
    },
    {
      question: "If 5^x = 125, what is the value of x?",
      options: ["2", "3", "4", "5"],
      correct_answer: "1",
    },
    {
      question:
        "The sum of the ages of a mother and her child is 40 years. If the mother’s age is twice that of her child, what is the age of the child?",
      options: ["10 years", "12 years", "15 years", "20 years"],
      correct_answer: "0",
    },
    {
      question:
        "A car travels 300 km in 5 hours. What is the average speed of the car in km/h?",
      options: ["50 km/h", "60 km/h", "70 km/h", "80 km/h"],
      correct_answer: "1",
    },
    {
      question:
        "In a certain code language, 'ROAD' is written as 'WLDZ'. How will 'HOUSE' be written in that code?",
      options: ["MQVSD", "KQTVD", "KQZVD", "LRYTE"],
      correct_answer: "1",
    },
    {
      question: "Which part of speech is the word 'Beautiful'?",
      options: ["Noun", "Verb", "Adjective", "Adverb"],
      correct_answer: "2",
    },
    {
      question:
        "A train running at 60 km/h crosses a pole in 9 seconds. What is the length of the train?",
      options: ["120 meters", "150 meters", "180 meters", "200 meters"],
      correct_answer: "1",
    },
    {
      question: "The currency of Japan is:",
      options: ["Yen", "Won", "Ringgit", "Baht"],
      correct_answer: "0",
    },
    {
      question: "Identify the odd one out: Copper, Aluminum, Silver, Wool",
      options: ["Copper", "Aluminum", "Silver", "Wool"],
      correct_answer: "3",
    },
    {
      question: "The synonym of the word 'Abandon' is:",
      options: ["Retain", "Embrace", "Desert", "Preserve"],
      correct_answer: "2",
    },
    {
      question: "Which of the following is the hardest natural substance?",
      options: ["Iron", "Diamond", "Gold", "Platinum"],
      correct_answer: "1",
    },
    {
      question: "What is the value of the expression 2(3x - 4) = 18?",
      options: ["x = 3", "x = 4", "x = 5", "x = 6"],
      correct_answer: "0",
    },
    {
      question: "Which Indian state has the largest coastline?",
      options: ["Gujarat", "Maharashtra", "Tamil Nadu", "Andhra Pradesh"],
      correct_answer: "0",
    },
    {
      question:
        "If a clock is showing 3:30, how many degrees will the minute hand travel in the next 15 minutes?",
      options: ["30 degrees", "60 degrees", "90 degrees", "180 degrees"],
      correct_answer: "1",
    },
    {
      question: "Choose the correctly spelled word.",
      options: ["Accomodate", "Accommodate", "Acommodate", "Acommodete"],
      correct_answer: "1",
    },
    {
      question: "Simplify: (5 + 2) × (8 - 3) ÷ 5",
      options: ["6", "7", "8", "9"],
      correct_answer: "0",
    },
    {
      question: "Which Mughal emperor built the Taj Mahal?",
      options: ["Akbar", "Aurangzeb", "Shah Jahan", "Jahangir"],
      correct_answer: "2",
    },
    {
      question: "The antonym of the word 'Benevolent' is:",
      options: ["Malevolent", "Kind", "Generous", "Humane"],
      correct_answer: "0",
    },
    {
      question: "If 3(x + 2) = 15, then x is:",
      options: ["3", "4", "5", "6"],
      correct_answer: "0",
    },
    {
      question: "Which Indian river is known as the 'Ganges of the South'?",
      options: ["Krishna", "Godavari", "Kaveri", "Tungabhadra"],
      correct_answer: "2",
    },
    {
      question:
        "Select the word that best expresses the meaning of 'Euphoria'.",
      options: ["Sadness", "Happiness", "Confusion", "Anxiety"],
      correct_answer: "1",
    },
    {
      question:
        "A shopkeeper marks his goods at 25% above the cost price and then gives a discount of 20% on the marked price. What is his overall gain or loss percentage?",
      options: ["5% gain", "5% loss", "10% gain", "10% loss"],
      correct_answer: "0",
    },
    {
      question:
        "The LCM of two numbers is 84, and their HCF is 14. If one of the numbers is 42, what is the other number?",
      options: ["28", "14", "21", "12"],
      correct_answer: "0",
    },
    {
      question:
        "Which of the following dynasties was associated with the construction of the famous Brihadeeswarar Temple?",
      options: ["Pallava", "Chola", "Pandya", "Chera"],
      correct_answer: "1",
    },
    {
      question:
        "In a certain code language, if 'FINGER' is written as 'QSLYVI', how will 'TOUCH' be written?",
      options: ["GTLFI", "GVXDR", "PSUHG", "HTOUC"],
      correct_answer: "2",
    },
    {
      question: "Choose the correct sentence:",
      options: [
        "He has been waiting since two hours.",
        "He has been waiting for two hours.",
        "He is waiting since two hours.",
        "He is waiting for two hours.",
      ],
      correct_answer: "1",
    },
    {
      question:
        "The ratio of the ages of A and B is 3:4. After 5 years, the ratio becomes 4:5. What is the present age of A?",
      options: ["15 years", "20 years", "25 years", "30 years"],
      correct_answer: "0",
    },
    {
      question:
        "Who among the following is known as the 'Father of Indian Renaissance'?",
      options: [
        "Raja Ram Mohan Roy",
        "Bal Gangadhar Tilak",
        "Mahatma Gandhi",
        "Swami Vivekananda",
      ],
      correct_answer: "0",
    },
    {
      question: "A sequence is given: 2, 6, 12, 20, 30, ?. What comes next?",
      options: ["42", "36", "52", "56"],
      correct_answer: "0",
    },
    {
      question: "Which of the following sentences uses 'much' correctly?",
      options: [
        "He doesn't have much friends.",
        "She has much work to do.",
        "There are much apples on the table.",
        "He ate much candies.",
      ],
      correct_answer: "1",
    },
    {
      question: "If sinθ = 1/2, what is the value of cosθ?",
      options: ["√3/2", "1/√2", "1/2", "1/√3"],
      correct_answer: "0",
    },
    {
      question:
        "Who was the first Indian woman to win a gold medal in the Asian Games?",
      options: ["P.T. Usha", "Karnam Malleswari", "Mary Kom", "Kamini Kaushal"],
      correct_answer: "0",
    },
    {
      question:
        "A cylindrical tank has a radius of 3 meters and a height of 7 meters. What is its volume?",
      options: [
        "198 cubic meters",
        "180 cubic meters",
        "189 cubic meters",
        "210 cubic meters",
      ],
      correct_answer: "2",
    },
    {
      question: "Which Mughal Emperor was known as 'Zinda Pir' (Living Saint)?",
      options: ["Babur", "Akbar", "Aurangzeb", "Shah Jahan"],
      correct_answer: "2",
    },
    {
      question:
        "A clock is set right at 6 a.m. It loses 16 minutes in 24 hours. What will be the true time when the clock indicates 5 a.m. on the 4th day?",
      options: ["6:00 a.m.", "7:00 a.m.", "7:10 a.m.", "7:20 a.m."],
      correct_answer: "1",
    },
    {
      question:
        "Choose the word which is opposite in meaning to the word 'Sparse'.",
      options: ["Thin", "Scanty", "Thick", "Dense"],
      correct_answer: "3",
    },
    {
      question: "Simplify: 81^(1/4) * 9^(1/2) * 3^(1/2)",
      options: ["27", "18", "9", "12"],
      correct_answer: "0",
    },
    {
      question:
        "Who among the following was the founder of the 'Servants of India Society'?",
      options: [
        "Dadabhai Naoroji",
        "Gopal Krishna Gokhale",
        "Lala Lajpat Rai",
        "Bipin Chandra Pal",
      ],
      correct_answer: "1",
    },
    {
      question:
        "In a certain code language, 'FIRE' is written as 'ERIF'. How is 'WATER' written in that code?",
      options: ["TAREW", "ERATW", "RETWA", "TAWER"],
      correct_answer: "2",
    },
    {
      question:
        "Choose the correct preposition to complete the sentence: 'She is looking forward _____ the trip.'",
      options: ["in", "for", "to", "at"],
      correct_answer: "2",
    },
    {
      question:
        "If the area of a square is 144 square meters, what is the perimeter of the square?",
      options: ["36 meters", "48 meters", "60 meters", "72 meters"],
      correct_answer: "1",
    },
    {
      question: "The author of the book 'The Argumentative Indian' is:",
      options: [
        "Amartya Sen",
        "Shashi Tharoor",
        "Chetan Bhagat",
        "Arundhati Roy",
      ],
      correct_answer: "0",
    },
  ],
  gd2: [
    {
      question:
        "The length of a rectangle is increased by 20% and its breadth is decreased by 20%. What will be the percentage change in its area?",
      options: ["0%", "4% decrease", "4% increase", "2% decrease"],
      correct_answer: "1",
    },
    {
      question: "Which of the following rivers flows through the Thar Desert?",
      options: ["Ganga", "Brahmaputra", "Luni", "Yamuna"],
      correct_answer: "2",
    },
    {
      question:
        "In a certain code language, 'BEFORE' is written as 'EBFOR'. How will 'AFTER' be written in that code?",
      options: ["AFTRE", "AERFT", "RFTAE", "EFART"],
      correct_answer: "3",
    },
    {
      question: "Identify the synonym of the word 'Abrogate'.",
      options: ["Enforce", "Abolish", "Institute", "Support"],
      correct_answer: "1",
    },
    {
      question:
        "The compound interest on a sum of money for 2 years at 5% per annum is Rs. 102.50. What is the sum?",
      options: ["Rs. 1000", "Rs. 1050", "Rs. 1100", "Rs. 1125"],
      correct_answer: "0",
    },
    {
      question:
        "Who among the following is credited with the discovery of the sea route to India?",
      options: [
        "Christopher Columbus",
        "Vasco da Gama",
        "Ferdinand Magellan",
        "Marco Polo",
      ],
      correct_answer: "1",
    },
    {
      question:
        "A clock gains 5 minutes every hour. What will be the angle between the hour and minute hand at 4:00 PM according to this clock?",
      options: ["120°", "130°", "140°", "150°"],
      correct_answer: "1",
    },
    {
      question: "Which of the following sentences is grammatically correct?",
      options: [
        "He goes to work by walk.",
        "He goes to work on foot.",
        "He goes to work with walking.",
        "He goes to work at foot.",
      ],
      correct_answer: "1",
    },
    {
      question: "If cosθ = 3/5, what is the value of sinθ?",
      options: ["4/5", "1/5", "3/4", "2/5"],
      correct_answer: "0",
    },
    {
      question: "Who was the first woman Governor of an Indian state?",
      options: [
        "Indira Gandhi",
        "Sarojini Naidu",
        "Vijaya Lakshmi Pandit",
        "Sucheta Kriplani",
      ],
      correct_answer: "1",
    },
    {
      question:
        "A sum of money at simple interest amounts to Rs. 720 in 2 years and Rs. 780 in 3 years. What is the principal amount?",
      options: ["Rs. 600", "Rs. 660", "Rs. 640", "Rs. 620"],
      correct_answer: "2",
    },
    {
      question:
        "Who among the following was the first Indian to receive the Nobel Prize?",
      options: [
        "C.V. Raman",
        "Rabindranath Tagore",
        "Hargobind Khorana",
        "Mother Teresa",
      ],
      correct_answer: "1",
    },
    {
      question:
        "If 3x - 2y = 5 and x + y = 4, then what is the value of 2x + 3y?",
      options: ["14", "15", "16", "17"],
      correct_answer: "2",
    },
    {
      question:
        "The Tropic of Cancer does not pass through which of the following Indian states?",
      options: ["Rajasthan", "Odisha", "Gujarat", "West Bengal"],
      correct_answer: "1",
    },
    {
      question:
        "Which of the following will be the best antonym for the word 'Elucidate'?",
      options: ["Clarify", "Complicate", "Illustrate", "Explain"],
      correct_answer: "1",
    },
    {
      question:
        "A number when divided by 4 gives a remainder 3. What will be the remainder when the square of this number is divided by 4?",
      options: ["0", "1", "2", "3"],
      correct_answer: "1",
    },
    {
      question:
        "Who was the Viceroy of India at the time of the Jallianwala Bagh Massacre?",
      options: [
        "Lord Curzon",
        "Lord Irwin",
        "Lord Chelmsford",
        "Lord Hardinge",
      ],
      correct_answer: "2",
    },
    {
      question:
        "In a certain code language, '123' means 'sky is blue', '345' means 'blue is ocean', and '367' means 'ocean is deep'. What does '4' stand for?",
      options: ["sky", "blue", "is", "ocean"],
      correct_answer: "1",
    },
    {
      question: "Identify the correctly punctuated sentence.",
      options: [
        "I bought apples oranges, and bananas.",
        "I bought apples, oranges and bananas.",
        "I bought apples, oranges, and bananas.",
        "I bought apples oranges and bananas.",
      ],
      correct_answer: "2",
    },
    {
      question: "The average of first 50 natural numbers is:",
      options: ["25.5", "25", "26", "24.5"],
      correct_answer: "0",
    },
    {
      question:
        "A person sold a chair at a gain of 15%. Had it been sold for Rs. 27 more, the profit would have been 20%. What is the cost price of the chair?",
      options: ["Rs. 540", "Rs. 500", "Rs. 600", "Rs. 580"],
      correct_answer: "1",
    },
    {
      question: "Who was the first Indian astronaut to go to space?",
      options: [
        "Sunita Williams",
        "Rakesh Sharma",
        "Kalpana Chawla",
        "Ravish Malhotra",
      ],
      correct_answer: "1",
    },
    {
      question:
        "In a row of students, John is 10th from the left and Peter is 15th from the right. If they interchange their positions, John becomes 25th from the left. What is Peter's new position from the right?",
      options: ["10th", "25th", "15th", "20th"],
      correct_answer: "0",
    },
    {
      question: "Choose the correct spelling.",
      options: ["Accommodate", "Acommodate", "Acommodete", "Acomodate"],
      correct_answer: "0",
    },
    {
      question: "If a:b = 3:4 and b:c = 2:5, then what is the ratio of a:c?",
      options: ["3:10", "2:7", "6:20", "5:6"],
      correct_answer: "0",
    },
    {
      question: "Which Mughal Emperor built the Buland Darwaza?",
      options: ["Akbar", "Aurangzeb", "Shah Jahan", "Babur"],
      correct_answer: "0",
    },
    {
      question:
        "A and B together can complete a piece of work in 8 days. B alone can do it in 12 days. How many days will A alone take to complete the work?",
      options: ["18 days", "24 days", "16 days", "20 days"],
      correct_answer: "1",
    },
    {
      question: "What is the chemical formula of Nitric Acid?",
      options: ["HCl", "HNO3", "H2SO4", "CH3COOH"],
      correct_answer: "1",
    },
    {
      question:
        "If EFGH is coded as VUTS in a certain language, how will ABCD be coded?",
      options: ["ZYXW", "WXYZ", "XVYZ", "YZWX"],
      correct_answer: "0",
    },
    {
      question: "Choose the most appropriate synonym for 'Obsolete'.",
      options: ["Modern", "Current", "Outdated", "Recent"],
      correct_answer: "2",
    },
    {
      question:
        "If 12 men can complete a work in 15 days, how many days will it take for 9 men to complete the same work?",
      options: ["18 days", "20 days", "16 days", "14 days"],
      correct_answer: "0",
    },
    {
      question:
        "Who among the following was the last Governor-General of independent India?",
      options: [
        "Lord Mountbatten",
        "C. Rajagopalachari",
        "Dr. Rajendra Prasad",
        "Sardar Vallabhbhai Patel",
      ],
      correct_answer: "1",
    },
    {
      question:
        "In a certain code, 'MANGO' is written as 'NAOMG'. How will 'APPLE' be written in that code?",
      options: ["PAELP", "PALPE", "LAPPE", "LEPAP"],
      correct_answer: "1",
    },
    {
      question: "Which of the following statements is grammatically correct?",
      options: [
        "She are my friend.",
        "He don't like apples.",
        "They has gone to the market.",
        "She does her work well.",
      ],
      correct_answer: "3",
    },
    {
      question:
        "The average of five consecutive odd numbers is 55. What is the smallest of these numbers?",
      options: ["49", "51", "53", "47"],
      correct_answer: "3",
    },
    {
      question: "Which of the following was the first Indian satellite?",
      options: ["Bhaskara", "Aryabhata", "Rohini", "INSAT-1A"],
      correct_answer: "1",
    },
    {
      question:
        "The sum of two numbers is 30, and their product is 216. What are the numbers?",
      options: ["18 and 12", "14 and 16", "20 and 10", "15 and 15"],
      correct_answer: "0",
    },
    {
      question: "Who was the first Indian woman to win the Miss World title?",
      options: [
        "Reita Faria",
        "Aishwarya Rai",
        "Priyanka Chopra",
        "Sushmita Sen",
      ],
      correct_answer: "0",
    },
    {
      question:
        "Which of the following is the highest civilian award in India?",
      options: [
        "Padma Shri",
        "Padma Bhushan",
        "Padma Vibhushan",
        "Bharat Ratna",
      ],
      correct_answer: "3",
    },
    {
      question:
        "The difference between a two-digit number and the number obtained by interchanging its digits is 36. What is the difference between the two digits?",
      options: ["3", "4", "2", "6"],
      correct_answer: "1",
    },
    {
      question:
        "A shopkeeper offers a discount of 20% on the marked price of an item and still makes a profit of 25%. If the marked price is Rs. 1500, what is the cost price of the item?",
      options: ["Rs. 1000", "Rs. 1050", "Rs. 1100", "Rs. 1200"],
      correct_answer: "0",
    },
    {
      question: "Who was the first Vice-President of India?",
      options: [
        "Sarvepalli Radhakrishnan",
        "Zakir Husain",
        "Rajendra Prasad",
        "V. V. Giri",
      ],
      correct_answer: "0",
    },
    {
      question: "If 5x + 3y = 26 and 3x - 5y = 2, then what is the value of x?",
      options: ["3", "4", "2", "5"],
      correct_answer: "1",
    },
    {
      question: "Choose the correct synonym for 'Insolent'.",
      options: ["Respectful", "Arrogant", "Shy", "Friendly"],
      correct_answer: "1",
    },
    {
      question:
        "A, B, and C can complete a task in 20, 24, and 30 days, respectively. If they work together, in how many days will they complete the task?",
      options: ["10 days", "8 days", "12 days", "9 days"],
      correct_answer: "3",
    },
    {
      question: "Which Indian state has the longest coastline?",
      options: ["Maharashtra", "Tamil Nadu", "Gujarat", "Andhra Pradesh"],
      correct_answer: "2",
    },
    {
      question:
        "A person can row 9 km/hr in still water. If the river is flowing at 3 km/hr, and it takes 1.5 hours for the person to row to a place and come back, how far is the place?",
      options: ["4.5 km", "5.4 km", "6 km", "3.5 km"],
      correct_answer: "0",
    },
    {
      question: "The Battle of Plassey was fought in which year?",
      options: ["1764", "1757", "1784", "1776"],
      correct_answer: "1",
    },
    {
      question:
        "In a certain code language, 'CIRCLE' is written as 'EKVPJC'. How will 'SQUARE' be written in that code?",
      options: ["WUVEFI", "UVTFSD", "USVFRC", "WTXGFI"],
      correct_answer: "0",
    },
    {
      question: "Choose the most appropriate antonym for 'Diligent'.",
      options: ["Lazy", "Hardworking", "Industrious", "Energetic"],
      correct_answer: "0",
    },
    {
      question:
        "If x = 2, y = 3, and z = 4, then what is the value of 2x^2 + 3y^2 + 4z^2?",
      options: ["54", "59", "69", "74"],
      correct_answer: "2",
    },
    {
      question:
        "Which Indian Prime Minister initiated the Green Revolution in India?",
      options: [
        "Indira Gandhi",
        "Lal Bahadur Shastri",
        "Jawaharlal Nehru",
        "Rajiv Gandhi",
      ],
      correct_answer: "1",
    },
    {
      question:
        "The LCM of two numbers is 240, and their HCF is 12. If one of the numbers is 48, what is the other number?",
      options: ["60", "72", "120", "96"],
      correct_answer: "2",
    },
    {
      question:
        "Which of the following is a non-metal that remains liquid at room temperature?",
      options: ["Phosphorus", "Bromine", "Chlorine", "Mercury"],
      correct_answer: "1",
    },
    {
      question:
        "In a certain code, 'MOTHER' is written as 'NOUIFS'. How will 'FATHER' be written in that code?",
      options: ["GBUIFS", "GBSJFS", "GBSJFT", "GBUJFT"],
      correct_answer: "0",
    },
    {
      question: "Choose the correct sentence.",
      options: [
        "He has gave me a book.",
        "She have eaten lunch.",
        "They has done the work.",
        "She has done her homework.",
      ],
      correct_answer: "3",
    },
    {
      question: "What is the value of x if 2^x = 64?",
      options: ["5", "6", "7", "8"],
      correct_answer: "1",
    },
    {
      question: "Which of the following rulers is known as 'Sher-e-Punjab'?",
      options: [
        "Maharaja Ranjit Singh",
        "Guru Gobind Singh",
        "Bhagat Singh",
        "Lala Lajpat Rai",
      ],
      correct_answer: "0",
    },
    {
      question: "If 5a = 60, then what is the value of 2a + 3?",
      options: ["27", "24", "25", "26"],
      correct_answer: "3",
    },
    {
      question: "Which is the smallest prime number?",
      options: ["1", "2", "3", "5"],
      correct_answer: "1",
    },
    {
      question:
        "A train travels 120 km at a speed of 80 km/hr. How much time will it take to cover the remaining 180 km at a speed of 60 km/hr?",
      options: ["3 hours", "4 hours", "5 hours", "6 hours"],
      correct_answer: "1",
    },
    {
      question:
        "The Governor of which state has the power to dissolve the Legislative Assembly?",
      options: ["Maharashtra", "Karnataka", "West Bengal", "Rajasthan"],
      correct_answer: "0",
    },
    {
      question:
        "What is the term used for a word that is spelled the same forward and backward?",
      options: ["Anagram", "Palindrome", "Antonym", "Synonym"],
      correct_answer: "1",
    },
    {
      question: "What is the antonym of 'Luminous'?",
      options: ["Bright", "Dim", "Shiny", "Radiant"],
      correct_answer: "1",
    },
    {
      question:
        "A number is increased by 20% and then by another 10%. What is the overall percentage increase?",
      options: ["30%", "32%", "34%", "28%"],
      correct_answer: "1",
    },
    {
      question: "Who was the first female Prime Minister of India?",
      options: [
        "Indira Gandhi",
        "Pratibha Patil",
        "Sarojini Naidu",
        "Meira Kumar",
      ],
      correct_answer: "0",
    },
    {
      question: "What is the value of sin(45°) + cos(45°)?",
      options: ["√2", "1", "√3", "2"],
      correct_answer: "0",
    },
    {
      question: "The currency of which country is the 'Ringgit'?",
      options: ["Thailand", "Malaysia", "Indonesia", "Philippines"],
      correct_answer: "1",
    },
    {
      question: "Who is known as the 'Father of the Indian Constitution'?",
      options: [
        "Jawaharlal Nehru",
        "B. R. Ambedkar",
        "Sardar Vallabhbhai Patel",
        "Mahatma Gandhi",
      ],
      correct_answer: "1",
    },
    {
      question:
        "In a code language, 'BOOK' is written as 'CPLL'. How is 'PEN' written in that code?",
      options: ["QFO", "QFQ", "RFO", "QFO"],
      correct_answer: "0",
    },
    {
      question:
        "In a certain code, 'FAMOUS' is written as 'EBLNRT'. How will 'GOVERN' be written in that code?",
      options: ["FNUFQM", "FPUFQO", "FPQFQO", "FQNFQM"],
      correct_answer: "0",
    },
    {
      question: "Which of the following elements is a noble gas?",
      options: ["Oxygen", "Nitrogen", "Neon", "Hydrogen"],
      correct_answer: "2",
    },
    {
      question: "The Grand Canyon is located in which country?",
      options: ["Canada", "USA", "Australia", "India"],
      correct_answer: "1",
    },
    {
      question:
        "A piece of work is completed by 10 men in 15 days. If 5 more men join them, how many days will they take to complete the work?",
      options: ["7.5 days", "10 days", "8 days", "6 days"],
      correct_answer: "3",
    },
    {
      question: "Which planet is known as the 'Red Planet'?",
      options: ["Earth", "Mars", "Jupiter", "Saturn"],
      correct_answer: "1",
    },
    {
      question: "Who invented the telephone?",
      options: [
        "Alexander Graham Bell",
        "Thomas Edison",
        "Nikola Tesla",
        "Michael Faraday",
      ],
      correct_answer: "0",
    },
    {
      question: "The term 'ecology' was coined by which scientist?",
      options: [
        "Charles Darwin",
        "Ernst Haeckel",
        "Gregor Mendel",
        "Louis Pasteur",
      ],
      correct_answer: "1",
    },
    {
      question: "Which is the longest river in the world?",
      options: ["Amazon", "Nile", "Yangtze", "Mississippi"],
      correct_answer: "1",
    },
    {
      question: "Which Indian state is known as the 'Land of Five Rivers'?",
      options: ["Punjab", "Haryana", "Uttar Pradesh", "Himachal Pradesh"],
      correct_answer: "0",
    },
    {
      question: "If the area of a square is 144 cm², what is its perimeter?",
      options: ["36 cm", "48 cm", "24 cm", "30 cm"],
      correct_answer: "1",
    },
    {
      question:
        "A car travels 180 km at a speed of 60 km/hr and then travels 240 km at a speed of 80 km/hr. What is the average speed of the car for the entire journey?",
      options: ["66.67 km/hr", "70 km/hr", "72 km/hr", "75 km/hr"],
      correct_answer: "0",
    },
    {
      question:
        "The first ten amendments to the U.S. Constitution are collectively known as what?",
      options: [
        "The Bill of Rights",
        "The Magna Carta",
        "The Federalist Papers",
        "The Declaration of Independence",
      ],
      correct_answer: "0",
    },
    {
      question:
        "In which city is the headquarters of the International Monetary Fund (IMF) located?",
      options: ["London", "Paris", "Washington D.C.", "New York"],
      correct_answer: "2",
    },
    {
      question:
        "Which famous scientist developed the theory of general relativity?",
      options: [
        "Isaac Newton",
        "Albert Einstein",
        "Niels Bohr",
        "Galileo Galilei",
      ],
      correct_answer: "1",
    },
    {
      question: "What is the sum of the first 50 positive integers?",
      options: ["1225", "1275", "2500", "2550"],
      correct_answer: "1",
    },
    {
      question:
        "Which planet is known as the 'Morning Star' or 'Evening Star'?",
      options: ["Venus", "Mercury", "Mars", "Jupiter"],
      correct_answer: "0",
    },
    {
      question:
        "The famous painting 'Starry Night' was created by which artist?",
      options: [
        "Pablo Picasso",
        "Claude Monet",
        "Vincent van Gogh",
        "Leonardo da Vinci",
      ],
      correct_answer: "2",
    },
    {
      question: "Which is the largest organ in the human body?",
      options: ["Heart", "Liver", "Skin", "Lungs"],
      correct_answer: "2",
    },
    {
      question: "In which year did the Titanic sink?",
      options: ["1912", "1914", "1916", "1918"],
      correct_answer: "0",
    },
    {
      question: "What is the square root of 225?",
      options: ["15", "20", "25", "30"],
      correct_answer: "0",
    },
    {
      question:
        "Which Indian leader is known for the slogan 'Jai Jawan Jai Kisan'?",
      options: [
        "Jawaharlal Nehru",
        "Lal Bahadur Shastri",
        "Mahatma Gandhi",
        "Indira Gandhi",
      ],
      correct_answer: "1",
    },
    {
      question: "Which of the following is the longest river in India?",
      options: ["Yamuna", "Ganges", "Godavari", "Brahmaputra"],
      correct_answer: "3",
    },
    {
      question: "What is the chemical symbol for Gold?",
      options: ["Ag", "Au", "Pb", "Fe"],
      correct_answer: "1",
    },
    {
      question:
        "Which language is primarily spoken in the Indian state of Kerala?",
      options: ["Kannada", "Malayalam", "Tamil", "Telugu"],
      correct_answer: "1",
    },
    {
      question: "The famous play 'Hamlet' was written by which playwright?",
      options: [
        "Christopher Marlowe",
        "William Shakespeare",
        "George Bernard Shaw",
        "Tennessee Williams",
      ],
      correct_answer: "1",
    },
    {
      question: "What is the boiling point of water in Celsius?",
      options: ["90°C", "95°C", "100°C", "105°C"],
      correct_answer: "2",
    },
    {
      question:
        "Which famous scientist is known for the laws of motion and universal gravitation?",
      options: [
        "Galileo Galilei",
        "Isaac Newton",
        "Nikola Tesla",
        "Albert Einstein",
      ],
      correct_answer: "1",
    },
    {
      question:
        "The Great Wall of China was built primarily to protect against invasions from which group?",
      options: ["Mongols", "Huns", "Persians", "Japanese"],
      correct_answer: "0",
    },
    {
      question:
        "Which Indian state is known for its annual boat race called 'Vallamkali'?",
      options: ["Karnataka", "Kerala", "Tamil Nadu", "Andhra Pradesh"],
      correct_answer: "1",
    },
    {
      question: "What is the capital city of Japan?",
      options: ["Tokyo", "Kyoto", "Osaka", "Hiroshima"],
      correct_answer: "0",
    },
  ],
  ipsclerk1: [
    {
      question:
        "The sum of the ages of A and B is 50 years. Five years ago, the ratio of their ages was 5:3. What is the present age of A?",
      options: ["30 years", "35 years", "40 years", "45 years"],
      correct_answer: "1",
    },
    {
      question:
        "Which of the following is most nearly opposite in meaning to 'Infallible'?",
      options: ["Erroneous", "Flawless", "Unerring", "Reliable"],
      correct_answer: "0",
    },
    {
      question:
        "A sum of money amounts to ₹1,452 in 2 years at 10% per annum compound interest. What was the principal amount?",
      options: ["₹1,200", "₹1,250", "₹1,300", "₹1,350"],
      correct_answer: "2",
    },
    {
      question:
        "Who among the following is known as the 'Nightingle of India'?",
      options: [
        "Sarojini Naidu",
        "Indira Gandhi",
        "Lata Mangeshkar",
        "M.S. Subbulakshmi",
      ],
      correct_answer: "0",
    },
    {
      question: "If 2x + 3y = 10 and 4x - y = 2, what is the value of x?",
      options: ["1", "2", "3", "4"],
      correct_answer: "1",
    },
    {
      question: "Which of the following rivers does not flow through India?",
      options: ["Brahmaputra", "Indus", "Amazon", "Ganga"],
      correct_answer: "2",
    },
    {
      question:
        "In a certain code, 'MOON' is written as 'NPPM'. How is 'STARS' written in that code?",
      options: ["TUBST", "TURBT", "TUBRT", "TUBSR"],
      correct_answer: "2",
    },
    {
      question:
        "What is the least number that should be added to 2497 so that the sum becomes a perfect square?",
      options: ["2", "3", "4", "5"],
      correct_answer: "1",
    },
    {
      question: "Which of the following is not a mammal?",
      options: ["Dolphin", "Shark", "Whale", "Bat"],
      correct_answer: "1",
    },
    {
      question: "Simplify: 12 + 24 ÷ 3 × 2 - 8",
      options: ["24", "32", "16", "28"],
      correct_answer: "0",
    },
    {
      question:
        "The 'Statue of Unity' in India is dedicated to which of the following leaders?",
      options: [
        "Mahatma Gandhi",
        "Jawaharlal Nehru",
        "B.R. Ambedkar",
        "Sardar Vallabhbhai Patel",
      ],
      correct_answer: "3",
    },
    {
      question:
        "A man travels 2/5th of his journey at 30 km/h and the remaining 3/5th at 50 km/h. What is his average speed for the entire journey?",
      options: ["40 km/h", "37.5 km/h", "35 km/h", "42.5 km/h"],
      correct_answer: "0",
    },
    {
      question:
        "Which of the following countries is the largest producer of coffee?",
      options: ["India", "Brazil", "Colombia", "Vietnam"],
      correct_answer: "1",
    },
    {
      question: "If 8x = 16y, then x : y is:",
      options: ["2:1", "1:2", "1:4", "4:1"],
      correct_answer: "0",
    },
    {
      question: "Which of the following is the hardest mineral?",
      options: ["Gold", "Iron", "Diamond", "Platinum"],
      correct_answer: "2",
    },
    {
      question:
        "If the difference between the compound interest and simple interest on a certain sum for 2 years at 10% per annum is ₹16, what is the sum?",
      options: ["₹1,600", "₹1,800", "₹2,000", "₹2,200"],
      correct_answer: "2",
    },
    {
      question: "What is the chemical symbol for gold?",
      options: ["Gd", "Ga", "Au", "Ag"],
      correct_answer: "2",
    },
    {
      question:
        "A train 300 meters long is running at a speed of 90 km/h. How much time will it take to cross a platform 150 meters long?",
      options: ["10 seconds", "12 seconds", "15 seconds", "18 seconds"],
      correct_answer: "2",
    },
    {
      question:
        "Who among the following was the first Indian to win a Nobel Prize?",
      options: [
        "C.V. Raman",
        "Rabindranath Tagore",
        "Amartya Sen",
        "Mother Teresa",
      ],
      correct_answer: "1",
    },
    {
      question:
        "If the area of a square is 144 square meters, what is the length of its diagonal?",
      options: ["12√2 meters", "14 meters", "12 meters", "14√2 meters"],
      correct_answer: "0",
    },
    {
      question:
        "A certain amount of money doubles itself in 5 years at simple interest. What is the rate of interest?",
      options: ["10%", "12%", "15%", "20%"],
      correct_answer: "0",
    },
    {
      question: "Which of the following is a synonym of 'Meticulous'?",
      options: ["Careless", "Negligent", "Thorough", "Rude"],
      correct_answer: "2",
    },
    {
      question:
        "A shopkeeper sells an article at a discount of 10% on its marked price. If the marked price is ₹500, what is the selling price of the article?",
      options: ["₹450", "₹400", "₹475", "₹425"],
      correct_answer: "0",
    },
    {
      question: "Which element has the chemical symbol 'Hg'?",
      options: ["Hydrogen", "Mercury", "Gold", "Lead"],
      correct_answer: "1",
    },
    {
      question:
        "If a man walks 3 km East and then 4 km North, what is the shortest distance from his starting point?",
      options: ["5 km", "7 km", "6 km", "8 km"],
      correct_answer: "0",
    },
    {
      question:
        "In a class, 60% of students passed in English, 50% passed in Mathematics, and 20% passed in both. What percentage of students passed in at least one subject?",
      options: ["80%", "70%", "90%", "85%"],
      correct_answer: "0",
    },
    {
      question: "Which of the following is the smallest prime number?",
      options: ["1", "2", "3", "5"],
      correct_answer: "1",
    },
    {
      question: "What is the capital of Canada?",
      options: ["Toronto", "Vancouver", "Ottawa", "Montreal"],
      correct_answer: "2",
    },
    {
      question:
        "If the sum of three consecutive odd numbers is 75, what is the smallest of these numbers?",
      options: ["23", "25", "27", "29"],
      correct_answer: "1",
    },
    {
      question: "Which of the following words is spelled incorrectly?",
      options: ["Occasion", "Definite", "Receieve", "Privilege"],
      correct_answer: "2",
    },
    {
      question: "What is the perimeter of a square with a side length of 8 cm?",
      options: ["32 cm", "16 cm", "24 cm", "64 cm"],
      correct_answer: "0",
    },
    {
      question: "Who is the author of the book 'The Alchemist'?",
      options: ["Paulo Coelho", "J.K. Rowling", "George Orwell", "Dan Brown"],
      correct_answer: "0",
    },
    {
      question:
        "If a person walks at 4 km/h, he reaches his destination 30 minutes late. If he walks at 5 km/h, he reaches 10 minutes early. What is the distance to his destination?",
      options: ["8 km", "10 km", "12 km", "15 km"],
      correct_answer: "1",
    },
    {
      question:
        "What is the ratio of the number of sides of a hexagon to that of a pentagon?",
      options: ["5:4", "6:5", "4:3", "5:3"],
      correct_answer: "1",
    },
    {
      question: "Which city is known as the 'City of Joy'?",
      options: ["Delhi", "Mumbai", "Kolkata", "Chennai"],
      correct_answer: "2",
    },
    {
      question: "Which planet is known as the 'Red Planet'?",
      options: ["Mars", "Jupiter", "Saturn", "Venus"],
      correct_answer: "0",
    },
    {
      question: "What is the next number in the sequence: 2, 6, 12, 20, ...?",
      options: ["30", "36", "42", "48"],
      correct_answer: "0",
    },
    {
      question:
        "The area of a rectangle is 120 square meters and its length is 12 meters. What is its width?",
      options: ["8 meters", "10 meters", "15 meters", "20 meters"],
      correct_answer: "1",
    },
    {
      question: "Which is the largest ocean in the world?",
      options: [
        "Atlantic Ocean",
        "Indian Ocean",
        "Arctic Ocean",
        "Pacific Ocean",
      ],
      correct_answer: "3",
    },
    {
      question: "Which of the following is a compound?",
      options: ["Hydrogen", "Oxygen", "Carbon Dioxide", "Helium"],
      correct_answer: "2",
    },
    {
      question:
        "In a mixture of 60 liters, the ratio of milk to water is 2:1. How much water should be added to the mixture to make the ratio of milk to water 1:2?",
      options: ["60 liters", "40 liters", "30 liters", "20 liters"],
      correct_answer: "0",
    },
    {
      question: "Who is known as the 'Father of Economics'?",
      options: ["Karl Marx", "Adam Smith", "David Ricardo", "Alfred Marshall"],
      correct_answer: "1",
    },
    {
      question: "What is the next term in the series: 3, 7, 15, 31, ...?",
      options: ["62", "63", "64", "65"],
      correct_answer: "1",
    },
    {
      question:
        "A train travels 60 km at a speed of 30 km/h and another 60 km at a speed of 60 km/h. What is the average speed of the train for the entire journey?",
      options: ["40 km/h", "45 km/h", "50 km/h", "55 km/h"],
      correct_answer: "0",
    },
    {
      question: "Which of the following correctly defines a 'glacier'?",
      options: [
        "A large mass of ice and snow moving slowly over land",
        "A large river in a desert",
        "An ice sheet on the ocean",
        "A snowstorm in the Arctic",
      ],
      correct_answer: "0",
    },
    {
      question:
        "If the radius of a circle is increased by 50%, how much does the area increase?",
      options: ["100%", "125%", "150%", "200%"],
      correct_answer: "1",
    },
    {
      question: "What is the chemical formula for common salt?",
      options: ["NaCl", "KCl", "Na2SO4", "NaNO3"],
      correct_answer: "0",
    },
    {
      question: "Who is the current Chief Justice of India (as of 2024)?",
      options: [
        "Sharad Arvind Bobde",
        "N.V. Ramana",
        "D.Y. Chandrachud",
        "Ranjan Gogoi",
      ],
      correct_answer: "2",
    },
    {
      question:
        "The angle of elevation of the top of a tower from a point 30 meters away from its base is 45°. What is the height of the tower?",
      options: ["30 meters", "45 meters", "15 meters", "60 meters"],
      correct_answer: "0",
    },
    {
      question:
        "Which of the following is the largest gland in the human body?",
      options: ["Pancreas", "Liver", "Thyroid", "Pituitary"],
      correct_answer: "1",
    },
    {
      question:
        "If 5 men or 7 women can do a piece of work in 10 days, how long will 2 men and 3 women take to do the same work?",
      options: ["8 days", "10 days", "12 days", "14 days"],
      correct_answer: "1",
    },
    {
      question: "What is the capital of Australia?",
      options: ["Sydney", "Melbourne", "Canberra", "Perth"],
      correct_answer: "2",
    },
    {
      question:
        "If a discount of 20% is given on the marked price of an article and still a profit of 25% is made, what is the cost price of the article if the marked price is ₹400?",
      options: ["₹240", "₹250", "₹280", "₹300"],
      correct_answer: "1",
    },
    {
      question:
        "Who among the following was the first Indian to win a Nobel Prize?",
      options: [
        "Rabindranath Tagore",
        "C.V. Raman",
        "Hargobind Khorana",
        "Mother Teresa",
      ],
      correct_answer: "0",
    },
    {
      question:
        "A sum of money amounts to ₹12,000 in 2 years and ₹15,000 in 4 years at simple interest. What is the principal?",
      options: ["₹10,000", "₹9,000", "₹11,000", "₹8,000"],
      correct_answer: "0",
    },
    {
      question: "Which of the following is a compound sentence?",
      options: [
        "She studied hard, but she failed the exam.",
        "Although she studied hard, she failed the exam.",
        "Because she studied hard, she passed the exam.",
        "She studied hard to pass the exam.",
      ],
      correct_answer: "0",
    },
    {
      question: "What is the smallest two-digit prime number?",
      options: ["11", "13", "17", "19"],
      correct_answer: "0",
    },
    {
      question: "Which of the following is not a physical change?",
      options: [
        "Melting of ice",
        "Boiling of water",
        "Rusting of iron",
        "Evaporation of water",
      ],
      correct_answer: "2",
    },
    {
      question:
        "In a triangle, if one angle is twice the other and the third angle is 60°, what is the smallest angle?",
      options: ["20°", "30°", "40°", "60°"],
      correct_answer: "1",
    },
    {
      question: "Which of the following is an antonym of 'Sporadic'?",
      options: ["Frequent", "Rare", "Infrequent", "Occasional"],
      correct_answer: "0",
    },
    {
      question:
        "If the product of two numbers is 216 and their HCF is 6, what is the LCM of the two numbers?",
      options: ["18", "36", "72", "108"],
      correct_answer: "2",
    },
    {
      question: "Which Indian city is known as the 'City of Pearls'?",
      options: ["Jaipur", "Kochi", "Hyderabad", "Surat"],
      correct_answer: "2",
    },
    {
      question: "What is the next term in the series: 2, 6, 12, 20, 30, ...?",
      options: ["42", "44", "46", "48"],
      correct_answer: "0",
    },
    {
      question: "Which of the following is the correct meaning of 'Ebullient'?",
      options: ["Depressed", "Cheerful", "Indifferent", "Hostile"],
      correct_answer: "1",
    },
    {
      question: "What is the smallest 5-digit number divisible by 9?",
      options: ["10008", "10017", "10026", "10035"],
      correct_answer: "0",
    },
    {
      question:
        "Which of the following statements is correct regarding the atmosphere?",
      options: [
        "The atmosphere is thicker at the poles.",
        "The atmosphere is thicker at the equator.",
        "The atmosphere is uniform in thickness across the Earth.",
        "The thickness of the atmosphere varies randomly.",
      ],
      correct_answer: "1",
    },
    {
      question:
        "If a merchant sells 40% of his goods at a profit of 20% and the remaining at the cost price, what is his overall profit percentage?",
      options: ["8%", "10%", "12%", "15%"],
      correct_answer: "1",
    },
    {
      question: "Who is the author of the book 'Midnight's Children'?",
      options: [
        "Arundhati Roy",
        "Salman Rushdie",
        "R.K. Narayan",
        "Vikram Seth",
      ],
      correct_answer: "1",
    },
    {
      question: "What is the value of π (pi) up to two decimal places?",
      options: ["3.12", "3.14", "3.16", "3.18"],
      correct_answer: "1",
    },
    {
      question: "What is the currency of Japan?",
      options: ["Won", "Yuan", "Yen", "Rupee"],
      correct_answer: "2",
    },
    {
      question:
        "Which of the following is the smallest planet in our solar system?",
      options: ["Venus", "Mars", "Mercury", "Pluto"],
      correct_answer: "2",
    },
    {
      question: "Which element is most abundant in the Earth's atmosphere?",
      options: ["Oxygen", "Nitrogen", "Hydrogen", "Carbon Dioxide"],
      correct_answer: "1",
    },
    {
      question: "What is the capital of France?",
      options: ["Rome", "Paris", "Berlin", "Madrid"],
      correct_answer: "1",
    },
    {
      question: "Which of the following numbers is a perfect square?",
      options: ["45", "49", "50", "55"],
      correct_answer: "1",
    },
    {
      question: "Who was the first woman Prime Minister of India?",
      options: [
        "Indira Gandhi",
        "Sarojini Naidu",
        "Pratibha Patil",
        "Sonia Gandhi",
      ],
      correct_answer: "0",
    },
    {
      question: "What is the atomic number of carbon?",
      options: ["4", "6", "8", "12"],
      correct_answer: "1",
    },
    {
      question: "Which of the following is a renewable resource?",
      options: ["Coal", "Petroleum", "Natural Gas", "Solar Energy"],
      correct_answer: "3",
    },
    {
      question: "Which river is known as the 'Sorrow of Bihar'?",
      options: ["Ganges", "Kosi", "Yamuna", "Ghaghara"],
      correct_answer: "1",
    },
    {
      question: "In which year did the Indian Constitution come into effect?",
      options: ["1947", "1948", "1950", "1951"],
      correct_answer: "2",
    },
    {
      question: "Which of the following is an example of an amphibian?",
      options: ["Frog", "Snake", "Shark", "Eagle"],
      correct_answer: "0",
    },
    {
      question: "Which is the smallest bone in the human body?",
      options: ["Femur", "Stapes", "Ulna", "Patella"],
      correct_answer: "1",
    },
    {
      question: "Which of the following is a vestigial organ in humans?",
      options: ["Liver", "Pancreas", "Appendix", "Lungs"],
      correct_answer: "2",
    },
    {
      question: "Which planet is known for its rings?",
      options: ["Jupiter", "Mars", "Saturn", "Uranus"],
      correct_answer: "2",
    },
    {
      question: "Who was the first President of India?",
      options: [
        "Dr. B.R. Ambedkar",
        "Jawaharlal Nehru",
        "Rajendra Prasad",
        "Sardar Vallabhbhai Patel",
      ],
      correct_answer: "2",
    },
    {
      question: "In which year was the Battle of Plassey fought?",
      options: ["1757", "1764", "1775", "1782"],
      correct_answer: "0",
    },
    {
      question: "Which of the following is a simile?",
      options: [
        "As brave as a lion",
        "He is a lion",
        "He roared like a lion",
        "A lion among men",
      ],
      correct_answer: "0",
    },
    {
      question: "Who discovered the electron?",
      options: [
        "Isaac Newton",
        "Albert Einstein",
        "J.J. Thomson",
        "Ernest Rutherford",
      ],
      correct_answer: "2",
    },
    {
      question: "What is the square root of 64?",
      options: ["6", "7", "8", "9"],
      correct_answer: "2",
    },
    {
      question: "Which of the following is an ionic compound?",
      options: ["H2O", "O2", "NaCl", "CO2"],
      correct_answer: "2",
    },
    {
      question: "What is the chemical formula for water?",
      options: ["H2O", "CO2", "O2", "H2O2"],
      correct_answer: "0",
    },
    {
      question: "Which continent is known as the 'Dark Continent'?",
      options: ["Africa", "Asia", "Europe", "Australia"],
      correct_answer: "0",
    },
    {
      question: "Who invented the telephone?",
      options: [
        "Thomas Edison",
        "Alexander Graham Bell",
        "Nikola Tesla",
        "Guglielmo Marconi",
      ],
      correct_answer: "1",
    },
    {
      question: "What is the boiling point of water at sea level?",
      options: ["0°C", "50°C", "100°C", "150°C"],
      correct_answer: "2",
    },
    {
      question: "What is the currency of the United Kingdom?",
      options: ["Euro", "Pound Sterling", "Dollar", "Yen"],
      correct_answer: "1",
    },
    {
      question: "Which is the largest desert in the world?",
      options: ["Sahara", "Gobi", "Kalahari", "Antarctica"],
      correct_answer: "3",
    },
    {
      question: "Who was the first man to walk on the moon?",
      options: [
        "Yuri Gagarin",
        "Buzz Aldrin",
        "Michael Collins",
        "Neil Armstrong",
      ],
      correct_answer: "3",
    },
    {
      question: "Which of the following is a greenhouse gas?",
      options: ["Nitrogen", "Oxygen", "Carbon Dioxide", "Helium"],
      correct_answer: "2",
    },
    {
      question: "What is the capital of Italy?",
      options: ["Venice", "Rome", "Milan", "Florence"],
      correct_answer: "1",
    },
    {
      question: "Which of the following is not a primary color?",
      options: ["Red", "Blue", "Green", "Yellow"],
      correct_answer: "2",
    },
    {
      question: "What is the chemical symbol for gold?",
      options: ["Au", "Ag", "Pt", "Pb"],
      correct_answer: "0",
    },
    {
      question: "Who wrote the play 'Romeo and Juliet'?",
      options: [
        "Christopher Marlowe",
        "William Shakespeare",
        "Ben Jonson",
        "John Milton",
      ],
      correct_answer: "1",
    },
    {
      question:
        "What is the primary ingredient in traditional Japanese miso soup?",
      options: ["Tofu", "Soybeans", "Rice", "Seaweed"],
      correct_answer: "1",
    },
    {
      question: "What is the hardest natural substance on Earth?",
      options: ["Gold", "Iron", "Diamond", "Quartz"],
      correct_answer: "2",
    },
    {
      question: "Which element has the highest melting point?",
      options: ["Iron", "Tungsten", "Carbon", "Platinum"],
      correct_answer: "1",
    },
    {
      question: "What is the most abundant gas in the Earth's atmosphere?",
      options: ["Oxygen", "Nitrogen", "Carbon Dioxide", "Hydrogen"],
      correct_answer: "1",
    },
    {
      question: "Who is the author of 'Pride and Prejudice'?",
      options: [
        "Jane Austen",
        "Charlotte Bronte",
        "Emily Bronte",
        "Mary Shelley",
      ],
      correct_answer: "0",
    },
    {
      question: "What is the capital of Canada?",
      options: ["Toronto", "Vancouver", "Ottawa", "Montreal"],
      correct_answer: "2",
    },
    {
      question: "Which of the following is an example of an igneous rock?",
      options: ["Marble", "Slate", "Granite", "Limestone"],
      correct_answer: "2",
    },
    {
      question: "Who discovered penicillin?",
      options: [
        "Alexander Fleming",
        "Louis Pasteur",
        "Robert Koch",
        "Edward Jenner",
      ],
      correct_answer: "0",
    },
    {
      question: "Which vitamin is known as the 'sunshine vitamin'?",
      options: ["Vitamin A", "Vitamin B", "Vitamin C", "Vitamin D"],
      correct_answer: "3",
    },
    [
      {
        question:
          "Which of the following gases is the primary component of natural gas?",
        options: ["Ethane", "Butane", "Methane", "Propane"],
        correct_answer: "2",
      },
      {
        question: "What is the meaning of the idiom 'Bite the bullet'?",
        options: [
          "To face a difficult situation bravely",
          "To avoid a situation",
          "To speak without thinking",
          "To make a quick decision",
        ],
        correct_answer: "0",
      },
      {
        question: "What is the next term in the series: 7, 14, 28, 56, ...?",
        options: ["112", "224", "448", "336"],
        correct_answer: "0",
      },
      {
        question:
          "Which of the following is the largest planet in our solar system?",
        options: ["Earth", "Jupiter", "Saturn", "Mars"],
        correct_answer: "1",
      },
      {
        question:
          "In a race of 200 meters, A beats B by 20 meters and B beats C by 10 meters. By how many meters does A beat C?",
        options: ["30 meters", "28 meters", "29 meters", "27 meters"],
        correct_answer: "2",
      },
    ],
  ],
  ipsclerk2: [
    {
      question:
        "A sum of money is invested at a rate of 5% per annum compounded annually. If the amount after 2 years is ₹11025, what is the principal amount?",
      options: ["₹10000", "₹10500", "₹9500", "₹12000"],
      correct_answer: "0",
    },
    {
      question:
        "The ratio of the ages of two persons is 5:7. If the sum of their ages is 48 years, what is the age of the older person?",
      options: ["28 years", "30 years", "32 years", "34 years"],
      correct_answer: "0",
    },
    {
      question: "Which of the following is the largest desert in the world?",
      options: [
        "Sahara Desert",
        "Gobi Desert",
        "Kalahari Desert",
        "Arabian Desert",
      ],
      correct_answer: "0",
    },
    {
      question: "What is the term for the study of plants?",
      options: ["Zoology", "Botany", "Geology", "Astronomy"],
      correct_answer: "1",
    },
    {
      question:
        "A shopkeeper sells an article at a 20% profit. If the selling price of the article is ₹600, what was the cost price?",
      options: ["₹500", "₹450", "₹480", "₹550"],
      correct_answer: "0",
    },
    {
      question: "Which planet is known for its rings?",
      options: ["Saturn", "Jupiter", "Mars", "Uranus"],
      correct_answer: "0",
    },
    {
      question: "What is the square root of 144?",
      options: ["10", "12", "14", "16"],
      correct_answer: "1",
    },
    {
      question: "Who is known as the 'Iron Man of India'?",
      options: [
        "Jawaharlal Nehru",
        "Sardar Vallabhbhai Patel",
        "Mahatma Gandhi",
        "Subhash Chandra Bose",
      ],
      correct_answer: "1",
    },
    {
      question:
        "If 8 workers can complete a task in 12 days, how many days will it take for 6 workers to complete the same task?",
      options: ["14 days", "16 days", "18 days", "20 days"],
      correct_answer: "1",
    },
    {
      question: "Which of the following is a programming language?",
      options: ["HTML", "CSS", "JavaScript", "Photoshop"],
      correct_answer: "2",
    },
    {
      question: "In which year did India gain independence?",
      options: ["1942", "1947", "1950", "1952"],
      correct_answer: "1",
    },
    {
      question:
        "The area of a rectangle is 60 square meters and its length is 12 meters. What is the width of the rectangle?",
      options: ["5 meters", "6 meters", "7 meters", "8 meters"],
      correct_answer: "1",
    },
    {
      question: "Which ocean is the largest in the world?",
      options: [
        "Atlantic Ocean",
        "Indian Ocean",
        "Arctic Ocean",
        "Pacific Ocean",
      ],
      correct_answer: "3",
    },
    {
      question: "What is the chemical symbol for Gold?",
      options: ["Au", "Ag", "Pb", "Fe"],
      correct_answer: "0",
    },
    {
      question:
        "A car travels from point A to point B at a speed of 80 km/h and from point B to point C at a speed of 60 km/h. If the distance from A to C is 180 km, what is the average speed of the car for the entire journey?",
      options: ["66.67 km/h", "70 km/h", "72 km/h", "75 km/h"],
      correct_answer: "0",
    },
    {
      question: "Which of the following rivers is the longest?",
      options: ["Nile", "Amazon", "Yangtze", "Mississippi"],
      correct_answer: "0",
    },
    {
      question: "If 12 apples cost ₹60, what is the cost of 18 apples?",
      options: ["₹90", "₹100", "₹110", "₹120"],
      correct_answer: "0",
    },
    {
      question: "In which city is the famous landmark 'Eiffel Tower' located?",
      options: ["London", "Paris", "Rome", "Berlin"],
      correct_answer: "1",
    },
    {
      question: "Which element is represented by the chemical symbol 'Na'?",
      options: ["Sodium", "Nitrogen", "Neon", "Nickel"],
      correct_answer: "0",
    },
    {
      question:
        "What is the term for a word that has the same meaning as another word?",
      options: ["Antonym", "Synonym", "Homonym", "Pseudonym"],
      correct_answer: "1",
    },
    {
      question:
        "A train travels at a speed of 90 km/h. How long will it take to travel 225 km?",
      options: ["2.5 hours", "3 hours", "4 hours", "5 hours"],
      correct_answer: "0",
    },
    {
      question: "Which country is known as the 'Land of the Rising Sun'?",
      options: ["China", "South Korea", "Japan", "Thailand"],
      correct_answer: "2",
    },
    {
      question:
        "If a sum of ₹2000 amounts to ₹2400 in 2 years at simple interest, what is the rate of interest per annum?",
      options: ["10%", "12%", "15%", "20%"],
      correct_answer: "1",
    },
    {
      question: "In which year was the United Nations founded?",
      options: ["1941", "1945", "1950", "1960"],
      correct_answer: "1",
    },
    {
      question: "What is the capital city of Canada?",
      options: ["Toronto", "Vancouver", "Ottawa", "Montreal"],
      correct_answer: "2",
    },
    {
      question:
        "Which of the following is an example of a non-renewable resource?",
      options: ["Solar Energy", "Wind Energy", "Natural Gas", "Biomass"],
      correct_answer: "2",
    },
    {
      question: "What is the largest organ in the human body?",
      options: ["Heart", "Liver", "Skin", "Lungs"],
      correct_answer: "2",
    },
    {
      question:
        "If a person divides ₹144 into two parts such that one part is 1.5 times the other, what is the difference between the two parts?",
      options: ["₹24", "₹30", "₹36", "₹40"],
      correct_answer: "2",
    },
    {
      question: "Which gas is most abundant in the Earth's atmosphere?",
      options: ["Oxygen", "Carbon Dioxide", "Nitrogen", "Argon"],
      correct_answer: "2",
    },
    {
      question: "What is the term used for the study of animals?",
      options: ["Botany", "Zoology", "Ecology", "Anatomy"],
      correct_answer: "1",
    },
    {
      question:
        "The sum of the ages of a father and his son is 50 years. After 10 years, the age of the father will be twice the age of the son. What are their present ages?",
      options: [
        "Father: 30 years, Son: 20 years",
        "Father: 35 years, Son: 15 years",
        "Father: 40 years, Son: 10 years",
        "Father: 45 years, Son: 5 years",
      ],
      correct_answer: "0",
    },
    {
      question: "In which country is the Great Barrier Reef located?",
      options: ["Australia", "New Zealand", "USA", "South Africa"],
      correct_answer: "0",
    },
    {
      question: "Which element has the atomic number 1?",
      options: ["Hydrogen", "Helium", "Lithium", "Oxygen"],
      correct_answer: "0",
    },
    {
      question: "What is the currency of Japan?",
      options: ["Yuan", "Won", "Yen", "Ringgit"],
      correct_answer: "2",
    },
    {
      question:
        "If a rectangle has a length of 10 meters and a width of 6 meters, what is its area?",
      options: [
        "60 square meters",
        "70 square meters",
        "80 square meters",
        "90 square meters",
      ],
      correct_answer: "0",
    },
    {
      question: "What is the chemical symbol for Iron?",
      options: ["Fe", "Ir", "In", "I"],
      correct_answer: "0",
    },
    {
      question: "Who wrote the play 'Romeo and Juliet'?",
      options: [
        "William Shakespeare",
        "Charles Dickens",
        "George Orwell",
        "Jane Austen",
      ],
      correct_answer: "0",
    },
    {
      question:
        "If 5 workers can complete a task in 8 days, how many days will it take for 10 workers to complete the same task?",
      options: ["4 days", "5 days", "6 days", "7 days"],
      correct_answer: "0",
    },
    {
      question:
        "Which is the smallest unit of an element that retains the properties of that element?",
      options: ["Molecule", "Atom", "Ion", "Electron"],
      correct_answer: "1",
    },
    {
      question: "Which of the following is the longest river in India?",
      options: ["Ganges", "Yamuna", "Godavari", "Brahmaputra"],
      correct_answer: "3",
    },
    {
      question:
        "In a certain code, 'PENCIL' is written as 'QFODMJ'. How is 'ERASER' written in that code?",
      options: ["FSBTFS", "FTCBTF", "FSDTFS", "FTCDFS"],
      correct_answer: "1",
    },
    {
      question:
        "If the ratio of the ages of A and B is 4:5, and the ratio of the ages of B and C is 5:6, what is the ratio of the ages of A and C?",
      options: ["2:3", "4:5", "8:9", "4:6"],
      correct_answer: "2",
    },
    {
      question:
        "In a class of 40 students, the average score of boys is 75 and that of girls is 85. If the number of boys is twice the number of girls, what is the average score of the entire class?",
      options: ["77.5", "78", "79", "80"],
      correct_answer: "1",
    },
    {
      question:
        "The sum of the squares of two consecutive even integers is 340. What are the integers?",
      options: ["10 and 12", "12 and 14", "14 and 16", "16 and 18"],
      correct_answer: "1",
    },
    {
      question: "Which among the following numbers is a perfect square?",
      options: ["123", "144", "155", "189"],
      correct_answer: "1",
    },
    {
      question:
        "A train travels from A to B at a speed of 90 km/h and returns at a speed of 60 km/h. What is the average speed of the train for the entire journey?",
      options: ["72 km/h", "70 km/h", "75 km/h", "65 km/h"],
      correct_answer: "0",
    },
    {
      question:
        "A man spends 20% of his salary on rent, 15% on groceries, and 10% on utilities. If he has ₹12000 left after these expenses, what is his total salary?",
      options: ["₹40000", "₹50000", "₹60000", "₹70000"],
      correct_answer: "2",
    },
    {
      question:
        "In a deck of 52 cards, what is the probability of drawing a heart or a king?",
      options: ["1/4", "7/52", "13/52", "4/13"],
      correct_answer: "0",
    },
    {
      question:
        "A number is increased by 25% and then decreased by 20%. What is the net percentage change in the number?",
      options: ["5% increase", "10% decrease", "10% increase", "5% decrease"],
      correct_answer: "3",
    },
    {
      question:
        "A person can do a piece of work in 30 days. After working for 10 days, he is joined by another person who can do the same work in 20 days. How many days will they take to complete the remaining work?",
      options: ["10 days", "12 days", "15 days", "20 days"],
      correct_answer: "1",
    },
    {
      question:
        "Which is the smallest positive integer that is evenly divisible by all the numbers from 1 to 10?",
      options: ["2520", "1260", "1440", "3600"],
      correct_answer: "0",
    },
    {
      question:
        "A person walks 10 km towards the north, then 5 km towards the east. What is the straight line distance from his starting point to his final position?",
      options: ["10 km", "15 km", "20 km", "25 km"],
      correct_answer: "3",
    },
    {
      question:
        "In how many different ways can the letters of the word 'LOGARITHM' be arranged?",
      options: ["40320", "5040", "720", "360"],
      correct_answer: "0",
    },
    {
      question:
        "If a is inversely proportional to b and a = 10 when b = 5, what is the value of a when b = 10?",
      options: ["5", "10", "15", "20"],
      correct_answer: "0",
    },
    {
      question:
        "The average of 8, 10, 15, and x is 12. What is the value of x?",
      options: ["14", "16", "18", "20"],
      correct_answer: "0",
    },
    {
      question:
        "A factory produces 500 units per day. If the production increases by 20% every month, what will be the production after 3 months?",
      options: ["720 units", "800 units", "900 units", "1000 units"],
      correct_answer: "2",
    },
    {
      question:
        "If a right-angled triangle has sides of length 6 cm and 8 cm, what is the length of the hypotenuse?",
      options: ["10 cm", "12 cm", "14 cm", "16 cm"],
      correct_answer: "0",
    },
    {
      question:
        "Which of the following is the correct formula for compound interest when interest is compounded annually?",
      options: [
        "P(1 + r/n)^(nt)",
        "P(1 + r)^t",
        "P(1 + rt)",
        "P(1 + r/n)^(nt) - 1",
      ],
      correct_answer: "1",
    },
    {
      question:
        "A man walks 30 km at a speed of 6 km/h and then 40 km at a speed of 8 km/h. What is his average speed for the entire journey?",
      options: ["6.67 km/h", "7.5 km/h", "7.8 km/h", "8 km/h"],
      correct_answer: "1",
    },
    {
      question: "What is the value of 2^(3) * 3^(2) - 4^(2)?",
      options: ["24", "28", "32", "36"],
      correct_answer: "0",
    },
    {
      question:
        "A man buys a book for ₹150 and sells it at a loss of 20%. What is the selling price of the book?",
      options: ["₹120", "₹130", "₹140", "₹125"],
      correct_answer: "0",
    },
    {
      question:
        "The ratio of the ages of two brothers is 5:7. If the elder brother is 28 years old, what is the age of the younger brother?",
      options: ["20 years", "22 years", "24 years", "25 years"],
      correct_answer: "1",
    },
    {
      question:
        "If the perimeter of a square is 48 cm, what is the area of the square?",
      options: ["144 cm²", "64 cm²", "100 cm²", "36 cm²"],
      correct_answer: "0",
    },
    {
      question:
        "In a class of 50 students, 30% are boys. How many girls are there in the class?",
      options: ["20", "30", "35", "40"],
      correct_answer: "3",
    },
    {
      question: "What is the next number in the sequence: 2, 6, 12, 20, 30, ?",
      options: ["42", "44", "46", "48"],
      correct_answer: "0",
    },
    {
      question:
        "A car travels 180 km in 3 hours. What is the speed of the car in km/h?",
      options: ["55 km/h", "60 km/h", "65 km/h", "70 km/h"],
      correct_answer: "1",
    },
    {
      question: "Which of the following numbers is divisible by both 3 and 4?",
      options: ["48", "50", "52", "54"],
      correct_answer: "0",
    },
    {
      question:
        "A group of people can complete a task in 15 days. If 5 more people join the group, they can complete the task in 10 days. How many people were originally in the group?",
      options: ["10", "15", "20", "25"],
      correct_answer: "2",
    },
    {
      question:
        "The average of five numbers is 30. If one of the numbers is 45, what is the average of the remaining four numbers?",
      options: ["27", "28", "29", "30"],
      correct_answer: "1",
    },
    {
      question:
        "In a family of six members P, Q, R, S, T, and U, T is the grandfather of U. Q is the father of R, who is married to S. P is the sister of T. How is P related to S?",
      options: ["Mother", "Aunt", "Grandmother", "Sister-in-law"],
      correct_answer: "1",
    },
    {
      question:
        "A man travels 40 km at a speed of 4 km/h and then 60 km at a speed of 6 km/h. What is the average speed for the entire journey?",
      options: ["4.8 km/h", "5 km/h", "5.5 km/h", "6 km/h"],
      correct_answer: "0",
    },
    {
      question: "If 20% of a number is 80, what is the number?",
      options: ["200", "300", "400", "500"],
      correct_answer: "2",
    },
    {
      question:
        "The sum of the ages of a mother and her daughter is 42 years. If the mother is 4 times as old as the daughter, how old is the daughter?",
      options: ["8 years", "9 years", "10 years", "11 years"],
      correct_answer: "0",
    },
    {
      question:
        "In a certain code, 'PRAISE' is written as 'SBTJTF'. How is 'ORIGIN' written in that code?",
      options: ["PSJHJO", "PSJHOP", "PSJHPJ", "QTKJPK"],
      correct_answer: "0",
    },
    {
      question:
        "A mixture contains milk and water in the ratio of 4:1. How much water must be added to 10 liters of this mixture to make the ratio 2:1?",
      options: ["5 liters", "6 liters", "8 liters", "10 liters"],
      correct_answer: "2",
    },
    {
      question:
        "The area of a circle is 154 square meters. What is the radius of the circle? (Use π = 22/7)",
      options: ["7 meters", "14 meters", "21 meters", "28 meters"],
      correct_answer: "0",
    },
    {
      question: "If x + y = 10 and x - y = 4, what is the value of xy?",
      options: ["20", "24", "28", "32"],
      correct_answer: "1",
    },
    {
      question: "Which of the following is the largest number?",
      options: ["2^10", "3^6", "4^5", "5^4"],
      correct_answer: "2",
    },
    {
      question:
        "A number is divided by 7 and gives a remainder of 5. If the same number is divided by 5, what will be the remainder?",
      options: ["0", "1", "2", "3"],
      correct_answer: "3",
    },
    {
      question:
        "If a die is rolled twice, what is the probability that the sum of the numbers on the top faces is 7?",
      options: ["1/6", "5/36", "1/12", "7/36"],
      correct_answer: "3",
    },
    {
      question:
        "In a right triangle, one angle is 45 degrees. What is the measure of the other non-right angle?",
      options: ["30 degrees", "45 degrees", "60 degrees", "75 degrees"],
      correct_answer: "1",
    },
  ],
  cgl1: [
    {
      question: "Who was the first President of India?",
      options: [
        "Dr. Rajendra Prasad",
        "Dr. S. Radhakrishnan",
        "Mahatma Gandhi",
        "Jawaharlal Nehru",
      ],
      correct_answer: 0,
    },
    {
      question: "Which is the longest river in the world?",
      options: ["Nile", "Amazon", "Yangtze", "Mississippi"],
      correct_answer: 0,
    },
    {
      question: "What is the capital of Australia?",
      options: ["Sydney", "Melbourne", "Canberra", "Brisbane"],
      correct_answer: 2,
    },
    {
      question: "Which element has the chemical symbol 'O'?",
      options: ["Oxygen", "Gold", "Osmium", "Oganesson"],
      correct_answer: 0,
    },
    {
      question: "Who wrote the national anthem of India?",
      options: [
        "Bankim Chandra Chatterjee",
        "Rabindranath Tagore",
        "Mahatma Gandhi",
        "Subhas Chandra Bose",
      ],
      correct_answer: 1,
    },
    {
      question: "What is the largest planet in our solar system?",
      options: ["Earth", "Mars", "Jupiter", "Saturn"],
      correct_answer: 2,
    },
    {
      question: "In which year did India gain independence?",
      options: ["1945", "1946", "1947", "1948"],
      correct_answer: 2,
    },
    {
      question: "Which is the smallest state in India by area?",
      options: ["Goa", "Sikkim", "Tripura", "Manipur"],
      correct_answer: 0,
    },
    {
      question: "Who is known as the 'Father of the Indian Constitution'?",
      options: [
        "Jawaharlal Nehru",
        "Mahatma Gandhi",
        "B. R. Ambedkar",
        "Sardar Vallabhbhai Patel",
      ],
      correct_answer: 2,
    },
    {
      question: "Which is the hardest natural substance on Earth?",
      options: ["Gold", "Iron", "Diamond", "Platinum"],
      correct_answer: 2,
    },
    {
      question: "Which of the following is the national flower of India?",
      options: ["Rose", "Lily", "Lotus", "Sunflower"],
      correct_answer: 2,
    },
    {
      question: "What is the currency of Japan?",
      options: ["Yuan", "Dollar", "Yen", "Won"],
      correct_answer: 2,
    },
    {
      question: "Which is the largest desert in the world?",
      options: ["Sahara", "Gobi", "Kalahari", "Arctic"],
      correct_answer: 0,
    },
    {
      question: "Who invented the telephone?",
      options: [
        "Alexander Graham Bell",
        "Thomas Edison",
        "Nikola Tesla",
        "James Watt",
      ],
      correct_answer: 0,
    },
    {
      question: "Which gas is most abundant in Earth's atmosphere?",
      options: ["Oxygen", "Carbon Dioxide", "Nitrogen", "Hydrogen"],
      correct_answer: 2,
    },
    {
      question: "In which continent is the Amazon rainforest located?",
      options: ["Africa", "Asia", "South America", "Australia"],
      correct_answer: 2,
    },
    {
      question: "What is the national animal of India?",
      options: ["Lion", "Elephant", "Tiger", "Peacock"],
      correct_answer: 2,
    },
    {
      question: "Which river is known as the 'Sorrow of Bihar'?",
      options: ["Ganga", "Kosi", "Yamuna", "Mahanadi"],
      correct_answer: 1,
    },
    {
      question: "What is the capital of Canada?",
      options: ["Toronto", "Ottawa", "Vancouver", "Montreal"],
      correct_answer: 1,
    },
    {
      question: "Who is the author of the book 'A Brief History of Time'?",
      options: [
        "Carl Sagan",
        "Isaac Newton",
        "Albert Einstein",
        "Stephen Hawking",
      ],
      correct_answer: 3,
    },
    {
      question:
        "A man can row 9 km/h in still water. If the river is running at 3 km/h, it takes him 6 hours to row to a place and back. How far is the place?",
      options: ["24 km", "30 km", "18 km", "12 km"],
      correct_answer: 2,
    },
    {
      question: "What is the remainder when 2^31 is divided by 5?",
      options: ["1", "2", "3", "4"],
      correct_answer: 3,
    },
    {
      question:
        "In how many ways can the letters of the word 'COMMITTEE' be arranged so that all vowels always come together?",
      options: ["43200", "21600", "10800", "5400"],
      correct_answer: 1,
    },
    {
      question:
        "A sum of ₹8000 amounts to ₹9240 in 2 years at a certain rate of simple interest. What is the rate of interest per annum?",
      options: ["6%", "7%", "7.5%", "8%"],
      correct_answer: 1,
    },
    {
      question:
        "If the ratio of the ages of two persons is 4:5 and the sum of their ages is 45 years, what will be the ratio of their ages after 5 years?",
      options: ["5:6", "3:4", "7:8", "4:5"],
      correct_answer: 0,
    },
    {
      question:
        "Two pipes can fill a tank in 20 minutes and 30 minutes respectively. Both pipes are opened together, but after 5 minutes, the first pipe is turned off. How much more time will it take to fill the tank?",
      options: ["10 minutes", "12 minutes", "15 minutes", "20 minutes"],
      correct_answer: 1,
    },
    {
      question:
        "If the area of a circle is equal to the area of a square, then the ratio of the square of the side of the square to the square of the radius of the circle is:",
      options: ["π:4", "4:π", "1:π", "π:1"],
      correct_answer: 1,
    },
    {
      question:
        "A dishonest milkman mixes milk with water and gains 20% by selling the mixture at cost price. What is the ratio of milk to water in the mixture?",
      options: ["5:1", "4:1", "6:1", "3:1"],
      correct_answer: 1,
    },
    {
      question:
        "If 20% of a number is subtracted from 120, the result is the same number. What is the number?",
      options: ["75", "80", "85", "100"],
      correct_answer: 1,
    },
    {
      question:
        "A and B together can complete a piece of work in 12 days, and B alone can complete it in 18 days. How long will A alone take to complete the work?",
      options: ["24 days", "36 days", "30 days", "48 days"],
      correct_answer: 1,
    },
    {
      question:
        "In a 100 m race, A covers the distance in 36 seconds and B in 45 seconds. In this race, A beats B by:",
      options: ["20 m", "22.5 m", "25 m", "28 m"],
      correct_answer: 2,
    },
    {
      question:
        "The ratio of the present ages of A and B is 5:7. After 6 years, the ratio of their ages will be 3:4. What is the present age of A?",
      options: ["24 years", "30 years", "36 years", "42 years"],
      correct_answer: 0,
    },
    {
      question:
        "A man covers a distance of 600 km by train, 120 km by bus, and 150 km by car. What is the average speed of the man if he spent 10 hours, 2 hours, and 3 hours respectively in covering these distances?",
      options: ["48 km/h", "50 km/h", "52 km/h", "54 km/h"],
      correct_answer: 1,
    },
    {
      question:
        "What is the probability of getting an odd sum when two dice are thrown?",
      options: ["1/3", "1/2", "2/3", "3/4"],
      correct_answer: 1,
    },
    {
      question: "If 5x - 3y = 26 and 3x + 2y = 14, what is the value of x?",
      options: ["5", "6", "4", "3"],
      correct_answer: 0,
    },
    {
      question:
        "A bag contains 4 white, 5 red, and 6 black balls. If three balls are drawn at random, what is the probability that all are red?",
      options: ["1/22", "5/91", "1/21", "2/35"],
      correct_answer: 1,
    },
    {
      question:
        "The sum of the ages of a father and son is 60 years. The father is four times as old as the son. What is the present age of the son?",
      options: ["10 years", "12 years", "15 years", "20 years"],
      correct_answer: 1,
    },
    {
      question:
        "The average of 10 numbers is 15. If each number is increased by 2, what will be the new average?",
      options: ["17", "18", "16", "19"],
      correct_answer: 2,
    },
    {
      question:
        "The circumference of a circle is 88 cm. What is the diameter of the circle?",
      options: ["14 cm", "28 cm", "22 cm", "44 cm"],
      correct_answer: 1,
    },
    {
      question:
        "The average of four consecutive odd numbers is 24. What is the smallest of these numbers?",
      options: ["21", "23", "25", "27"],
      correct_answer: 0,
    },
    {
      question:
        "In a certain code, ‘FIRE’ is written as ‘EJQF’. How is ‘WATER’ written in that code?",
      options: ["VSBFS", "UBSFS", "VSBFT", "UBTFS"],
      correct_answer: 0,
    },
    {
      question:
        "If 'PAPER' is coded as 'QBPFS', how is 'BOOK' coded in that language?",
      options: ["CPPL", "CPPM", "DPPM", "CPNM"],
      correct_answer: 0,
    },
    {
      question: "What will be the next number in the series 2, 6, 12, 20, __?",
      options: ["30", "32", "34", "36"],
      correct_answer: 0,
    },
    {
      question:
        "A clock shows 3:30. What is the angle between the hour and minute hands?",
      options: ["30°", "45°", "60°", "75°"],
      correct_answer: 2,
    },
    {
      question:
        "If in a certain code language, 'SUCCESS' is written as 'TVDDFTT', how is 'ACHIEVE' written?",
      options: ["BDIJFF", "BDFJFF", "BDHJFF", "BFIJFF"],
      correct_answer: 1,
    },
    {
      question: "Who is known as the 'Missile Man of India'?",
      options: [
        "Dr. A.P.J. Abdul Kalam",
        "Dr. Vikram Sarabhai",
        "Dr. Homi Bhabha",
        "Dr. C.V. Raman",
      ],
      correct_answer: 0,
    },
    {
      question: "Which element has the atomic number 1?",
      options: ["Hydrogen", "Helium", "Lithium", "Beryllium"],
      correct_answer: 0,
    },
    {
      question: "What is the largest planet in our solar system?",
      options: ["Earth", "Jupiter", "Saturn", "Neptune"],
      correct_answer: 1,
    },
    {
      question: "In which year did India gain independence from British rule?",
      options: ["1942", "1947", "1950", "1952"],
      correct_answer: 1,
    },
    {
      question: "Which is the smallest state in India by area?",
      options: ["Goa", "Sikkim", "Tripura", "Nagaland"],
      correct_answer: 0,
    },
    {
      question:
        "If the word ‘DEVELOPMENT’ is coded as ‘EFMPWQNFSOU’, how is ‘GOVERNMENT’ coded?",
      options: ["HPWFSNFOU", "HPWFSNFSOU", "HPWFSOFOU", "HPWFTNFOV"],
      correct_answer: 0,
    },
    {
      question:
        "Which number should come next in the series: 7, 14, 28, 56, __?",
      options: ["112", "108", "120", "100"],
      correct_answer: 0,
    },
    {
      question:
        "A person is standing at a point and facing north. He turns 180° to his right, then 90° to his left. Which direction is he facing now?",
      options: ["North", "South", "East", "West"],
      correct_answer: 2,
    },
    {
      question:
        "The average of five numbers is 40. If the average of the first three numbers is 30, what is the average of the last two numbers?",
      options: ["60", "70", "80", "50"],
      correct_answer: 1,
    },
    {
      question: "What is the product of the first 4 prime numbers?",
      options: ["210", "231", "240", "300"],
      correct_answer: 0,
    },
    {
      question: "What is the capital of Canada?",
      options: ["Toronto", "Ottawa", "Vancouver", "Montreal"],
      correct_answer: 1,
    },
    {
      question: "Which is the hardest natural substance on Earth?",
      options: ["Gold", "Diamond", "Platinum", "Iron"],
      correct_answer: 1,
    },
    {
      question:
        "What is the boiling point of water at sea level in degrees Celsius?",
      options: ["90°C", "100°C", "110°C", "120°C"],
      correct_answer: 1,
    },
    {
      question:
        "Which of the following is not a part of the human circulatory system?",
      options: ["Heart", "Lungs", "Liver", "Arteries"],
      correct_answer: 2,
    },
    {
      question: "Who was the first woman Prime Minister of India?",
      options: [
        "Indira Gandhi",
        "Pratibha Patil",
        "Sonia Gandhi",
        "Hillary Clinton",
      ],
      correct_answer: 0,
    },
    {
      question:
        "In a certain code language, 'BEFORE' is written as 'CFDPST'. How is 'AFTER' coded?",
      options: ["BGSFS", "BGSFT", "BFTFS", "BGSFR"],
      correct_answer: 1,
    },
    {
      question:
        "If the sum of the squares of two numbers is 130 and their product is 84, what is the sum of the numbers?",
      options: ["16", "18", "20", "22"],
      correct_answer: 1,
    },
    {
      question:
        "A person travels 40 km towards east and then 30 km towards north. How far is he from his starting point?",
      options: ["50 km", "60 km", "70 km", "80 km"],
      correct_answer: 0,
    },
    {
      question:
        "The ratio of the ages of A and B is 4:5. After 5 years, the ratio will be 5:6. What is the present age of A?",
      options: ["20 years", "24 years", "28 years", "30 years"],
      correct_answer: 1,
    },
    {
      question:
        "If 5 men can complete a work in 20 days, how many days will 10 men take to complete the same work?",
      options: ["10 days", "15 days", "20 days", "25 days"],
      correct_answer: 1,
    },
    {
      question: "What is the least common multiple (LCM) of 12, 15, and 20?",
      options: ["60", "120", "180", "240"],
      correct_answer: 1,
    },
    {
      question:
        "A sum of money is invested at a simple interest rate of 6% per annum. If it amounts to $8400 after 4 years, what is the principal amount?",
      options: ["$6000", "$7000", "$7500", "$8000"],
      correct_answer: 3,
    },
    {
      question:
        "The average of 5 numbers is 24. If one number is excluded, the average of the remaining numbers is 20. What is the excluded number?",
      options: ["28", "30", "32", "34"],
      correct_answer: 0,
    },
    {
      question:
        "In a group of 120 students, 70 study Mathematics, 50 study Physics, and 20 study both Mathematics and Physics. How many students study only Physics?",
      options: ["10", "20", "30", "40"],
      correct_answer: 2,
    },
    {
      question:
        "If the price of an article is reduced by 20% and the new price is $240, what was the original price?",
      options: ["$300", "$320", "$350", "$400"],
      correct_answer: 3,
    },
    {
      question:
        "A train travels 180 km in 3 hours. What is its average speed in km/h?",
      options: ["50 km/h", "60 km/h", "70 km/h", "80 km/h"],
      correct_answer: 1,
    },
    {
      question:
        "In a bag, the ratio of red to green balls is 3:4 and the ratio of green to blue balls is 5:6. What is the ratio of red to blue balls?",
      options: ["5:8", "3:4", "3:5", "3:6"],
      correct_answer: 0,
    },
    {
      question:
        "What is the largest 4-digit number that is divisible by 6, 8, and 12?",
      options: ["9996", "9984", "9876", "9768"],
      correct_answer: 0,
    },
    {
      question:
        "A man is 4 times as old as his son. After 5 years, he will be 3 times as old as his son. What is the present age of the son?",
      options: ["8 years", "10 years", "12 years", "15 years"],
      correct_answer: 2,
    },
    {
      question:
        "If a man earns $1800 in 15 days, how much will he earn in 25 days at the same rate?",
      options: ["$2500", "$3000", "$3200", "$3500"],
      correct_answer: 1,
    },
    {
      question: "What is the sum of the first 15 prime numbers?",
      options: ["200", "250", "300", "350"],
      correct_answer: 1,
    },
    {
      question:
        "In a game, a player scores 80, 90, and 70 points in three rounds. If the average score of the player is 80, what was the score in the fourth round?",
      options: ["80", "90", "100", "110"],
      correct_answer: 0,
    },
    {
      question:
        "If the difference between the squares of two numbers is 252, and one of the numbers is 9, what is the other number?",
      options: ["12", "15", "18", "21"],
      correct_answer: 1,
    },
    {
      question:
        "A mixture contains 40% milk and 60% water. How much water must be added to make it 50% milk?",
      options: [
        "Equal quantity",
        "Half the quantity",
        "Twice the quantity",
        "Three times the quantity",
      ],
      correct_answer: 2,
    },
    {
      question:
        "What is the area of a triangle with base 12 cm and height 16 cm?",
      options: ["96 cm²", "120 cm²", "144 cm²", "192 cm²"],
      correct_answer: 0,
    },
    {
      question:
        "In a certain code language, 'HELLO' is coded as 'KHOOR'. How is 'WORLD' coded?",
      options: ["YQTNF", "YPSNF", "YQOPE", "YQMPF"],
      correct_answer: 0,
    },
    {
      question:
        "A sum of money amounts to $1600 in 2 years at simple interest. If the rate of interest is 5% per annum, what is the principal amount?",
      options: ["$1200", "$1300", "$1400", "$1500"],
      correct_answer: 3,
    },
    {
      question:
        "If a train travels 150 km in 2.5 hours, what is its speed in km/h?",
      options: ["55 km/h", "60 km/h", "65 km/h", "70 km/h"],
      correct_answer: 1,
    },
    {
      question:
        "A box contains 3 red, 5 blue, and 2 green balls. What is the probability of drawing a blue ball?",
      options: ["1/3", "1/4", "5/10", "5/12"],
      correct_answer: 3,
    },
    {
      question:
        "If the ratio of ages of A and B is 5:6, and B is 4 years older than A, what is the present age of A?",
      options: ["12 years", "16 years", "20 years", "24 years"],
      correct_answer: 1,
    },
    {
      question:
        "What is the area of a rectangle with a length of 15 cm and a width of 8 cm?",
      options: ["120 cm²", "130 cm²", "140 cm²", "150 cm²"],
      correct_answer: 0,
    },
    {
      question:
        "If a number is increased by 15% and the result is 115, what is the original number?",
      options: ["100", "105", "110", "120"],
      correct_answer: 0,
    },
    {
      question:
        "In a class of 40 students, the average score in Mathematics is 75. If 10 students scored below average, what is the total score of these 10 students?",
      options: ["650", "700", "750", "800"],
      correct_answer: 0,
    },
    {
      question: "What is the value of the expression (8 + 6) × (12 - 4)?",
      options: ["96", "104", "112", "120"],
      correct_answer: 0,
    },
    {
      question:
        "If the perimeter of a square is 64 cm, what is the area of the square?",
      options: ["144 cm²", "256 cm²", "324 cm²", "400 cm²"],
      correct_answer: 1,
    },
    {
      question: "What is the smallest 3-digit number that is divisible by 7?",
      options: ["105", "112", "119", "126"],
      correct_answer: 0,
    },
    {
      question:
        "If a company’s revenue increases from $50,000 to $75,000, what is the percentage increase in revenue?",
      options: ["40%", "45%", "50%", "55%"],
      correct_answer: 2,
    },
    {
      question:
        "A person invests $8000 at an annual compound interest rate of 4% for 3 years. What is the amount at the end of 3 years?",
      options: ["$8960", "$8968", "$9024", "$9072"],
      correct_answer: 1,
    },
    {
      question:
        "The difference between two numbers is 18 and their sum is 54. What are the two numbers?",
      options: ["18 and 36", "20 and 34", "22 and 32", "24 and 30"],
      correct_answer: 0,
    },
    {
      question:
        "A cylindrical tank has a height of 10 meters and a radius of 5 meters. What is the volume of the tank?",
      options: [
        "785 cubic meters",
        "1570 cubic meters",
        "2500 cubic meters",
        "3140 cubic meters",
      ],
      correct_answer: 0,
    },
    {
      question:
        "In a group of 200 people, 60% are male and 40% are female. How many females are there in the group?",
      options: ["70", "80", "90", "100"],
      correct_answer: 1,
    },
    {
      question:
        "If the angle of elevation to the top of a tower from a point on the ground is 30°, and the distance from the point to the base of the tower is 50 meters, what is the height of the tower?",
      options: ["25 meters", "50 meters", "75 meters", "100 meters"],
      correct_answer: 2,
    },
    {
      question:
        "A man sells an article at a loss of 10%. If the selling price is $540, what is the cost price of the article?",
      options: ["$600", "$630", "$650", "$700"],
      correct_answer: 0,
    },
    {
      question:
        "What is the least number of six-digit numbers that must be divisible by both 12 and 18?",
      options: ["100000", "100800", "100920", "101000"],
      correct_answer: 1,
    },
    {
      question:
        "If a number is multiplied by 7 and then decreased by 4, the result is 50. What is the number?",
      options: ["6", "7", "8", "9"],
      correct_answer: 1,
    },
  ],
  chsl1: [
    {
      question: "Which one of the following is the capital city of Norway?",
      options: ["Stockholm", "Helsinki", "Oslo", "Copenhagen"],
      correct_answer: 2,
    },
    {
      question:
        "Which number will come next in the series: 2, 6, 12, 20, 30, ?",
      options: ["40", "42", "36", "38"],
      correct_answer: 0,
    },
    {
      question: "Which part of the plant conducts photosynthesis?",
      options: ["Root", "Stem", "Leaf", "Flower"],
      correct_answer: 2,
    },
    {
      question:
        "If the cost price of 10 articles is equal to the selling price of 8 articles, then what is the profit percentage?",
      options: ["20%", "25%", "30%", "50%"],
      correct_answer: 1,
    },
    {
      question: "What is the synonym of 'abundant'?",
      options: ["Scarce", "Plentiful", "Rare", "Lack"],
      correct_answer: 1,
    },
    {
      question:
        "The Indus Valley Civilization was known for which of the following?",
      options: [
        "Urban Planning",
        "Monotheism",
        "Nomadic Lifestyle",
        "Vedic Literature",
      ],
      correct_answer: 0,
    },
    {
      question: "Which of the following is not a prime number?",
      options: ["2", "9", "11", "17"],
      correct_answer: 1,
    },
    {
      question: "What is the main function of white blood cells in the body?",
      options: [
        "Transporting oxygen",
        "Forming clots",
        "Fighting infections",
        "Carrying nutrients",
      ],
      correct_answer: 2,
    },
    {
      question: "What is the square root of 144?",
      options: ["10", "11", "12", "13"],
      correct_answer: 2,
    },
    {
      question: "Who wrote the famous novel 'Pride and Prejudice'?",
      options: [
        "Emily Brontë",
        "Charlotte Brontë",
        "Jane Austen",
        "Mary Shelley",
      ],
      correct_answer: 2,
    },
    {
      question: "Which metal is most abundant in the Earth's crust?",
      options: ["Iron", "Aluminum", "Copper", "Gold"],
      correct_answer: 1,
    },
    {
      question: "Which of the following is a programming language?",
      options: ["HTTP", "HTML", "Python", "FTP"],
      correct_answer: 2,
    },
    {
      question: "What is the capital of Australia?",
      options: ["Sydney", "Melbourne", "Canberra", "Perth"],
      correct_answer: 2,
    },
    {
      question: "In which year did India gain independence?",
      options: ["1945", "1947", "1950", "1952"],
      correct_answer: 1,
    },
    {
      question:
        "Which organ in the human body is primarily responsible for filtering blood?",
      options: ["Heart", "Liver", "Kidney", "Lungs"],
      correct_answer: 2,
    },
    {
      question: "What is the chemical formula of water?",
      options: ["H2O", "CO2", "O2", "H2O2"],
      correct_answer: 0,
    },
    {
      question: "Who is known as the 'Father of Computers'?",
      options: [
        "Alan Turing",
        "Charles Babbage",
        "John von Neumann",
        "Bill Gates",
      ],
      correct_answer: 1,
    },
    {
      question: "Which gas is most abundant in the Earth's atmosphere?",
      options: ["Oxygen", "Carbon Dioxide", "Nitrogen", "Hydrogen"],
      correct_answer: 2,
    },
    {
      question: "What is the largest planet in our solar system?",
      options: ["Earth", "Mars", "Jupiter", "Saturn"],
      correct_answer: 2,
    },
    {
      question: "In which year did the Battle of Plassey take place?",
      options: ["1757", "1764", "1799", "1857"],
      correct_answer: 0,
    },
    {
      question:
        "If the product of two consecutive odd numbers is 255, what is the smaller number?",
      options: ["15", "17", "13", "19"],
      correct_answer: 1,
    },
    {
      question:
        "A sum of money amounts to Rs. 1,320 in 2 years at 10% per annum simple interest. What is the principal amount?",
      options: ["Rs. 1,000", "Rs. 1,100", "Rs. 1,200", "Rs. 1,050"],
      correct_answer: 0,
    },
    {
      question:
        "If the length of a rectangle is increased by 20% and the width is decreased by 20%, what will be the percentage change in the area of the rectangle?",
      options: ["4% decrease", "4% increase", "No change", "8% decrease"],
      correct_answer: 0,
    },
    {
      question: "What is the value of the expression (81)^0.25 × (64)^0.25?",
      options: ["4", "6", "8", "12"],
      correct_answer: 1,
    },
    {
      question:
        "A man buys 3 apples for Rs. 5 and sells 2 apples for Rs. 5. Find his gain or loss percentage.",
      options: ["20% gain", "50% gain", "25% loss", "50% loss"],
      correct_answer: 1,
    },
    {
      question: "What is the LCM of 24, 36, and 54?",
      options: ["108", "216", "432", "648"],
      correct_answer: 1,
    },
    {
      question:
        "If x^2 - 5x + 6 = 0, what is the sum of the roots of the equation?",
      options: ["3", "5", "6", "8"],
      correct_answer: 1,
    },
    {
      question:
        "A train 240 meters long passes a pole in 24 seconds. What is the speed of the train in km/h?",
      options: ["30 km/h", "36 km/h", "40 km/h", "50 km/h"],
      correct_answer: 2,
    },
    {
      question:
        "If the ratio of the ages of two persons is 4:5 and the difference of their ages is 8 years, what is the age of the younger person?",
      options: ["16 years", "20 years", "24 years", "32 years"],
      correct_answer: 0,
    },
    {
      question:
        "In a triangle, the sum of the lengths of any two sides is greater than the length of the third side. This statement is known as the:",
      options: [
        "Pythagorean theorem",
        "Triangle inequality theorem",
        "Congruence theorem",
        "Midpoint theorem",
      ],
      correct_answer: 1,
    },
    {
      question:
        "A shopkeeper marks up his goods by 40% and offers a discount of 25%. What is his profit or loss percentage?",
      options: ["5% profit", "5% loss", "10% profit", "10% loss"],
      correct_answer: 0,
    },
    {
      question:
        "If a number is increased by 20% and then decreased by 20%, what is the net percentage change?",
      options: ["No change", "1% increase", "4% decrease", "4% increase"],
      correct_answer: 2,
    },
    {
      question:
        "The length of the shadow of a tower is √3 times the height of the tower. What is the angle of elevation of the sun?",
      options: ["30°", "45°", "60°", "75°"],
      correct_answer: 0,
    },
    {
      question: "What is the value of 7 + 3 × (6/2) - 4?",
      options: ["11", "13", "14", "15"],
      correct_answer: 1,
    },
    {
      question:
        "A can complete a task in 10 days, and B can complete the same task in 15 days. How long will it take for A and B to complete the task together?",
      options: ["6 days", "7 days", "8 days", "9 days"],
      correct_answer: 1,
    },
    {
      question: "What is the cube root of 343?",
      options: ["5", "6", "7", "8"],
      correct_answer: 2,
    },
    {
      question: "If sinθ = 1/2, what is the value of θ in degrees?",
      options: ["15°", "30°", "45°", "60°"],
      correct_answer: 1,
    },
    {
      question:
        "A person invests Rs. 12,000 at a compound interest rate of 10% per annum. What will be the amount after 2 years?",
      options: ["Rs. 14,520", "Rs. 14,400", "Rs. 14,640", "Rs. 15,000"],
      correct_answer: 2,
    },
    {
      question: "If cos 45° = sin x°, then what is the value of x?",
      options: ["15°", "30°", "45°", "60°"],
      correct_answer: 2,
    },
    {
      question:
        "A person buys 50 shares at Rs. 20 each. If the dividend declared is 8%, what is the amount of dividend he receives?",
      options: ["Rs. 40", "Rs. 60", "Rs. 80", "Rs. 100"],
      correct_answer: 2,
    },
    {
      question:
        "The average of five consecutive odd numbers is 27. What is the largest number?",
      options: ["29", "31", "33", "35"],
      correct_answer: 2,
    },
    {
      question:
        "A mixture contains alcohol and water in the ratio 4:3. If 5 liters of water is added, the new ratio becomes 4:5. What is the amount of alcohol in the original mixture?",
      options: ["10 liters", "12 liters", "15 liters", "20 liters"],
      correct_answer: 2,
    },
    {
      question:
        "If the roots of the quadratic equation 2x^2 - 3x + k = 0 are equal, what is the value of k?",
      options: ["9/8", "5/4", "3/2", "1/2"],
      correct_answer: 0,
    },
    {
      question:
        "The ratio of the ages of A and B is 5:7. If the difference between their ages is 12 years, what is B's age?",
      options: ["42 years", "36 years", "30 years", "28 years"],
      correct_answer: 0,
    },
    {
      question:
        "If the sum of the first 15 terms of an arithmetic progression is 120, what is the 8th term?",
      options: ["8", "12", "16", "18"],
      correct_answer: 1,
    },
    {
      question:
        "A man sold an article at a loss of 15%. If he had sold it for Rs. 90 more, he would have gained 5%. What is the cost price of the article?",
      options: ["Rs. 450", "Rs. 500", "Rs. 600", "Rs. 650"],
      correct_answer: 2,
    },
    {
      question:
        "The circumference of the base of a cylinder is 44 cm and its height is 21 cm. What is the volume of the cylinder?",
      options: ["3080 cm³", "3234 cm³", "3696 cm³", "4620 cm³"],
      correct_answer: 1,
    },
    {
      question:
        "If a car travels a distance of 300 km in 5 hours, what is the speed of the car in meters per second?",
      options: ["15 m/s", "16.67 m/s", "20 m/s", "25 m/s"],
      correct_answer: 1,
    },
    {
      question:
        "If the ratio of the areas of two similar triangles is 9:16, what is the ratio of their corresponding sides?",
      options: ["2:3", "3:4", "4:5", "9:16"],
      correct_answer: 1,
    },
    {
      question:
        "In how many different ways can the letters of the word 'LEADER' be arranged?",
      options: ["360", "720", "1440", "1800"],
      correct_answer: 2,
    },
    {
      question:
        "The radius of a circle is increased by 50%. By what percentage does the area increase?",
      options: ["50%", "100%", "125%", "150%"],
      correct_answer: 2,
    },
    {
      question:
        "If a man walks at 5 km/h, he misses a train by 7 minutes. If he walks at 6 km/h, he reaches the station 5 minutes before the train arrives. What is the distance to the station?",
      options: ["4 km", "5 km", "6 km", "7 km"],
      correct_answer: 0,
    },
    {
      question:
        "A sum of Rs. 12,000 is divided into two parts such that the simple interest on the first part for 3 years at 10% per annum is equal to the simple interest on the second part for 4 years at 12% per annum. What is the second part?",
      options: ["Rs. 4,000", "Rs. 5,000", "Rs. 6,000", "Rs. 7,000"],
      correct_answer: 1,
    },
    {
      question: "The value of log(1000) to the base 10 is:",
      options: ["1", "2", "3", "4"],
      correct_answer: 2,
    },
    {
      question:
        "Two pipes can fill a tank in 20 minutes and 30 minutes respectively. If both pipes are opened simultaneously, how long will it take to fill the tank?",
      options: ["10 minutes", "12 minutes", "15 minutes", "25 minutes"],
      correct_answer: 1,
    },
    {
      question:
        "A train 150 meters long is running at a speed of 60 km/h. In what time will it pass a man standing on the platform?",
      options: ["6 seconds", "9 seconds", "12 seconds", "15 seconds"],
      correct_answer: 1,
    },
    {
      question:
        "If 6 men can complete a piece of work in 12 days, how many days will it take for 8 men to complete the same work?",
      options: ["9 days", "10 days", "7 days", "6 days"],
      correct_answer: 2,
    },
    {
      question:
        "The perimeter of a rectangle is 48 cm. If the length is 5 cm more than the width, what is the area of the rectangle?",
      options: ["96 cm²", "120 cm²", "144 cm²", "160 cm²"],
      correct_answer: 1,
    },
    {
      question:
        "If the sum of the interior angles of a polygon is 1440°, how many sides does the polygon have?",
      options: ["8", "10", "12", "14"],
      correct_answer: 2,
    },
    {
      question:
        "A sum of Rs. 800 amounts to Rs. 920 in 3 years at simple interest. What will it amount to if the interest rate is increased by 2%?",
      options: ["Rs. 950", "Rs. 960", "Rs. 980", "Rs. 1,000"],
      correct_answer: 1,
    },
    {
      question:
        "The average of five consecutive odd numbers is 27. What is the largest number?",
      options: ["29", "31", "33", "35"],
      correct_answer: 2,
    },
    {
      question:
        "A mixture contains alcohol and water in the ratio 4:3. If 5 liters of water is added, the new ratio becomes 4:5. What is the amount of alcohol in the original mixture?",
      options: ["10 liters", "12 liters", "15 liters", "20 liters"],
      correct_answer: 2,
    },
    {
      question:
        "If the roots of the quadratic equation 2x^2 - 3x + k = 0 are equal, what is the value of k?",
      options: ["9/8", "5/4", "3/2", "1/2"],
      correct_answer: 0,
    },
    {
      question:
        "The ratio of the ages of A and B is 5:7. If the difference between their ages is 12 years, what is B's age?",
      options: ["42 years", "36 years", "30 years", "28 years"],
      correct_answer: 0,
    },
    {
      question:
        "If the sum of the first 15 terms of an arithmetic progression is 120, what is the 8th term?",
      options: ["8", "12", "16", "18"],
      correct_answer: 1,
    },
    {
      question:
        "A man sold an article at a loss of 15%. If he had sold it for Rs. 90 more, he would have gained 5%. What is the cost price of the article?",
      options: ["Rs. 450", "Rs. 500", "Rs. 600", "Rs. 650"],
      correct_answer: 2,
    },
    {
      question:
        "The circumference of the base of a cylinder is 44 cm and its height is 21 cm. What is the volume of the cylinder?",
      options: ["3080 cm³", "3234 cm³", "3696 cm³", "4620 cm³"],
      correct_answer: 1,
    },
    {
      question:
        "If a car travels a distance of 300 km in 5 hours, what is the speed of the car in meters per second?",
      options: ["15 m/s", "16.67 m/s", "20 m/s", "25 m/s"],
      correct_answer: 1,
    },
    {
      question:
        "If the ratio of the areas of two similar triangles is 9:16, what is the ratio of their corresponding sides?",
      options: ["2:3", "3:4", "4:5", "9:16"],
      correct_answer: 1,
    },
    {
      question:
        "In how many different ways can the letters of the word 'LEADER' be arranged?",
      options: ["360", "720", "1440", "1800"],
      correct_answer: 2,
    },
    {
      question:
        "The radius of a circle is increased by 50%. By what percentage does the area increase?",
      options: ["50%", "100%", "125%", "150%"],
      correct_answer: 2,
    },
    {
      question:
        "If a man walks at 5 km/h, he misses a train by 7 minutes. If he walks at 6 km/h, he reaches the station 5 minutes before the train arrives. What is the distance to the station?",
      options: ["4 km", "5 km", "6 km", "7 km"],
      correct_answer: 0,
    },
    {
      question:
        "A sum of Rs. 12,000 is divided into two parts such that the simple interest on the first part for 3 years at 10% per annum is equal to the simple interest on the second part for 4 years at 12% per annum. What is the second part?",
      options: ["Rs. 4,000", "Rs. 5,000", "Rs. 6,000", "Rs. 7,000"],
      correct_answer: 1,
    },
    {
      question: "The value of log(1000) to the base 10 is:",
      options: ["1", "2", "3", "4"],
      correct_answer: 2,
    },
    {
      question:
        "Two pipes can fill a tank in 20 minutes and 30 minutes respectively. If both pipes are opened simultaneously, how long will it take to fill the tank?",
      options: ["10 minutes", "12 minutes", "15 minutes", "25 minutes"],
      correct_answer: 1,
    },
    {
      question:
        "A train 150 meters long is running at a speed of 60 km/h. In what time will it pass a man standing on the platform?",
      options: ["6 seconds", "9 seconds", "12 seconds", "15 seconds"],
      correct_answer: 1,
    },
    {
      question:
        "If 6 men can complete a piece of work in 12 days, how many days will it take for 8 men to complete the same work?",
      options: ["9 days", "10 days", "7 days", "6 days"],
      correct_answer: 2,
    },
    {
      question:
        "The perimeter of a rectangle is 48 cm. If the length is 5 cm more than the width, what is the area of the rectangle?",
      options: ["96 cm²", "120 cm²", "144 cm²", "160 cm²"],
      correct_answer: 1,
    },
    {
      question:
        "If the sum of the interior angles of a polygon is 1440°, how many sides does the polygon have?",
      options: ["8", "10", "12", "14"],
      correct_answer: 2,
    },
    {
      question:
        "A sum of Rs. 800 amounts to Rs. 920 in 3 years at simple interest. What will it amount to if the interest rate is increased by 2%?",
      options: ["Rs. 950", "Rs. 960", "Rs. 980", "Rs. 1,000"],
      correct_answer: 1,
    },
    {
      question:
        "If in a certain code, 'LANGUAGE' is written as 'LNAUGAGE'. How is 'MONKEY' written in that code?",
      options: ["MOKNEY", "MNOKEY", "MONKYE", "MONKEY"],
      correct_answer: 0,
    },
    {
      question:
        "The average of five consecutive odd numbers is 27. What is the largest number?",
      options: ["29", "31", "33", "35"],
      correct_answer: 2,
    },
    {
      question:
        "A mixture contains alcohol and water in the ratio 4:3. If 5 liters of water is added, the new ratio becomes 4:5. What is the amount of alcohol in the original mixture?",
      options: ["10 liters", "12 liters", "15 liters", "20 liters"],
      correct_answer: 2,
    },
    {
      question:
        "If the roots of the quadratic equation 2x^2 - 3x + k = 0 are equal, what is the value of k?",
      options: ["9/8", "5/4", "3/2", "1/2"],
      correct_answer: 0,
    },
    {
      question:
        "The ratio of the ages of A and B is 5:7. If the difference between their ages is 12 years, what is B's age?",
      options: ["42 years", "36 years", "30 years", "28 years"],
      correct_answer: 0,
    },
    {
      question:
        "If the sum of the first 15 terms of an arithmetic progression is 120, what is the 8th term?",
      options: ["8", "12", "16", "18"],
      correct_answer: 1,
    },
    {
      question:
        "A man sold an article at a loss of 15%. If he had sold it for Rs. 90 more, he would have gained 5%. What is the cost price of the article?",
      options: ["Rs. 450", "Rs. 500", "Rs. 600", "Rs. 650"],
      correct_answer: 2,
    },
    {
      question:
        "The circumference of the base of a cylinder is 44 cm and its height is 21 cm. What is the volume of the cylinder?",
      options: ["3080 cm³", "3234 cm³", "3696 cm³", "4620 cm³"],
      correct_answer: 1,
    },
    {
      question:
        "If a car travels a distance of 300 km in 5 hours, what is the speed of the car in meters per second?",
      options: ["15 m/s", "16.67 m/s", "20 m/s", "25 m/s"],
      correct_answer: 1,
    },
    {
      question:
        "If the ratio of the areas of two similar triangles is 9:16, what is the ratio of their corresponding sides?",
      options: ["2:3", "3:4", "4:5", "9:16"],
      correct_answer: 1,
    },
    {
      question:
        "In how many different ways can the letters of the word 'LEADER' be arranged?",
      options: ["360", "720", "1440", "1800"],
      correct_answer: 2,
    },
    {
      question:
        "The radius of a circle is increased by 50%. By what percentage does the area increase?",
      options: ["50%", "100%", "125%", "150%"],
      correct_answer: 2,
    },
    {
      question:
        "If a man walks at 5 km/h, he misses a train by 7 minutes. If he walks at 6 km/h, he reaches the station 5 minutes before the train arrives. What is the distance to the station?",
      options: ["4 km", "5 km", "6 km", "7 km"],
      correct_answer: 0,
    },
    {
      question:
        "A sum of Rs. 12,000 is divided into two parts such that the simple interest on the first part for 3 years at 10% per annum is equal to the simple interest on the second part for 4 years at 12% per annum. What is the second part?",
      options: ["Rs. 4,000", "Rs. 5,000", "Rs. 6,000", "Rs. 7,000"],
      correct_answer: 1,
    },
    {
      question: "The value of log(1000) to the base 10 is:",
      options: ["1", "2", "3", "4"],
      correct_answer: 2,
    },
    {
      question:
        "Two pipes can fill a tank in 20 minutes and 30 minutes respectively. If both pipes are opened simultaneously, how long will it take to fill the tank?",
      options: ["10 minutes", "12 minutes", "15 minutes", "25 minutes"],
      correct_answer: 1,
    },
    {
      question:
        "A train 150 meters long is running at a speed of 60 km/h. In what time will it pass a man standing on the platform?",
      options: ["6 seconds", "9 seconds", "12 seconds", "15 seconds"],
      correct_answer: 1,
    },
    {
      question:
        "If 6 men can complete a piece of work in 12 days, how many days will it take for 8 men to complete the same work?",
      options: ["9 days", "10 days", "7 days", "6 days"],
      correct_answer: 2,
    },
    {
      question:
        "The perimeter of a rectangle is 48 cm. If the length is 5 cm more than the width, what is the area of the rectangle?",
      options: ["96 cm²", "120 cm²", "144 cm²", "160 cm²"],
      correct_answer: 1,
    },
    {
      question:
        "If the sum of the interior angles of a polygon is 1440°, how many sides does the polygon have?",
      options: ["8", "10", "12", "14"],
      correct_answer: 2,
    },
    {
      question:
        "A sum of Rs. 800 amounts to Rs. 920 in 3 years at simple interest. What will it amount to if the interest rate is increased by 2%?",
      options: ["Rs. 950", "Rs. 960", "Rs. 980", "Rs. 1,000"],
      correct_answer: 1,
    },
    {
      question:
        "If in a certain code, 'LANGUAGE' is written as 'LNAUGAGE'. How is 'MONKEY' written in that code?",
      options: ["MOKNEY", "MNOKEY", "MONKYE", "MONKEY"],
      correct_answer: 0,
    },
    {
      question:
        "In a row of boys, Kamal is 12th from the left and Ajay is 15th from the right. If they interchange their positions, Kamal becomes 20th from the left. What is Ajay’s position from the right after interchanging?",
      options: ["22nd", "18th", "25th", "27th"],
      correct_answer: 0,
    },
    {
      question:
        "Select the word which cannot be formed using the letters of the word 'EXAMINATION'.",
      options: ["EXAM", "MOTION", "NATION", "EXTREME"],
      correct_answer: 3,
    },
    {
      question: "If 'WHITE' is coded as 'XHKVG', how is 'BLACK' coded?",
      options: ["CMDLN", "CLDNM", "CMDBN", "CMDKN"],
      correct_answer: 0,
    },
    {
      question:
        "In a certain code language, '3' is written as 'D', '4' is written as 'E', '5' is written as 'F'. What will '19' be written as?",
      options: ["O", "Q", "R", "S"],
      correct_answer: 1,
    },
    {
      question:
        "Arrange the following words in a logical and meaningful order: 1. Foot 2. Knee 3. Shoulder 4. Head 5. Neck",
      options: [
        "1, 2, 5, 3, 4",
        "2, 3, 1, 5, 4",
        "5, 4, 3, 2, 1",
        "1, 2, 4, 5, 3",
      ],
      correct_answer: 0,
    },
    {
      question:
        "A is the father of B, but B is not the son of A. How is B related to A?",
      options: ["Daughter", "Mother", "Sister", "Wife"],
      correct_answer: 0,
    },
    {
      question:
        "Pointing to a photograph, a man said, 'She is the daughter of my grandfather’s only son.' How is the woman in the photograph related to the man?",
      options: ["Mother", "Sister", "Daughter", "Niece"],
      correct_answer: 1,
    },
    {
      question:
        "If '+ means ×', '− means ÷', '× means −', and '÷ means +', then what is the value of 12 ÷ 3 + 4 × 2 − 1?",
      options: ["5", "11", "13", "21"],
      correct_answer: 2,
    },
    {
      question:
        "If P is the brother of Q; Q is the sister of R; and R is the father of S, how is P related to S?",
      options: ["Father", "Uncle", "Grandfather", "Brother"],
      correct_answer: 1,
    },
    {
      question: "Select the odd one out: Circle, Triangle, Rectangle, Square",
      options: ["Circle", "Triangle", "Rectangle", "Square"],
      correct_answer: 0,
    },
    {
      question: "Find the missing number in the series: 3, 9, 27, ?, 243",
      options: ["54", "81", "72", "90"],
      correct_answer: 1,
    },
    {
      question:
        "Which one of the following words cannot be formed by rearranging the letters of the word 'RESTAURANT'?",
      options: ["STARTER", "NATURE", "TRUE", "TURNER"],
      correct_answer: 2,
    },
    {
      question:
        "Which number will come next in the series: 2, 5, 10, 17, 26, ?",
      options: ["35", "37", "41", "47"],
      correct_answer: 0,
    },
    {
      question: "If 'CLOCK' is coded as 'JFLMN', how is 'WATCH' coded?",
      options: ["ZYQBK", "XBSHL", "YZSHM", "YBSHL"],
      correct_answer: 3,
    },
    {
      question: "Complete the series: E, H, L, Q, ?",
      options: ["U", "V", "W", "X"],
      correct_answer: 0,
    },
    {
      question:
        "If 'TRAIL' is written as 'UXDLO', then how is 'BOATS' written?",
      options: ["CVDQU", "DTEPU", "EVDPU", "EVFPU"],
      correct_answer: 2,
    },
    {
      question:
        "Which one of the following diagrams represents the correct relationship among 'Men', 'Fathers', and 'Teachers'?",
      options: [
        "Three circles intersecting each other",
        "Two circles intersecting with a third one surrounding them",
        "Three circles touching each other",
        "One circle within another circle",
      ],
      correct_answer: 0,
    },
    {
      question:
        "In a certain code, 'PEAR' is written as 'QNBS'. How is 'GRAPE' written in that code?",
      options: ["HSBRF", "HSBQF", "HTBRF", "HSBQG"],
      correct_answer: 1,
    },
    {
      question:
        "Select the one which is different from the others: 3, 9, 12, 18, 21",
      options: ["12", "18", "21", "9"],
      correct_answer: 0,
    },
    {
      question:
        "If in a code language, 'MIRROR' is written as 'ORRIRM', how will 'WINDOW' be written?",
      options: ["WODNIW", "DNIWOD", "WODNWI", "WINDWO"],
      correct_answer: 0,
    },
  ],
  chsl2: [
    {
      question:
        "If the sum of three consecutive odd numbers is 63, what is the middle number?",
      options: ["19", "21", "23", "25"],
      correct_answer: 1,
    },
    {
      question:
        "In a class of 60 students, the average age of boys is 12 years and that of girls is 11 years. If the average age of the entire class is 11.4 years, how many girls are there in the class?",
      options: ["24", "30", "36", "40"],
      correct_answer: 2,
    },
    {
      question:
        "Two pipes A and B can fill a tank in 20 minutes and 30 minutes, respectively. If both are opened together, how much time will they take to fill the tank?",
      options: ["12 minutes", "15 minutes", "18 minutes", "24 minutes"],
      correct_answer: 0,
    },
    {
      question:
        "A person covers half of a journey at 60 km/h and the remaining half at 40 km/h. Find the average speed of the entire journey.",
      options: ["48 km/h", "50 km/h", "52 km/h", "54 km/h"],
      correct_answer: 0,
    },
    {
      question:
        "If 40% of a number is equal to two-thirds of another number, what is the ratio of the first number to the second number?",
      options: ["2:5", "3:5", "5:3", "5:2"],
      correct_answer: 3,
    },
    {
      question:
        "A man purchased a TV set at 20% discount on the labeled price. He sold it at 25% more than the labeled price. What is his profit percentage?",
      options: ["56.25%", "45%", "40%", "50%"],
      correct_answer: 0,
    },
    {
      question:
        "A mixture contains milk and water in the ratio 7:3. On adding 5 liters of water, the ratio of milk to water becomes 7:4. How much milk is there in the original mixture?",
      options: ["21 liters", "28 liters", "35 liters", "42 liters"],
      correct_answer: 2,
    },
    {
      question: "If 3x - 2y = 4 and xy = 6, what is the value of 9x² + 4y²?",
      options: ["84", "88", "92", "96"],
      correct_answer: 3,
    },
    {
      question:
        "A sum of money amounts to ₹2,520 in 2 years and ₹2,680 in 3 years at simple interest. What is the rate of interest?",
      options: ["6%", "7%", "8%", "9%"],
      correct_answer: 2,
    },
    {
      question:
        "A boat takes 3 hours to travel 15 km downstream and 2 hours to travel 10 km upstream. What is the speed of the boat in still water?",
      options: ["4.5 km/h", "5 km/h", "6 km/h", "7.5 km/h"],
      correct_answer: 2,
    },
    {
      question:
        "The ratio of the areas of two squares is 16:9. What is the ratio of their perimeters?",
      options: ["4:3", "9:16", "3:4", "5:4"],
      correct_answer: 0,
    },
    {
      question:
        "If 12 men can complete a piece of work in 8 days, how many days will 8 men take to complete the same work?",
      options: ["10 days", "12 days", "15 days", "18 days"],
      correct_answer: 1,
    },
    {
      question:
        "The average age of a group of 20 students is 18 years. If the age of the teacher is included, the average age increases by 1 year. What is the age of the teacher?",
      options: ["38 years", "39 years", "40 years", "41 years"],
      correct_answer: 2,
    },
    {
      question:
        "In how many different ways can the letters of the word 'LEADER' be arranged?",
      options: ["360", "720", "1440", "2160"],
      correct_answer: 2,
    },
    {
      question:
        "A cylindrical vessel of radius 7 cm and height 21 cm is filled with water. What is the total surface area of the vessel?",
      options: ["616 cm²", "924 cm²", "1386 cm²", "1848 cm²"],
      correct_answer: 2,
    },
    {
      question:
        "If a clock is showing 3:15, what is the angle between the hour hand and the minute hand?",
      options: ["0°", "7.5°", "30°", "37.5°"],
      correct_answer: 3,
    },
    {
      question:
        "The difference between the compound interest and simple interest on a certain sum for 2 years at 10% per annum is ₹25. What is the sum?",
      options: ["₹2,500", "₹3,000", "₹4,000", "₹5,000"],
      correct_answer: 3,
    },
    {
      question:
        "A sum of ₹9,600 is divided among A, B, and C in the ratio 5:7:8. What is C's share?",
      options: ["₹2,400", "₹2,800", "₹3,200", "₹3,600"],
      correct_answer: 2,
    },
    {
      question:
        "A shopkeeper allows a discount of 10% on the marked price of an article and still gains 20%. If the marked price of the article is ₹500, find the cost price.",
      options: ["₹350", "₹360", "₹375", "₹400"],
      correct_answer: 2,
    },
    {
      question:
        "If x² + y² = 25 and xy = 10, then what is the value of x⁴ + y⁴?",
      options: ["405", "425", "445", "465"],
      correct_answer: 2,
    },
    {
      question:
        "Which of the following acts as the primary source of law in India?",
      options: ["Judiciary", "Parliament", "Constitution", "Executive"],
      correct_answer: 2,
    },
    {
      question: "Who was the first Indian to win the Nobel Prize?",
      options: [
        "C.V. Raman",
        "Rabindranath Tagore",
        "Amartya Sen",
        "Mother Teresa",
      ],
      correct_answer: 1,
    },
    {
      question: "The Battle of Plassey was fought in which year?",
      options: ["1757", "1764", "1775", "1780"],
      correct_answer: 0,
    },
    {
      question: "Which of the following is not a constitutional body in India?",
      options: [
        "Election Commission",
        "Finance Commission",
        "National Development Council",
        "Union Public Service Commission",
      ],
      correct_answer: 2,
    },
    {
      question: "The famous book ‘Gitanjali’ was written by?",
      options: [
        "Sarojini Naidu",
        "Rabindranath Tagore",
        "Bankim Chandra Chatterjee",
        "Mahadevi Verma",
      ],
      correct_answer: 1,
    },
    {
      question:
        "Who among the following was the first Vice President of India?",
      options: [
        "Dr. Rajendra Prasad",
        "Dr. S. Radhakrishnan",
        "V.V. Giri",
        "Zakir Husain",
      ],
      correct_answer: 1,
    },
    {
      question:
        "Which of the following is the largest gland in the human body?",
      options: ["Pancreas", "Liver", "Thyroid", "Pituitary"],
      correct_answer: 1,
    },
    {
      question: "Which country is the largest producer of coffee in the world?",
      options: ["Vietnam", "India", "Brazil", "Colombia"],
      correct_answer: 2,
    },
    {
      question: "The Khajuraho temples were built by which dynasty?",
      options: ["Chola", "Pallava", "Chandela", "Rashtrakuta"],
      correct_answer: 2,
    },
    {
      question:
        "Which of the following rivers is also known as 'Dakshin Ganga'?",
      options: ["Godavari", "Krishna", "Kaveri", "Tungabhadra"],
      correct_answer: 0,
    },
    {
      question:
        "The Tropic of Cancer does not pass through which of the following states?",
      options: ["Rajasthan", "Odisha", "Chhattisgarh", "Tripura"],
      correct_answer: 1,
    },
    {
      question:
        "In which year did India conduct its first successful nuclear test?",
      options: ["1971", "1974", "1984", "1998"],
      correct_answer: 1,
    },
    {
      question:
        "Which of the following is the highest civilian award in India?",
      options: [
        "Padma Shri",
        "Padma Bhushan",
        "Padma Vibhushan",
        "Bharat Ratna",
      ],
      correct_answer: 3,
    },
    {
      question:
        "Which of the following mountain ranges forms the northern border of the Indian subcontinent?",
      options: ["Aravalli", "Vindhya", "Himalayas", "Western Ghats"],
      correct_answer: 2,
    },
    {
      question: "Which state in India has the longest coastline?",
      options: ["Tamil Nadu", "Maharashtra", "Gujarat", "West Bengal"],
      correct_answer: 2,
    },
    {
      question:
        "Which of the following rulers is known as the ‘Napoleon of India’?",
      options: ["Ashoka", "Chandragupta Maurya", "Samudragupta", "Harsha"],
      correct_answer: 2,
    },
    {
      question: "Who among the following wrote the book ‘Indica’?",
      options: ["Megasthenes", "Kautilya", "Kalidasa", "Fa Hien"],
      correct_answer: 0,
    },
    {
      question:
        "Which of the following countries shares the longest border with India?",
      options: ["China", "Bangladesh", "Pakistan", "Nepal"],
      correct_answer: 1,
    },
    {
      question: "The famous 'Dilwara Temples' are located in which state?",
      options: ["Madhya Pradesh", "Rajasthan", "Gujarat", "Karnataka"],
      correct_answer: 1,
    },
    {
      question:
        "Which of the following events marked the beginning of the Quit India Movement?",
      options: [
        "Salt Satyagraha",
        "Jallianwala Bagh Massacre",
        "August Kranti",
        "Non-Cooperation Movement",
      ],
      correct_answer: 2,
    },
    {
      question: "Find the odd one out: 125, 343, 729, 1331, 2025",
      options: ["125", "343", "729", "2025"],
      correct_answer: 3,
    },
    {
      question:
        "If in a certain code language, 'COMPUTER' is written as 'RFUOCPGT', how will 'INTERNET' be written?",
      options: ["RFMGOMGV", "RMFGOMVG", "RMFGOVMG", "RMFGOMGV"],
      correct_answer: 1,
    },
    {
      question:
        "In a certain code language, 'STUDENT' is written as 'TUDETSN'. How is 'TEACHER' written in that code?",
      options: ["ETCHEAR", "TEACRHE", "HETCREA", "HETRCEA"],
      correct_answer: 0,
    },
    {
      question: "Find the missing number in the series: 7, 14, 28, 56, ?, 224",
      options: ["84", "98", "112", "168"],
      correct_answer: 2,
    },
    {
      question: "If 3+5=16, 4+7=35, then 5+9=?",
      options: ["53", "63", "72", "81"],
      correct_answer: 1,
    },
    {
      question:
        "A clock is set to show the correct time at 12:00 PM. The clock loses 5 minutes every hour. What will be the actual time when the clock shows 11:00 PM?",
      options: ["12:30 AM", "1:00 AM", "12:00 AM", "12:15 AM"],
      correct_answer: 1,
    },
    {
      question:
        "Find the missing term in the following series: Z, X, T, P, L, ?",
      options: ["G", "H", "F", "I"],
      correct_answer: 2,
    },
    {
      question:
        "Choose the pair that does not share the same relationship as the others: Apple: Fruit, Carrot: Vegetable, Pen: Write, Spoon: Eat",
      options: [
        "Apple: Fruit",
        "Carrot: Vegetable",
        "Pen: Write",
        "Spoon: Eat",
      ],
      correct_answer: 3,
    },
    {
      question:
        "In a row of 20 boys, Raju is 12th from the left end. What is his position from the right end?",
      options: ["7th", "8th", "9th", "10th"],
      correct_answer: 2,
    },
    {
      question:
        "A man walks 3 km north, then turns right and walks 5 km. Then, he turns right and walks 7 km. How far is he now from the starting point?",
      options: ["5 km", "7 km", "10 km", "9 km"],
      correct_answer: 0,
    },
    {
      question:
        "Pointing to a photograph, a woman says, 'This man’s sister is my mother’s daughter.' How is the man related to the woman?",
      options: ["Uncle", "Brother", "Father", "Cousin"],
      correct_answer: 1,
    },
    {
      question:
        "In a certain code, 'ORANGE' is written as 'OEGANR'. How is 'MANGO' written in that code?",
      options: ["OMAGN", "OGNAM", "AMGON", "GAMNO"],
      correct_answer: 1,
    },
    {
      question:
        "In the series 2, 6, 12, 20, 30, ?, which number should come next?",
      options: ["38", "40", "42", "44"],
      correct_answer: 2,
    },
    {
      question:
        "Arrange the following words in alphabetical order: Leopard, Lizard, Lemur, Leopardess",
      options: [
        "Lemur, Leopard, Leopardess, Lizard",
        "Lemur, Leopardess, Leopard, Lizard",
        "Leopard, Leopardess, Lemur, Lizard",
        "Lemur, Leopard, Leopardess, Lizard",
      ],
      correct_answer: 0,
    },
    {
      question: "How many triangles are there in the following figure?",
      options: ["16", "18", "20", "22"],
      correct_answer: 2,
    },
    {
      question:
        "Select the missing number from the given alternatives: 5, 9, 17, 33, ?, 129",
      options: ["49", "57", "65", "81"],
      correct_answer: 1,
    },
    {
      question:
        "In a certain code language, 'MANGO' is written as 'PNQJO'. How is 'GUAVA' written in that code?",
      options: ["JVFYD", "JWAZB", "JXCYD", "HXWBB"],
      correct_answer: 2,
    },
    {
      question:
        "Select the word which cannot be formed using the letters of the word 'PARLIAMENT'.",
      options: ["PALM", "MART", "PRIME", "TRAIN"],
      correct_answer: 2,
    },
    {
      question: "Find the missing term in the series: A, D, I, P, ?",
      options: ["U", "V", "W", "X"],
      correct_answer: 3,
    },
    {
      question:
        "If in a certain code, 'SEND' is written as 'TFOE', how will 'MONEY' be written in that code?",
      options: ["NOPFX", "NOPXE", "NPOEX", "NPOEY"],
      correct_answer: 0,
    },
    {
      question:
        "Choose the word which is most nearly the same in meaning as the word 'ABATE'.",
      options: ["Increase", "Diminish", "Intensify", "Magnify"],
      correct_answer: 1,
    },
    {
      question: "Choose the correct synonym for the word 'ELOQUENT'.",
      options: ["Inarticulate", "Fluent", "Dull", "Weak"],
      correct_answer: 1,
    },
    {
      question: "Select the correct antonym of the word 'BENIGN'.",
      options: ["Harmless", "Noxious", "Mild", "Gentle"],
      correct_answer: 1,
    },
    {
      question: "Find the correct spelling of the word.",
      options: ["Occurence", "Occurrence", "Ocurrence", "Ocurrance"],
      correct_answer: 1,
    },
    {
      question: "Choose the correctly punctuated sentence.",
      options: [
        "She said I will go to the market, and buy some vegetables.",
        "She said, I will go to the market and buy some vegetables.",
        "She said I will go to the market and buy some vegetables.",
        "She said, I will go to the market, and buy some vegetables.",
      ],
      correct_answer: 1,
    },
    {
      question: "Select the sentence which is correctly punctuated.",
      options: [
        "The teacher asked the students, to be quiet.",
        "The teacher asked the students to be quiet.",
        "The teacher asked, the students to be quiet.",
        "The teacher, asked the students to be quiet.",
      ],
      correct_answer: 1,
    },
    {
      question:
        "Choose the appropriate word to fill in the blank: He was praised for his _____ in solving the complex problem.",
      options: ["Ignorance", "Apathy", "Skill", "Clumsiness"],
      correct_answer: 2,
    },
    {
      question: "Find the error in the sentence: The news are not very good.",
      options: ["The", "news", "are", "good"],
      correct_answer: 2,
    },
    {
      question:
        "Choose the word that best fits the blank: She is known for her _____ contributions to the community.",
      options: ["Negligible", "Insignificant", "Remarkable", "Trivial"],
      correct_answer: 2,
    },
    {
      question:
        "Choose the appropriate preposition to fill in the blank: He was convicted _____ theft.",
      options: ["On", "In", "For", "About"],
      correct_answer: 2,
    },
    {
      question:
        "Choose the correct form of the verb to complete the sentence: She _____ to the office every day.",
      options: ["Goes", "Going", "Go", "Gone"],
      correct_answer: 0,
    },
    {
      question: "Select the correctly spelled word.",
      options: ["Accommodate", "Accomodate", "Acommodate", "Accomoddate"],
      correct_answer: 0,
    },
    {
      question: "Find the synonym of the word 'DILIGENT'.",
      options: ["Lazy", "Careful", "Negligent", "Indifferent"],
      correct_answer: 1,
    },
    {
      question: "Choose the antonym of 'ENIGMA'.",
      options: ["Mystery", "Riddle", "Puzzle", "Solution"],
      correct_answer: 3,
    },
    {
      question: "Select the word which is opposite in meaning to 'EXUBERANT'.",
      options: ["Joyful", "Lively", "Depressed", "Energetic"],
      correct_answer: 2,
    },
    {
      question: "Identify the correctly written sentence.",
      options: [
        "He is good in singing.",
        "He is good on singing.",
        "He is good at singing.",
        "He is good to singing.",
      ],
      correct_answer: 2,
    },
    {
      question:
        "Choose the appropriate conjunction to fill in the blank: He missed the bus _____ he was late.",
      options: ["because", "but", "and", "so"],
      correct_answer: 0,
    },
    {
      question:
        "Select the sentence with the correct use of the past perfect tense.",
      options: [
        "She had finished her homework before she went to bed.",
        "She finished her homework before she had gone to bed.",
        "She has finished her homework before she went to bed.",
        "She had finished her homework after she went to bed.",
      ],
      correct_answer: 0,
    },
    {
      question:
        "Find the error in the sentence: The committee have made their decision.",
      options: ["The", "committee", "have", "their"],
      correct_answer: 2,
    },
    {
      question:
        "Choose the word which best fits the blank: The project was completed _____ schedule.",
      options: ["in", "on", "at", "by"],
      correct_answer: 1,
    },
    {
      question:
        "If the ratio of the ages of A and B is 5:4 and B is 12 years old, what is the age of A?",
      options: ["15 years", "16 years", "18 years", "20 years"],
      correct_answer: 2,
    },
    {
      question: "Solve the quadratic equation: 3x^2 - 12x + 12 = 0.",
      options: ["x = 2", "x = 3", "x = 4", "x = 6"],
      correct_answer: 1,
    },
    {
      question: "Find the area of a triangle with base 10 cm and height 12 cm.",
      options: ["60 cm²", "120 cm²", "150 cm²", "180 cm²"],
      correct_answer: 1,
    },
    {
      question:
        "The sum of the squares of two consecutive odd numbers is 394. What are the numbers?",
      options: ["13 and 15", "17 and 19", "21 and 23", "25 and 27"],
      correct_answer: 1,
    },
    {
      question:
        "A train travels at a speed of 60 km/h. How long will it take to cover a distance of 180 km?",
      options: ["2 hours", "3 hours", "4 hours", "5 hours"],
      correct_answer: 1,
    },
    {
      question: "If the circumference of a circle is 44 cm, find its radius.",
      options: ["7 cm", "14 cm", "22 cm", "28 cm"],
      correct_answer: 0,
    },
    {
      question:
        "A mixture contains 30% of alcohol. How much alcohol is there in 2 liters of the mixture?",
      options: ["0.6 liters", "0.8 liters", "1 liter", "1.2 liters"],
      correct_answer: 1,
    },
    {
      question: "Find the value of x if 2^x = 64.",
      options: ["4", "5", "6", "7"],
      correct_answer: 1,
    },
    {
      question:
        "The average of 5 numbers is 20. If one number is 15, what is the average of the remaining 4 numbers?",
      options: ["18", "19", "20", "21"],
      correct_answer: 1,
    },
    {
      question:
        "If the sides of a right-angled triangle are in the ratio 3:4:5, what is the length of the hypotenuse if the shortest side is 6 cm?",
      options: ["8 cm", "10 cm", "12 cm", "15 cm"],
      correct_answer: 1,
    },

    {
      question:
        "Find the compound interest on a principal of Rs. 1000 at an annual interest rate of 5% compounded annually for 2 years.",
      options: ["Rs. 105", "Rs. 110.25", "Rs. 110", "Rs. 112.50"],
      correct_answer: 1,
    },
    {
      question:
        "What is the median of the following data set: 8, 12, 15, 20, 22?",
      options: ["15", "16", "18", "20"],
      correct_answer: 0,
    },
    {
      question:
        "A person bought 12 pencils for Rs. 60. If he sells each pencil at Rs. 6, what is his profit percentage?",
      options: ["20%", "25%", "30%", "40%"],
      correct_answer: 1,
    },
    {
      question: "Solve for y in the equation: 3y - 7 = 2y + 5.",
      options: ["2", "7", "12", "14"],
      correct_answer: 1,
    },
    {
      question: "Find the value of sin 30 degrees.",
      options: ["1/2", "√2/2", "√3/2", "1"],
      correct_answer: 0,
    },
    {
      question:
        "What is the probability of getting a head in a single toss of a fair coin?",
      options: ["1/2", "1/4", "1/3", "1"],
      correct_answer: 0,
    },
    {
      question: "Find the area of a square with a side length of 8 cm.",
      options: ["64 cm²", "32 cm²", "16 cm²", "128 cm²"],
      correct_answer: 0,
    },
    {
      question: "What is the volume of a cube with a side length of 5 cm?",
      options: ["125 cm³", "100 cm³", "150 cm³", "200 cm³"],
      correct_answer: 0,
    },
    {
      question: "If the sum of two angles is 90 degrees, what are they called?",
      options: [
        "Supplementary angles",
        "Complementary angles",
        "Adjacent angles",
        "Vertical angles",
      ],
      correct_answer: 1,
    },
    {
      question:
        "The perimeter of a rectangle is 40 cm and its length is 12 cm. What is the width of the rectangle?",
      options: ["8 cm", "10 cm", "12 cm", "14 cm"],
      correct_answer: 0,
    },
  ],
  ibpspo1: [
    {
      question_number: 1,
      question:
        "A sum of ₹15,000 is invested in two schemes. The first scheme offers 5% per annum simple interest, and the second offers 8% per annum simple interest. If the total interest after 2 years is ₹1,980, what is the amount invested in the first scheme?",
      options: {
        A: "₹6,000",
        B: "₹7,500",
        C: "₹8,000",
        D: "₹9,000",
      },
      correct_answer: "C",
    },
    {
      question_number: 2,
      question: "What is the next term in the series 3, 9, 27, 81, __?",
      options: {
        A: "162",
        B: "243",
        C: "324",
        D: "729",
      },
      correct_answer: "B",
    },
    {
      question_number: 3,
      question: "Choose the correct synonym of the word 'ECCENTRIC'.",
      options: {
        A: "Conventional",
        B: "Normal",
        C: "Unusual",
        D: "Common",
      },
      correct_answer: "C",
    },
    {
      question_number: 4,
      question:
        "A trader mixes two varieties of rice costing ₹30 per kg and ₹45 per kg in the ratio 3:2. What is the cost per kg of the mixture?",
      options: {
        A: "₹36",
        B: "₹37.50",
        C: "₹38",
        D: "₹39",
      },
      correct_answer: "A",
    },
    {
      question_number: 5,
      question:
        "If the letters of the word 'ORANGE' are arranged in alphabetical order, which letter will be in the middle?",
      options: {
        A: "A",
        B: "E",
        C: "G",
        D: "N",
      },
      correct_answer: "C",
    },
    {
      question_number: 6,
      question: "Find the value of x in the equation: 4^(2x - 3) = 64.",
      options: {
        A: "3",
        B: "2.5",
        C: "2",
        D: "1.5",
      },
      correct_answer: "A",
    },
    {
      question_number: 7,
      question:
        "In a certain code language, 'DOG' is written as 'FIQ'. How will 'CAT' be written in that code?",
      options: {
        A: "EBV",
        B: "ECU",
        C: "EBW",
        D: "DCU",
      },
      correct_answer: "A",
    },
    {
      question_number: 8,
      question:
        "If the average of five consecutive even numbers is 28, what is the largest number?",
      options: {
        A: "32",
        B: "34",
        C: "36",
        D: "38",
      },
      correct_answer: "A",
    },
    {
      question_number: 9,
      question: "Choose the correct antonym of the word 'AUDACIOUS'.",
      options: {
        A: "Bold",
        B: "Timid",
        C: "Daring",
        D: "Brave",
      },
      correct_answer: "B",
    },
    {
      question_number: 10,
      question:
        "A boat takes 6 hours to travel 36 km upstream and 2 hours to travel 24 km downstream. What is the speed of the boat in still water?",
      options: {
        A: "7 km/h",
        B: "8 km/h",
        C: "9 km/h",
        D: "10 km/h",
      },
      correct_answer: "C",
    },
    {
      question_number: 11,
      question:
        "Which of the following is closest in meaning to the word 'PERSPICACIOUS'?",
      options: {
        A: "Oblivious",
        B: "Perceptive",
        C: "Dull",
        D: "Confused",
      },
      correct_answer: "B",
    },
    {
      question_number: 12,
      question:
        "A man buys an article for ₹1,500 and sells it at a loss of 10%. What is the selling price of the article?",
      options: {
        A: "₹1,350",
        B: "₹1,300",
        C: "₹1,250",
        D: "₹1,200",
      },
      correct_answer: "A",
    },
    {
      question_number: 13,
      question:
        "Choose the word which is most nearly the same in meaning as the word 'MELANCHOLY'.",
      options: {
        A: "Cheerful",
        B: "Happy",
        C: "Sorrowful",
        D: "Joyous",
      },
      correct_answer: "C",
    },
    {
      question_number: 14,
      question:
        "If a number is divided by 6, the remainder is 4. What will be the remainder when the square of the number is divided by 6?",
      options: {
        A: "2",
        B: "3",
        C: "4",
        D: "0",
      },
      correct_answer: "D",
    },
    {
      question_number: 15,
      question: "Find the missing number in the series: 12, 15, 19, 24, __.",
      options: {
        A: "28",
        B: "29",
        C: "30",
        D: "32",
      },
      correct_answer: "B",
    },
    {
      question_number: 16,
      question:
        "The length of a rectangle is increased by 25%, and the breadth is decreased by 20%. What is the percentage change in the area?",
      options: {
        A: "0%",
        B: "2%",
        C: "5%",
        D: "10%",
      },
      correct_answer: "C",
    },
    {
      question_number: 17,
      question:
        "Which of the following is the correct meaning of the idiom 'To bell the cat'?",
      options: {
        A: "To be a coward",
        B: "To take on a difficult task",
        C: "To run away",
        D: "To act foolishly",
      },
      correct_answer: "B",
    },
    {
      question_number: 18,
      question:
        "If a person can walk 3/4th of his usual speed, how much time will he take to cover a distance that he would cover in 1 hour at his usual speed?",
      options: {
        A: "1 hour 15 minutes",
        B: "1 hour 20 minutes",
        C: "1 hour 25 minutes",
        D: "1 hour 30 minutes",
      },
      correct_answer: "D",
    },
    {
      question_number: 19,
      question: "Which of the following numbers is a perfect square?",
      options: {
        A: "2500",
        B: "2700",
        C: "2800",
        D: "2900",
      },
      correct_answer: "A",
    },
    {
      question_number: 20,
      question:
        "A man rows to a place 48 km distant and back in 14 hours. He finds that he can row 4 km with the stream in the same time as 3 km against the stream. What is the speed of the stream?",
      options: {
        A: "1 km/h",
        B: "1.5 km/h",
        C: "2 km/h",
        D: "2.5 km/h",
      },
      correct_answer: "C",
    },
    [
      {
        question_number: 21,
        question:
          "A man buys a certain number of oranges at 5 for ₹10 and sells them at 4 for ₹10. What is his gain or loss percentage?",
        options: {
          A: "20% gain",
          B: "25% gain",
          C: "20% loss",
          D: "25% loss",
        },
        correct_answer: "B",
      },
      {
        question_number: 22,
        question:
          "Which of the following words is most nearly opposite in meaning to the word 'BENEVOLENT'?",
        options: {
          A: "Charitable",
          B: "Malicious",
          C: "Kind",
          D: "Compassionate",
        },
        correct_answer: "B",
      },
      {
        question_number: 23,
        question:
          "In a certain code language, 'FOUR' is written as 'SPHK'. How would 'NINE' be written in that code?",
        options: {
          A: "QMPL",
          B: "OMQK",
          C: "MQLP",
          D: "PMQL",
        },
        correct_answer: "A",
      },
      {
        question_number: 24,
        question:
          "The average of four consecutive odd numbers is 32. What is the greatest number?",
        options: {
          A: "33",
          B: "35",
          C: "37",
          D: "39",
        },
        correct_answer: "C",
      },
      {
        question_number: 25,
        question:
          "Which of the following statements is correct regarding a Parliamentary form of government?",
        options: {
          A: "The executive is independent of the legislature.",
          B: "The executive is responsible to the legislature.",
          C: "The judiciary is responsible to the legislature.",
          D: "The legislature is independent of the executive.",
        },
        correct_answer: "B",
      },
      {
        question_number: 26,
        question: "Solve the equation: √(x + 15) = 7.",
        options: {
          A: "34",
          B: "49",
          C: "35",
          D: "36",
        },
        correct_answer: "C",
      },
      {
        question_number: 27,
        question:
          "A shopkeeper marks up his goods by 25% and then offers a discount of 20%. What is his overall profit or loss percentage?",
        options: {
          A: "Profit 2%",
          B: "Loss 2%",
          C: "Profit 4%",
          D: "Loss 4%",
        },
        correct_answer: "B",
      },
      {
        question_number: 28,
        question: "Choose the correctly spelled word.",
        options: {
          A: "Accomodate",
          B: "Accommodate",
          C: "Acommodate",
          D: "Acomodate",
        },
        correct_answer: "B",
      },
      {
        question_number: 29,
        question:
          "What is the compound interest on ₹12,000 at 10% per annum for 2 years, compounded annually?",
        options: {
          A: "₹2,420",
          B: "₹2,500",
          C: "₹2,520",
          D: "₹2,600",
        },
        correct_answer: "C",
      },
      {
        question_number: 30,
        question:
          "Which of the following sentences uses the correct form of the verb?",
        options: {
          A: "She don't like coffee.",
          B: "He doesn't knows the answer.",
          C: "They doesn't know the rules.",
          D: "She doesn't like coffee.",
        },
        correct_answer: "D",
      },
      {
        question_number: 31,
        question: "Find the next number in the sequence: 2, 5, 10, 17, 26, __.",
        options: {
          A: "37",
          B: "35",
          C: "38",
          D: "36",
        },
        correct_answer: "A",
      },
      {
        question_number: 32,
        question: "Which of the following is a constitutional right in India?",
        options: {
          A: "Right to Vote",
          B: "Right to Property",
          C: "Right to Privacy",
          D: "Right to Education",
        },
        correct_answer: "D",
      },
      {
        question_number: 33,
        question:
          "A train 300 meters long is running at a speed of 90 km/h. How long will it take to cross a platform 200 meters long?",
        options: {
          A: "20 seconds",
          B: "24 seconds",
          C: "28 seconds",
          D: "30 seconds",
        },
        correct_answer: "B",
      },
      {
        question_number: 34,
        question:
          "Which of the following is closest in meaning to the word 'OBSTINATE'?",
        options: {
          A: "Stubborn",
          B: "Flexible",
          C: "Timid",
          D: "Submissive",
        },
        correct_answer: "A",
      },
      {
        question_number: 35,
        question:
          "The area of a circle is 154 square cm. What is the circumference of the circle?",
        options: {
          A: "22 cm",
          B: "44 cm",
          C: "38.5 cm",
          D: "30.5 cm",
        },
        correct_answer: "B",
      },
      {
        question_number: 36,
        question:
          "Which of the following is the correct meaning of the idiom 'To turn a blind eye'?",
        options: {
          A: "To ignore something",
          B: "To pay attention",
          C: "To be cautious",
          D: "To be curious",
        },
        correct_answer: "A",
      },
      {
        question_number: 37,
        question:
          "If a triangle has sides 7 cm, 24 cm, and 25 cm, what type of triangle is it?",
        options: {
          A: "Scalene",
          B: "Right-angled",
          C: "Isosceles",
          D: "Equilateral",
        },
        correct_answer: "B",
      },
      {
        question_number: 38,
        question:
          "The average of five numbers is 50. If one number is excluded, the average of the remaining four numbers is 48. What is the excluded number?",
        options: {
          A: "50",
          B: "55",
          C: "60",
          D: "65",
        },
        correct_answer: "C",
      },
      {
        question_number: 39,
        question:
          "Which of the following countries does not share a border with India?",
        options: {
          A: "Nepal",
          B: "Bhutan",
          C: "Myanmar",
          D: "Vietnam",
        },
        correct_answer: "D",
      },
      {
        question_number: 40,
        question: "What is the next term in the series: 5, 10, 20, 40, __?",
        options: {
          A: "60",
          B: "70",
          C: "80",
          D: "90",
        },
        correct_answer: "C",
      },
      [
        {
          question_number: 41,
          question:
            "Which of the following is the smallest prime number greater than 50?",
          options: {
            A: "51",
            B: "53",
            C: "59",
            D: "61",
          },
          correct_answer: "B",
        },
        {
          question_number: 42,
          question:
            "Fill in the blank: She is not only intelligent _______ also very hard-working.",
          options: {
            A: "and",
            B: "but",
            C: "as",
            D: "or",
          },
          correct_answer: "B",
        },
        {
          question_number: 43,
          question:
            "If the simple interest on a sum of money for 2 years at 5% per annum is ₹400, what is the principal amount?",
          options: {
            A: "₹4000",
            B: "₹5000",
            C: "₹6000",
            D: "₹8000",
          },
          correct_answer: "A",
        },
        {
          question_number: 44,
          question:
            "Which one of the following is not an official language of the United Nations?",
          options: {
            A: "Arabic",
            B: "Chinese",
            C: "Russian",
            D: "German",
          },
          correct_answer: "D",
        },
        {
          question_number: 45,
          question:
            "If a code language 'ROAD' is written as 'TQBF', how will 'MARK' be written in that code?",
          options: {
            A: "NBUL",
            B: "OCTM",
            C: "OBSL",
            D: "LZQJ",
          },
          correct_answer: "C",
        },
        {
          question_number: 46,
          question:
            "The perimeter of a rectangle is 60 meters. If the length is 5 meters more than the breadth, find the length of the rectangle.",
          options: {
            A: "15 meters",
            B: "20 meters",
            C: "25 meters",
            D: "30 meters",
          },
          correct_answer: "B",
        },
        {
          question_number: 47,
          question: "Who among the following wrote 'The God of Small Things'?",
          options: {
            A: "Arundhati Roy",
            B: "Salman Rushdie",
            C: "Vikram Seth",
            D: "Jhumpa Lahiri",
          },
          correct_answer: "A",
        },
        {
          question_number: 48,
          question:
            "The length of a diagonal of a square is 10√2 cm. What is the area of the square?",
          options: {
            A: "100 cm²",
            B: "200 cm²",
            C: "150 cm²",
            D: "250 cm²",
          },
          correct_answer: "A",
        },
        {
          question_number: 49,
          question:
            "Which of the following sentences is grammatically correct?",
          options: {
            A: "He don't know the answer.",
            B: "She didn't went to the market.",
            C: "They doesn't likes tea.",
            D: "She didn't go to the market.",
          },
          correct_answer: "D",
        },
        {
          question_number: 50,
          question: "Find the odd one out: 121, 144, 169, 210, 225.",
          options: {
            A: "121",
            B: "169",
            C: "210",
            D: "225",
          },
          correct_answer: "C",
        },
        {
          question_number: 51,
          question:
            "The sum of the ages of a father and his son is 60 years. If the father is thrice as old as his son, what is the son's age?",
          options: {
            A: "10 years",
            B: "15 years",
            C: "20 years",
            D: "25 years",
          },
          correct_answer: "B",
        },
        {
          question_number: 52,
          question:
            "Which of the following is the correct meaning of the idiom 'Break the ice'?",
          options: {
            A: "To start a conversation",
            B: "To break a relationship",
            C: "To cool down",
            D: "To end a quarrel",
          },
          correct_answer: "A",
        },
        {
          question_number: 53,
          question:
            "What is the compound interest on ₹5,000 at 8% per annum for 3 years, compounded annually?",
          options: {
            A: "₹1,256.64",
            B: "₹1,260.80",
            C: "₹1,262.48",
            D: "₹1,264.88",
          },
          correct_answer: "D",
        },
        {
          question_number: 54,
          question:
            "The distance between two places A and B is 120 km. A car travels from A to B at a speed of 60 km/h and returns at a speed of 40 km/h. What is the average speed of the car for the whole journey?",
          options: {
            A: "48 km/h",
            B: "50 km/h",
            C: "52 km/h",
            D: "54 km/h",
          },
          correct_answer: "A",
        },
        {
          question_number: 55,
          question:
            "In a certain code language, 'COIN' is written as 'CIPM'. How would 'MINE' be written in that code?",
          options: {
            A: "MIPN",
            B: "MINP",
            C: "MIPM",
            D: "MIMN",
          },
          correct_answer: "A",
        },
        {
          question_number: 56,
          question: "Who is the author of the book 'The Origin of Species'?",
          options: {
            A: "Isaac Newton",
            B: "Albert Einstein",
            C: "Charles Darwin",
            D: "Galileo Galilei",
          },
          correct_answer: "C",
        },
        {
          question_number: 57,
          question:
            "A man invests ₹10,000 at 5% per annum simple interest for 3 years. How much interest will he earn?",
          options: {
            A: "₹1,500",
            B: "₹2,000",
            C: "₹2,500",
            D: "₹3,000",
          },
          correct_answer: "A",
        },
        {
          question_number: 58,
          question:
            "The headquarters of the United Nations is located in which city?",
          options: {
            A: "Geneva",
            B: "New York",
            C: "Paris",
            D: "London",
          },
          correct_answer: "B",
        },
        {
          question_number: 59,
          question:
            "Find the smallest number which when divided by 12, 16, and 24 leaves no remainder.",
          options: {
            A: "48",
            B: "96",
            C: "144",
            D: "192",
          },
          correct_answer: "B",
        },
        {
          question_number: 60,
          question: "What is the synonym of the word 'ABUNDANT'?",
          options: {
            A: "Scarce",
            B: "Limited",
            C: "Plentiful",
            D: "Rare",
          },
          correct_answer: "C",
        },
        [
          {
            question_number: 61,
            question: "If 3x + 2 = 11, what is the value of x?",
            options: {
              A: "2",
              B: "3",
              C: "4",
              D: "5",
            },
            correct_answer: "A",
          },
          {
            question_number: 62,
            question:
              "Which of the following words is most nearly opposite in meaning to 'Exemplary'?",
            options: {
              A: "Notable",
              B: "Reprehensible",
              C: "Ideal",
              D: "Admirable",
            },
            correct_answer: "B",
          },
          {
            question_number: 63,
            question:
              "Which of the following is an example of a non-renewable resource?",
            options: {
              A: "Solar energy",
              B: "Wind energy",
              C: "Coal",
              D: "Hydropower",
            },
            correct_answer: "C",
          },
          {
            question_number: 64,
            question:
              "A train travels at a speed of 60 km/h and covers a certain distance in 6 hours. What is the distance covered by the train?",
            options: {
              A: "300 km",
              B: "360 km",
              C: "400 km",
              D: "450 km",
            },
            correct_answer: "B",
          },
          {
            question_number: 65,
            question:
              "In a certain code language, 'DEAR' is written as 'ABCN'. How will 'FIRE' be written in that code?",
            options: {
              A: "CEQB",
              B: "EFQB",
              C: "CFOB",
              D: "DFQB",
            },
            correct_answer: "A",
          },
          {
            question_number: 66,
            question: "The sum of the first 50 natural numbers is:",
            options: {
              A: "1,225",
              B: "1,275",
              C: "1,325",
              D: "1,375",
            },
            correct_answer: "C",
          },
          {
            question_number: 67,
            question:
              "Who is the author of the book 'The Argumentative Indian'?",
            options: {
              A: "Amartya Sen",
              B: "V.S. Naipaul",
              C: "Arundhati Roy",
              D: "Salman Rushdie",
            },
            correct_answer: "A",
          },
          {
            question_number: 68,
            question:
              "In a sequence of numbers, each term after the first is obtained by multiplying the previous term by 2 and then subtracting 1. If the first term is 2, what is the fourth term?",
            options: {
              A: "7",
              B: "13",
              C: "25",
              D: "49",
            },
            correct_answer: "B",
          },
          {
            question_number: 69,
            question: "The Battle of Plassey was fought in the year:",
            options: {
              A: "1747",
              B: "1757",
              C: "1767",
              D: "1777",
            },
            correct_answer: "B",
          },
          {
            question_number: 70,
            question: "What is the average of first five prime numbers?",
            options: {
              A: "5.6",
              B: "5.4",
              C: "6.2",
              D: "7.0",
            },
            correct_answer: "C",
          },
          {
            question_number: 71,
            question:
              "Which of the following countries is not a member of the G7?",
            options: {
              A: "Canada",
              B: "Russia",
              C: "Japan",
              D: "Germany",
            },
            correct_answer: "B",
          },
          {
            question_number: 72,
            question: "Simplify the expression: 2/3 + 3/4 - 1/6",
            options: {
              A: "1",
              B: "3/2",
              C: "13/12",
              D: "17/12",
            },
            correct_answer: "C",
          },
          {
            question_number: 73,
            question:
              "Which of the following rivers does not originate in India?",
            options: {
              A: "Ganga",
              B: "Yamuna",
              C: "Indus",
              D: "Brahmaputra",
            },
            correct_answer: "D",
          },
          {
            question_number: 74,
            question:
              "A number when divided by 84 leaves a remainder 29. What will be the remainder when the same number is divided by 12?",
            options: {
              A: "5",
              B: "7",
              C: "8",
              D: "11",
            },
            correct_answer: "B",
          },
          {
            question_number: 75,
            question:
              "Which among the following is the hardest natural substance?",
            options: {
              A: "Gold",
              B: "Iron",
              C: "Diamond",
              D: "Platinum",
            },
            correct_answer: "C",
          },
          {
            question_number: 76,
            question:
              "If a shopkeeper offers a discount of 20% on the marked price of an article and still makes a profit of 25%, what is the cost price of the article if its marked price is ₹1,000?",
            options: {
              A: "₹600",
              B: "₹625",
              C: "₹700",
              D: "₹750",
            },
            correct_answer: "B",
          },
          {
            question_number: 77,
            question:
              "Which of the following is the correct order of words in the dictionary?",
            options: {
              A: "Mango, Maple, Mane, Many",
              B: "Maple, Mango, Mane, Many",
              C: "Many, Mane, Mango, Maple",
              D: "Mane, Mango, Many, Maple",
            },
            correct_answer: "D",
          },
          {
            question_number: 78,
            question:
              "What is the ratio of the circumference of a circle to its diameter?",
            options: {
              A: "2π",
              B: "π",
              C: "1/π",
              D: "2",
            },
            correct_answer: "B",
          },
          {
            question_number: 79,
            question:
              "Who among the following is known as the 'Father of the Indian Constitution'?",
            options: {
              A: "Mahatma Gandhi",
              B: "Jawaharlal Nehru",
              C: "B.R. Ambedkar",
              D: "Sardar Vallabhbhai Patel",
            },
            correct_answer: "C",
          },
          {
            question_number: 80,
            question:
              "In a partnership, A invests ₹12,000 for 6 months and B invests ₹18,000 for 4 months. If they earn a profit of ₹6,000, what is A's share?",
            options: {
              A: "₹2,400",
              B: "₹3,000",
              C: "₹3,600",
              D: "₹4,000",
            },
            correct_answer: "A",
          },
        ],
      ],
    ],
  ],
  mts1: [
    {
      question:
        "If the ratio of the ages of A and B is 5:3 and the sum of their ages is 40 years, what is the age of A?",
      options: ["20 years", "25 years", "30 years", "35 years"],
      correct_answer: 1,
    },
    {
      question:
        "A shopkeeper sells an article for Rs. 1200, making a profit of 20%. What is the cost price of the article?",
      options: ["Rs. 1000", "Rs. 1020", "Rs. 1050", "Rs. 1100"],
      correct_answer: 0,
    },
    {
      question:
        "A train travels at a speed of 80 km/h. How long will it take to cover a distance of 200 km?",
      options: ["2 hours", "2.5 hours", "3 hours", "2.5 hours"],
      correct_answer: 1,
    },
    {
      question:
        "The area of a right-angled triangle is 48 cm² and its base is 8 cm. What is its height?",
      options: ["6 cm", "8 cm", "12 cm", "15 cm"],
      correct_answer: 0,
    },
    {
      question:
        "If a number is increased by 25% and the result is 60, what was the original number?",
      options: ["40", "45", "48", "50"],
      correct_answer: 3,
    },
    {
      question: "Solve for x in the equation: 3x + 7 = 22.",
      options: ["3", "5", "7", "10"],
      correct_answer: 1,
    },
    {
      question:
        "A rectangle has a perimeter of 50 cm and a length of 15 cm. What is the width of the rectangle?",
      options: ["10 cm", "12 cm", "15 cm", "20 cm"],
      correct_answer: 0,
    },
    {
      question:
        "What is the volume of a cuboid with dimensions 4 cm × 5 cm × 6 cm?",
      options: ["100 cm³", "120 cm³", "140 cm³", "150 cm³"],
      correct_answer: 1,
    },
    {
      question:
        "Find the simple interest on a principal of Rs. 1500 at an annual interest rate of 4% for 3 years.",
      options: ["Rs. 180", "Rs. 200", "Rs. 220", "Rs. 240"],
      correct_answer: 0,
    },
    {
      question: "What is the greatest common divisor (GCD) of 36 and 60?",
      options: ["6", "12", "18", "24"],
      correct_answer: 1,
    },
    {
      question:
        "If all squares are rectangles, and all rectangles are parallelograms, which of the following is definitely true?",
      options: [
        "All parallelograms are squares.",
        "All squares are parallelograms.",
        "All rectangles are squares.",
        "All parallelograms are rectangles.",
      ],
      correct_answer: 1,
    },
    {
      question: "Find the missing number in the series: 5, 11, 19, 29, ?",
      options: ["39", "41", "43", "47"],
      correct_answer: 1,
    },
    {
      question:
        "In a certain code, 'FISH' is written as 'HUKJ'. How is 'BIRD' written in that code?",
      options: ["DJTF", "DJSF", "DJUE", "CKSF"],
      correct_answer: 0,
    },
    {
      question:
        "A man is facing East. He turns 90 degrees clockwise and then 90 degrees anticlockwise. What direction is he facing now?",
      options: ["North", "South", "East", "West"],
      correct_answer: 2,
    },
    {
      question: "Find the odd one out: 11, 17, 19, 23, 27",
      options: ["11", "17", "19", "27"],
      correct_answer: 3,
    },
    {
      question: "If 'ABCD' is coded as 'CDAB', how will 'WXYZ' be coded?",
      options: ["YZWX", "ZYWX", "YZWX", "WXZY"],
      correct_answer: 0,
    },

    {
      question:
        "If a circle is divided into 8 equal parts, what is the angle of each part?",
      options: ["30 degrees", "45 degrees", "60 degrees", "90 degrees"],
      correct_answer: 1,
    },
    {
      question:
        "In a certain code, 'JUMP' is written as 'KVNQ'. How is 'LEAP' written?",
      options: ["MFQZ", "MFQC", "MFRA", "MFRB"],
      correct_answer: 0,
    },
    {
      question: "What comes next in the sequence: 3, 6, 11, 18, ?",
      options: ["28", "30", "32", "34"],
      correct_answer: 0,
    },
    {
      question:
        "If 'BEACH' is written as 'DFBID', how is 'OCEAN' written in that code?",
      options: ["PFDPO", "PFDPE", "PEFDP", "PFEBO"],
      correct_answer: 0,
    },
    {
      question: "Who is known as the 'Father of the Nation' in India?",
      options: [
        "Jawaharlal Nehru",
        "Sardar Patel",
        "Mahatma Gandhi",
        "Baba Saheb Ambedkar",
      ],
      correct_answer: 2,
    },
    {
      question: "Which is the largest continent by land area?",
      options: ["Africa", "Asia", "Europe", "North America"],
      correct_answer: 1,
    },
    {
      question: "Who wrote the book 'To Kill a Mockingbird'?",
      options: [
        "Harper Lee",
        "J.D. Salinger",
        "F. Scott Fitzgerald",
        "Ernest Hemingway",
      ],
      correct_answer: 0,
    },
    {
      question: "Which planet is known as the 'Red Planet'?",
      options: ["Venus", "Mars", "Jupiter", "Saturn"],
      correct_answer: 1,
    },
    {
      question: "Who was the first President of the United States?",
      options: [
        "George Washington",
        "Thomas Jefferson",
        "Abraham Lincoln",
        "John Adams",
      ],
      correct_answer: 0,
    },
    {
      question: "Which is the longest river in the world?",
      options: ["Amazon", "Nile", "Yangtze", "Mississippi"],
      correct_answer: 1,
    },
    {
      question: "Who is known for the theory of relativity?",
      options: {
        0: "Isaac Newton",
        1: "Albert Einstein",
        2: "Galileo Galilei",
        3: "Niels Bohr",
      },
      correct_answer: "1",
    },
    {
      question: "In which year did India gain independence from British rule?",
      options: {
        0: "1942",
        1: "1945",
        2: "1947",
        3: "1950",
      },
      correct_answer: "2",
    },
    {
      question: "What is the chemical symbol for Gold?",
      options: {
        0: "Au",
        1: "Ag",
        2: "Fe",
        3: "Pb",
      },
      correct_answer: "0",
    },
    {
      question: "Which country is known as the 'Land of the Rising Sun'?",
      options: {
        0: "China",
        1: "Japan",
        2: "South Korea",
        3: "Thailand",
      },
      correct_answer: "1",
    },
    {
      question: "What is the capital city of Australia?",
      options: {
        0: "Sydney",
        1: "Melbourne",
        2: "Canberra",
        3: "Brisbane",
      },
      correct_answer: "2",
    },
    {
      question: "Which is the smallest bone in the human body?",
      options: {
        0: "Stapes",
        1: "Femur",
        2: "Humerus",
        3: "Tibia",
      },
      correct_answer: "0",
    },
    {
      question: "Who painted the 'Mona Lisa'?",
      options: {
        0: "Vincent Van Gogh",
        1: "Leonardo da Vinci",
        2: "Pablo Picasso",
        3: "Claude Monet",
      },
      correct_answer: "1",
    },
    {
      question:
        "A man is facing South. He turns 135 degrees clockwise. What direction is he facing now?",
      options: {
        0: "North-East",
        1: "South-East",
        2: "North-West",
        3: "South-West",
      },
      correct_answer: "1",
    },
    {
      question:
        "If 'PRINTER' is coded as 'SQUIJFS', what is the code for 'MACHINE'?",
      options: {
        0: "NBMJOF",
        1: "NBJDOF",
        2: "NBJHF",
        3: "NCHJOF",
      },
      correct_answer: "0",
    },
    {
      question: "Find the next number in the series: 2, 5, 10, 17, ?",
      options: {
        0: "26",
        1: "28",
        2: "30",
        3: "32",
      },
      correct_answer: "0",
    },
    {
      question: "Which of the following figures is different from the others?",
      options: {
        0: "Circle",
        1: "Square",
        2: "Triangle",
        3: "Rectangle",
      },
      correct_answer: "0",
    },
    {
      question:
        "In a certain code language, 'FRIEND' is written as 'GSKJOF'. How is 'FAMILY' written?",
      options: {
        0: "GBNJLZ",
        1: "GBNKMZ",
        2: "GBMZJZ",
        3: "GBMKZB",
      },
      correct_answer: "0",
    },
    {
      question:
        "If 'A' is coded as 'Z', 'B' is coded as 'Y', what will 'D' be coded as?",
      options: {
        0: "W",
        1: "X",
        2: "Y",
        3: "Z",
      },
      correct_answer: "0",
    },
    {
      question:
        "Which number should replace the question mark in the series: 7, 14, 28, ?, 112?",
      options: {
        0: "42",
        1: "56",
        2: "84",
        3: "70",
      },
      correct_answer: "1",
    },
    {
      question: "Which is the largest ocean on Earth?",
      options: {
        0: "Atlantic Ocean",
        1: "Indian Ocean",
        2: "Arctic Ocean",
        3: "Pacific Ocean",
      },
      correct_answer: "3",
    },
    {
      question: "Which country is known as the 'Land of the Midnight Sun'?",
      options: {
        0: "Norway",
        1: "Sweden",
        2: "Finland",
        3: "Denmark",
      },
      correct_answer: "0",
    },
    {
      question: "Who is the author of the book 'Pride and Prejudice'?",
      options: {
        0: "Jane Austen",
        1: "Charlotte Brontë",
        2: "Emily Brontë",
        3: "Mary Shelley",
      },
      correct_answer: "0",
    },
    {
      question: "Which element has the chemical symbol 'Na'?",
      options: {
        0: "Sodium",
        1: "Neon",
        2: "Nickel",
        3: "Nitrogen",
      },
      correct_answer: "0",
    },
    {
      question: "What is the capital city of Canada?",
      options: {
        0: "Toronto",
        1: "Ottawa",
        2: "Vancouver",
        3: "Montreal",
      },
      correct_answer: "1",
    },
    {
      question: "Which war was fought between 1914 and 1918?",
      options: {
        0: "World War I",
        1: "World War II",
        2: "The Korean War",
        3: "The Vietnam War",
      },
      correct_answer: "0",
    },
    {
      question: "Who is known as the 'Iron Man of India'?",
      options: {
        0: "Mahatma Gandhi",
        1: "Jawaharlal Nehru",
        2: "Sardar Vallabhbhai Patel",
        3: "Bhagat Singh",
      },
      correct_answer: "2",
    },
    {
      question: "What is the currency of Japan?",
      options: {
        0: "Yuan",
        1: "Won",
        2: "Yen",
        3: "Ringgit",
      },
      correct_answer: "2",
    },
    {
      question: "Which country is famous for inventing the game of chess?",
      options: {
        0: "India",
        1: "China",
        2: "Persia",
        3: "Egypt",
      },
      correct_answer: "0",
    },
    {
      question:
        "What is the main ingredient in traditional Japanese miso soup?",
      options: {
        0: "Seaweed",
        1: "Tofu",
        2: "Miso paste",
        3: "Rice",
      },
      correct_answer: "2",
    },
    {
      question:
        "If 5 cats can catch 5 mice in 5 minutes, how many mice can 10 cats catch in 10 minutes?",
      options: {
        0: "10",
        1: "20",
        2: "25",
        3: "50",
      },
      correct_answer: "1",
    },
    {
      question:
        "In a certain code, 'PENCIL' is coded as 'RERDJM'. How is 'ERASER' coded?",
      options: {
        0: "GTCTGT",
        1: "FSTBTF",
        2: "FTCTFT",
        3: "FTCTGT",
      },
      correct_answer: "0",
    },
    {
      question: "Which number replaces the question mark? 2, 6, 12, 20, ?",
      options: {
        0: "30",
        1: "32",
        2: "34",
        3: "36",
      },
      correct_answer: "0",
    },
    {
      question: "Find the odd one out: 35, 42, 55, 60, 77",
      options: {
        0: "35",
        1: "42",
        2: "55",
        3: "77",
      },
      correct_answer: "1",
    },
    {
      question:
        "A man walks 10 meters south, then 10 meters east, and finally 10 meters north. How far is he from his starting point?",
      options: {
        0: "10 meters",
        1: "20 meters",
        2: "15 meters",
        3: "30 meters",
      },
      correct_answer: "0",
    },
    {
      question:
        "Which letter will be in the middle if the letters of the word 'OCEAN' are arranged alphabetically?",
      options: {
        0: "A",
        1: "C",
        2: "E",
        3: "O",
      },
      correct_answer: "2",
    },
    {
      question:
        "If 'B' is coded as '1', 'C' as '2', and so on, what is the code for 'DOG'?",
      options: {
        0: "315",
        1: "417",
        2: "420",
        3: "526",
      },
      correct_answer: "0",
    },
    {
      question: "What is the next term in the sequence: 5, 15, 30, 50, ?",
      options: {
        0: "70",
        1: "75",
        2: "85",
        3: "90",
      },
      correct_answer: "1",
    },
    {
      question:
        "In a certain code, 'LATE' is written as 'MBUF'. How is 'COLD' written in that code?",
      options: {
        0: "DPNE",
        1: "DPNE",
        2: "DPME",
        3: "DOLQ",
      },
      correct_answer: "0",
    },
    {
      question: "Find the missing number in the series: 9, 19, 31, 45, ?",
      options: {
        0: "61",
        1: "65",
        2: "71",
        3: "75",
      },
      correct_answer: "0",
    },
    {
      question: "Find the odd one out: 3, 9, 27, 81, 164",
      options: {
        0: "3",
        1: "27",
        2: "81",
        3: "164",
      },
      correct_answer: "3",
    },
    {
      question:
        "In a code language, if 'ROAD' is coded as 'TQBF', how is 'MILE' coded?",
      options: {
        0: "OJNG",
        1: "OIMG",
        2: "OKMG",
        3: "PKNH",
      },
      correct_answer: "1",
    },
    {
      question:
        "If 3, 6, 12, 24, ?, 96, what number should come in place of the question mark?",
      options: {
        0: "30",
        1: "36",
        2: "48",
        3: "72",
      },
      correct_answer: "2",
    },
    {
      question:
        "Choose the number that is different from the others: 2, 3, 5, 9, 11",
      options: {
        0: "2",
        1: "9",
        2: "11",
        3: "3",
      },
      correct_answer: "1",
    },
    {
      question: "How many triangles are there in the following figure?",
      options: {
        0: "6",
        1: "10",
        2: "8",
        3: "12",
      },
      correct_answer: "1",
    },
    {
      question:
        "If '+' means '-', '-' means '*', '*' means '/', and '/' means '+', what is the value of 8 * 4 / 2 + 6 - 3?",
      options: {
        0: "4",
        1: "7",
        2: "10",
        3: "2",
      },
      correct_answer: "1",
    },
    {
      question:
        "In a certain code, 'FLOWER' is written as 'GMPXFS'. How is 'GARDEN' written in that code?",
      options: {
        0: "HBSEFO",
        1: "HBSFFO",
        2: "HCSFEO",
        3: "HCSGFO",
      },
      correct_answer: "0",
    },
    {
      question: "Which number replaces the question mark? 15, 31, 63, 127, ?",
      options: {
        0: "255",
        1: "245",
        2: "230",
        3: "265",
      },
      correct_answer: "0",
    },
    {
      question:
        "If a clock shows 4:30, what is the angle between the hour and the minute hand?",
      options: {
        0: "45 degrees",
        1: "60 degrees",
        2: "75 degrees",
        3: "90 degrees",
      },
      correct_answer: "2",
    },
    {
      question: "Find the next term in the series: A, D, G, J, ?",
      options: {
        0: "M",
        1: "L",
        2: "N",
        3: "P",
      },
      correct_answer: "1",
    },
    {
      question:
        "What is the least number that should be added to 1056 to make it exactly divisible by 23?",
      options: {
        0: "2",
        1: "3",
        2: "1",
        3: "4",
      },
      correct_answer: "2",
    },
    {
      question:
        "A man covers a distance of 2.5 km in 5/6 hour. What is his speed in meters per second?",
      options: {
        0: "0.75",
        1: "1.25",
        2: "0.8",
        3: "1.2",
      },
      correct_answer: "1",
    },
    {
      question:
        "A sum of money amounts to Rs. 1060 in 2 years at 5% simple interest per annum. What was the principal amount?",
      options: {
        0: "Rs. 1000",
        1: "Rs. 950",
        2: "Rs. 1025",
        3: "Rs. 900",
      },
      correct_answer: "0",
    },
    {
      question:
        "If the area of a circle is 154 cm², what is its circumference?",
      options: {
        0: "22 cm",
        1: "44 cm",
        2: "38.5 cm",
        3: "48 cm",
      },
      correct_answer: "1",
    },
    {
      question:
        "A shopkeeper gives a discount of 20% on the marked price and gains 10%. If the marked price is Rs. 100, what is the cost price?",
      options: {
        0: "Rs. 72",
        1: "Rs. 75",
        2: "Rs. 80",
        3: "Rs. 90",
      },
      correct_answer: "1",
    },
    {
      question:
        "A man bought 50 kg of wheat at the rate of Rs. 6 per kg. He sold 40 kg at Rs. 8 per kg and the remaining at Rs. 5 per kg. What is his gain or loss percent?",
      options: {
        0: "5% loss",
        1: "No profit no loss",
        2: "5% gain",
        3: "10% gain",
      },
      correct_answer: "2",
    },
    {
      question:
        "If 2x - 3y = 12 and 3x + 2y = 5, what is the value of x and y?",
      options: {
        0: "x = 4, y = -2",
        1: "x = 3, y = 1",
        2: "x = -1, y = 2",
        3: "x = 2, y = -1",
      },
      correct_answer: "0",
    },
    {
      question:
        "The difference between the compound interest and simple interest on Rs. 800 for 2 years at 5% per annum is:",
      options: {
        0: "Rs. 2",
        1: "Rs. 5",
        2: "Rs. 10",
        3: "Rs. 1",
      },
      correct_answer: "0",
    },
    {
      question:
        "A train running at 60 km/hr crosses a pole in 9 seconds. What is the length of the train?",
      options: {
        0: "120 meters",
        1: "150 meters",
        2: "180 meters",
        3: "100 meters",
      },
      correct_answer: "1",
    },
    {
      question:
        "If the ratio of the ages of two persons is 4:7 and the difference in their ages is 18 years, what are their ages?",
      options: {
        0: "24 and 42",
        1: "28 and 46",
        2: "36 and 54",
        3: "32 and 50",
      },
      correct_answer: "2",
    },
    {
      question: "Which Indian state is the largest by area?",
      options: {
        0: "Uttar Pradesh",
        1: "Madhya Pradesh",
        2: "Maharashtra",
        3: "Rajasthan",
      },
      correct_answer: "3",
    },
    {
      question: "Who was the first woman President of India?",
      options: {
        0: "Pratibha Patil",
        1: "Indira Gandhi",
        2: "Sonia Gandhi",
        3: "Sarojini Naidu",
      },
      correct_answer: "0",
    },
    {
      question: "Which planet is known as the 'Red Planet'?",
      options: {
        0: "Venus",
        1: "Mars",
        2: "Jupiter",
        3: "Mercury",
      },
      correct_answer: "1",
    },
    {
      question:
        "The 'Sundarbans' is a famous mangrove forest located in which country?",
      options: {
        0: "India",
        1: "Bangladesh",
        2: "Sri Lanka",
        3: "Myanmar",
      },
      correct_answer: "1",
    },
    {
      question: "Who is known as the 'Iron Man of India'?",
      options: {
        0: "Mahatma Gandhi",
        1: "Subhas Chandra Bose",
        2: "Sardar Vallabhbhai Patel",
        3: "Jawaharlal Nehru",
      },
      correct_answer: "2",
    },
    {
      question: "Which is the largest gland in the human body?",
      options: {
        0: "Pancreas",
        1: "Thyroid",
        2: "Liver",
        3: "Kidney",
      },
      correct_answer: "2",
    },
    {
      question: "Which Indian classical dance form originated in Kerala?",
      options: {
        0: "Bharatanatyam",
        1: "Kathak",
        2: "Kathakali",
        3: "Odissi",
      },
      correct_answer: "2",
    },
    {
      question: "The famous 'Charminar' is located in which Indian city?",
      options: {
        0: "Hyderabad",
        1: "Mumbai",
        2: "Delhi",
        3: "Bangalore",
      },
      correct_answer: "0",
    },
    {
      question: "Which element has the highest melting point?",
      options: {
        0: "Iron",
        1: "Tungsten",
        2: "Gold",
        3: "Platinum",
      },
      correct_answer: "1",
    },
    {
      question: "Who is known as the 'Nightangle of India'?",
      options: {
        0: "Lata Mangeshkar",
        1: "Sarojini Naidu",
        2: "Indira Gandhi",
        3: "Savitribai Phule",
      },
      correct_answer: "1",
    },
  ],
  rrbntpc01: [
    {
      question:
        "Rearrange the following jumbled sentences to make a meaningful one: P: weather conditions across a vast geographic Q: the climate of India R: scale and varied topography S: comprises of a wide range of The proper sequence should be:",
      options: ["SRQP", "QSPR", "PQRS", "QRPS"],
      correct_answer: 1,
    },
    {
      question:
        "Choose the pair which is related in the same way as the words in the first pair from the given choices. SOLDIERS : ARMY ∷ MUSICIANS: ______________",
      options: ["FLOCK", "GANG", "COLONY", "BAND"],
      correct_answer: 3,
    },
    {
      question:
        "The process when the computer is switched on and the operating system gets loaded from hard disk to main memory is called–",
      options: ["Booting", "Fetching", "Processing", "Multi-Processing"],
      correct_answer: 0,
    },
    {
      question: "Which Indian won the 2014 Nobel Prize for Peace?",
      options: [
        "Kailash Satyarthi",
        "Malala Yosufzai",
        "Sanjiv Chaturvedi",
        "Anshu Gupta",
      ],
      correct_answer: 0,
    },
    {
      question:
        "If three numbers are in the ratio 2:3:5 and the twice their sum is 100. Find the square of the largest of the three numbers.",
      options: ["225", "625", "25", "100"],
      correct_answer: 1,
    },
    {
      question: "Who was the first President of the Indian National Congress?",
      options: [
        "Womesh Chandra Bannerjee",
        "Bal Gangadhar Tilak",
        "Allan Octavian Hume",
        "Dadabhai Naoroji",
      ],
      correct_answer: 0,
    },
    {
      question:
        "What is the compound interest on Rs. 48,000 for 2 years at 20% p.a., if interest is compounded annually?",
      options: ["Rs. 69,120", "Rs. 21,120", "Rs. 76,800", "Rs. 72,000"],
      correct_answer: 1,
    },
    {
      question:
        "A ladder 20 m long is leaning against a vertical wall. It makes an angle of 30° with the ground. How high on the wall does the ladder reach?",
      options: ["10 m", "17.32 m", "34.64 m", "30 m"],
      correct_answer: 0,
    },
    {
      question:
        "Ram said, “Sita is my paternal great grandfather’s only son’s only daughter-in-law”. How is Sita related to Ram?",
      options: ["Maternal Aunt", "Paternal Aunt", "Mother", "Sister"],
      correct_answer: 2,
    },
    {
      question:
        "A piece of cloth costs Rs. 35. If the piece were 4 m longer and each meter were to cost Rs. 1 lesser, then the total cost would remain unchanged. How long is the piece of cloth?",
      options: ["10 m", "14 m", "12 m", "8 m"],
      correct_answer: 0,
    },
    {
      question:
        "In 2013, the first woman to be elected as the President of South Korea is–",
      options: [
        "Park Young-sun",
        "Yuk Young-soo",
        "Park Geun-hye",
        "Sim Sang-jung",
      ],
      correct_answer: 2,
    },
    {
      question: "The Mughal empire was founded by–",
      options: ["Babur", "Humayun", "Akbar", "Shah Jahan"],
      correct_answer: 0,
    },
    {
      question:
        "Two poles of the height 15 m and 20 m stand vertically upright on a plane ground. If the distance between their feet is 12 m, find the distance between their tops.",
      options: ["11 m", "12 m", "13 m", "14 m"],
      correct_answer: 2,
    },
    {
      question:
        "How is Sammer related to Akbar if Sameer introduced Akbar as his maternal grandmother’s only son’s son?",
      options: ["Brother", "Son", "Maternal Uncle", "Cousin"],
      correct_answer: 3,
    },
    {
      question:
        "Given below is a statement followed by some conclusions. You have to take the given statement to be true even if it seems to be at variance with the commonly known facts and then decide which of the given conclusions logically follow(s) from the given statement. Statement: According to a recent health survey, people who exercise for at least half an hour every day are less prone to lifestyle diseases. Conclusion: I. Moderate exercise is essential to lead a healthy life. II. Everyone with no exercise on their routine suffer from lifestyle diseases.",
      options: [
        "Only conclusion I follows",
        "Only conclusion II follows",
        "Both I and II follow",
        "Neither of them follows",
      ],
      correct_answer: 0,
    },
    {
      question: "Choose the one which is different or odd from the following.",
      options: ["Knight", "Rook", "Bishop", "Pawn"],
      correct_answer: 0,
    },
    {
      question: "The oldest oil field in Asia is located in–",
      options: ["Gujarat", "Assam", "Arunanchal Pradesh", "Nagaland"],
      correct_answer: 1,
    },
    {
      question:
        "The simple interest on a certain sum of money invested at a certain rate for 2 years amounts to Rs. 1200. The compound interest on the same sum of money invested at the same rate of interest for 2 years amounts to Rs. 1290. What was the principal?",
      options: ["Rs. 12000", "Rs. 16000", "Rs. 6000", "Rs. 4000"],
      correct_answer: 3,
    },
    {
      question:
        "Which of the following is false? Sound waves are _________ waves.",
      options: ["Pressure", "Longitudinal", "Electromagnetic", "Mechanical"],
      correct_answer: 2,
    },
    {
      question:
        "Given below is a statement followed by some conclusions. You have to take the given statement to be true even if it seems to be at variance with the commonly known facts and then decide which of the given conclusions logically follow(s) from the given statement. Statement: In some tier 2 cities in India, transportation is one of the major problems. Conclusions: I. All tier 2 cities are not well connected. II. Lack of transportation facilities is the only problem in Indian metros.",
      options: [
        "Only conclusion I follows",
        "Only conclusion II follows",
        "Both I and II follow",
        "Neither of them follows",
      ],
      correct_answer: 3,
    },
    {
      question:
        "Below are given statements. You have to take the given statements to be true even if they seem to be at variance with the commonly known facts and then decide which of the given conclusions logically follows from the given statements. Statements: I. All rats are hills. II. All hills are rivers.",
      options: [
        "Some rivers are rats",
        "No river is a hill",
        "All hills are rats",
        "No river is a rat",
      ],
      correct_answer: 0,
    },
    {
      question: "How many bones does a new born human baby have?",
      options: ["305", "206", "211", "411"],
      correct_answer: 0,
    },
    {
      question:
        "Which country built the Bird’s Nest Stadium for 2008 Summer Olympics?",
      options: ["China", "Brazil", "Australia", "Germany"],
      correct_answer: 0,
    },
    {
      question:
        "Which of the following coloured light has the lowest frequency?",
      options: ["Green", "Blue", "Red", "Violet"],
      correct_answer: 2,
    },
    {
      question:
        "In 2015, which auto manufacturer manipulated emissions testing data leading to the resignation of its CEO Martin Winterkom?",
      options: ["Volkswagen", "Ford", "Toyota", "General Motors"],
      correct_answer: 0,
    },
    {
      question:
        "A closed wooden rectangular box made of 1 cm thick wood has the following outer dimensions: length 22 cm, breadth 17 cm, and height 12 cm. It is filled to capacity with cement. What is the volume of the cement in the box?",
      options: ["1488 cu. cm", "3000 cu. cm", "4488 cu. cm", "2880 cu. cm"],
      correct_answer: 1,
    },
    {
      question:
        "If the code for MOTHER is JRQKBU then what is the code for PRINCIPAL?",
      options: ["MRFKZLMXI", "SULQFLSDO", "MUFQZLMDI", "MRFKZFMXI"],
      correct_answer: 2,
    },
    {
      question: "The only non-metal which is liquid at room temperature is–",
      options: ["Mercury", "Bromine", "Chlorine", "Gallium"],
      correct_answer: 1,
    },
    {
      question: "Maanch is a folk dance from–",
      options: ["Haryana", "Kerala", "Assam", "Madhya Pradesh"],
      correct_answer: 3,
    },
    {
      question:
        "Which of the following insecticides’ harmful effects came under media attention when health issues in Kerala were publicized?",
      options: ["Endosulfan", "Lethal", "Thimet", "Monocil"],
      correct_answer: 0,
    },
    {
      question:
        "The HCF and LCM of two numbers are 3 and 2730 respectively. If one of the numbers is 78, find the other number.",
      options: ["107", "103", "105", "102"],
      correct_answer: 2,
    },
    {
      question: "If A = 1 and OAR = 34, then ROAR =",
      options: ["52", "53", "51", "50"],
      correct_answer: 0,
    },
    {
      question: "Choose the one which is different or odd from the following.",
      options: ["Aluminum", "Iron", "Copper", "Brass"],
      correct_answer: 3,
    },
    {
      question:
        "According to the Constitution, the ratio between the length and breadth of the national tricolor should be–",
      options: ["3:2", "3:1", "2:1", "4:3"],
      correct_answer: 0,
    },
    {
      question:
        "If P travelled the first half of a journey at 40 km/hr and the remaining distance at 50 km/hr, what is the average speed of his travel?",
      options: ["44.44 km/hr", "53.33 km/hr", "45 km/hr", "60 km/hr"],
      correct_answer: 0,
    },
    {
      question: "Find the LCM of 15, 25 and 29.",
      options: ["2335", "3337", "2175", "2375"],
      correct_answer: 2,
    },
    {
      question:
        "In 1981, ISRO launched India’s first geostationary satellite called–",
      options: ["Aryabhata", "APPLE", "Bhaskara II", "INSAT 1B"],
      correct_answer: 1,
    },
    {
      question:
        "Which of the following was the first antibiotic discovered by Alexander Fleming in 1928?",
      options: ["Penicillin", "Prontosil", "Streptomycin", "Teracyline"],
      correct_answer: 0,
    },
    {
      question:
        "If ‘+’ means ×, ‘–’ means ÷ , ‘×’ means + and ‘÷ ’ means –; compute the value of the expression: 17 + 6 × 13 ÷ 8",
      options: ["100", "107", "110", "109"],
      correct_answer: 1,
    },
    {
      question: "Which among the following is true for the given numbers?",
      options: [
        "3/8 < 19/73 < 29/47 < 17/39",
        "19/73 < 3/8 < 17/39 < 29/47",
        "19/73 < 3/8 < 29/47 < 17/39",
        "19/73 < 29/47 < 3/8 < 17/39",
      ],
      correct_answer: 1,
    },
    {
      question:
        "Divide 3740 in three parts in such a way that half of the first part, one-third of the second part and one-sixth of the third part are equal.",
      options: [
        "700, 1000, 2040",
        "340, 1360, 2040",
        "680, 1020, 2040",
        "500, 1200, 2040",
      ],
      correct_answer: 2,
    },
    {
      question: "Where is White Desert located?",
      options: ["Gujarat", "Tamil Nadu", "Jammu and Kashmir", "Sikkim"],
      correct_answer: 0,
    },
    {
      question:
        "Which of the following Acts under the Indian Constitution is described by Article 21 A?",
      options: [
        "Right to Education",
        "Right to Information",
        "Representation of the People",
        "Right to Freedom of Religion",
      ],
      correct_answer: 0,
    },
    {
      question:
        "Which of the following monuments built by Muhammad Quli Qutb Shah is said to be built to commemorate the eradication of plague?",
      options: ["Alai Minar", "Charminar", "Fateh Burj", "Qutub Minar"],
      correct_answer: 1,
    },
    {
      question: "Which of the following is not an example of a Word Processor?",
      options: [
        "IBM Lotus Symphony",
        "Microsoft Word",
        "Google Docs",
        "Microsoft Excel",
      ],
      correct_answer: 3,
    },
    {
      question:
        "There are 90 coins, comprising of 5 and 10 paisa coins. The value of all the coins is Rs. 7. How many 5 paisa coins are there?",
      options: ["50", "45", "40", "35"],
      correct_answer: 2,
    },
    {
      question: "How high is the badminton net at the center?",
      options: ["5 feet", "5.1 feet", "5.5 feet", "4.8 feet"],
      correct_answer: 0,
    },
    {
      question:
        "Given below is a statement followed by some conclusions. You have to take the given statement to be true even if it seems to be at variance with the commonly known facts and then decide which of the given conclusions logically follow(s) from the given statements. Statement: Success cannot be achieved without hard work. Conclusions: I. Every hardworking person is successful. II. Every successful person is hardworking.",
      options: [
        "Only conclusion I follows",
        "Only conclusion II follows",
        "Both I and II follow",
        "Neither of them follows",
      ],
      correct_answer: 1,
    },
    {
      question:
        "Find the mode of the given numbers: 52, 54, 55, 56, 55, 54, 53, 55, 53, 51, and 57.",
      options: ["53", "54", "55", "52"],
      correct_answer: 2,
    },
    {
      question:
        "Use the following passage for the questions based on it. Here are six flats on a floor in two rows. Out of these, three are north facing and the other three are south facing flats. The flats are to be allotted amongst Arun, Biswajyot, Chitra, Derek, Evan, and Fatima. Biswajyot gets a north facing flat and is not next to Derek. Derek and Fatima get diagonally opposite flats. Chitra is next to Fatima and gets a south facing flat. Evan gets a north facing flat. Except Derek and Fatima, which other pair is diagonally opposite to each other?",
      options: [
        "Arun and Biswajyot",
        "Arun and Chitra",
        "Evan and Derek",
        "Evan and Chitra",
      ],
      correct_answer: 0,
    },
    {
      question:
        "Which of the following pairs is exactly opposite to each other?",
      options: [
        "Derek and Evan",
        "Fatima and Chitra",
        "Evan and Chitra",
        "Evan and Arun",
      ],
      correct_answer: 2,
    },
    {
      question: "Which of the following combinations get south facing flats?",
      options: [
        "Arun, Chitra, and Fatima",
        "Chitra, Biswajyot, and Derek",
        "Evan, Arun, and Fatima",
        "Derek, Arun, and Biswajyot",
      ],
      correct_answer: 0,
    },
    {
      question:
        "30 men working 5 hours a day can do a task in 16 days. In how many days will 40 men working 6 hours a day do the same task?",
      options: ["12 days", "10 days", "15 days", "18 days"],
      correct_answer: 1,
    },
    {
      question:
        "A triangle has a perimeter of 200. If two of its sides are equal and the third side is 20 more than the equal sides, what is the length of the third side?",
      options: ["60", "50", "80", "70"],
      correct_answer: 2,
    },
    {
      question: "The operating system UNIX is the trademark of–",
      options: ["Microsoft", "Bell Laboratories", "Apple", "Motorola"],
      correct_answer: 1,
    },
    {
      question:
        "The first large scale electrical air conditioning was invented and used in 1902 by–",
      options: [
        "Willis Carrier",
        "John Gorrie",
        "Stuart Cramer",
        "H. H. Schultz",
      ],
      correct_answer: 0,
    },
    {
      question:
        "The area of a triangle ABC is 63 sq. units. Two parallel lines DE, FG, are drawn such that they divide the line segments AB and AC into three equal parts. What is the area of the quadrilateral DEGF?",
      options: ["28 sq. units", "35 sq. units", "21 sq. units", "48 sq. units"],
      correct_answer: 2,
    },
    {
      question:
        "Which of the following is the most common kidney stone-forming compound?",
      options: [
        "Calcium oxalate",
        "Magnesium oxide",
        "Sodium bicarbonate",
        "Magnesium citrate",
      ],
      correct_answer: 0,
    },
    {
      question: "Compute: (50 + 0.5×20)÷ 0.7",
      options: ["8.571", "857.1", "85.71", "72.85"],
      correct_answer: 2,
    },
    {
      question:
        "Working together, P, Q, and R reap a field in 6 days. If P can do it alone in 10 days and Q in 24 days, in how many days will R alone be able to reap the field?",
      options: ["32 days", "40 days", "45 days", "60 days"],
      correct_answer: 1,
    },
    {
      question:
        "In 2015, underground glaciers of frozen water were discovered on–",
      options: ["Mars", "Venus", "Jupiter", "Saturn"],
      correct_answer: 0,
    },
    {
      question:
        "A shopkeeper marks the price of an article at Rs. 320. Find the cost price if after allowing a discount of 10%, he still gains 20% on the cost price.",
      options: ["Rs. 240", "Rs. 280", "Rs. 300", "Rs. 264"],
      correct_answer: 0,
    },
    {
      question:
        "Which leader adopted Orthodox Christianity as the official religion of Russia?",
      options: [
        "Vladimir the Great",
        "Michael Romanov",
        "Ivan IV",
        "Boris Godunov",
      ],
      correct_answer: 0,
    },
    {
      question: "If RUN = 182114 and BIN = 2914, then BRING =",
      options: ["2189147", "1178136", "31910158", "21910158"],
      correct_answer: 0,
    },
    {
      question:
        "On an average, how many taste buds are present in a human tongue?",
      options: [
        "2000 to 8000",
        "50000 to 100000",
        "1 million to 10 million",
        "More than 10 million",
      ],
      correct_answer: 0,
    },
    {
      question: "5.16 × 3.2 =",
      options: ["15.502", "16.512", "17.772", "17.52"],
      correct_answer: 1,
    },
    {
      question:
        "Choose the pair which is related in the same way as the words in the first pair from the given choices. DOG : KENNEL ∷ BEE:___________",
      options: ["HIVE", "BARN", "HOLE", "NEST"],
      correct_answer: 0,
    },
    {
      question:
        "Below are given statements followed by some conclusions. You have to take the given statements to be true even if they seem to be at variance with the commonly known facts and then decide which of the given conclusions logically follow(s) from the given statements. Statements: A. Some fruits are vegetables. B. All vegetables are plants. Conclusions: I. Some plants are vegetables. II. Some fruits are plants.",
      options: [
        "Only conclusion I follows",
        "Only conclusion II follows",
        "Both I and II follow",
        "Neither of them follows",
      ],
      correct_answer: 2,
    },
    {
      question:
        "A table was sold at a profit of 10%. If its cost price was 5% less and it was sold for Rs. 7 more, the gain would have been 20%. Find the cost price of the table.",
      options: ["Rs. 175", "Rs. 200", "Rs. 250", "Rs. 150"],
      correct_answer: 0,
    },
    {
      question:
        "Which of the following can reproduce only through a sexual method?",
      options: ["Coral", "Hydra", "Sponges", "Annelida"],
      correct_answer: 1,
    },
    {
      question: "Who had discovered Heliocentric theory?",
      options: [
        "Nicolaus Copernicus",
        "Galileo Galilei",
        "Johannes Kepler",
        "Friedrich Bessel",
      ],
      correct_answer: 0,
    },
    {
      question: "The ‘Father of Indian Space Program’ is–",
      options: [
        "Dr. A. P. J. Abdul Kalam",
        "Dr. Vikram A. Sarabhai",
        "Dr. K. Kasturirangan",
        "Prof. Satish Dhawan",
      ],
      correct_answer: 1,
    },
    {
      question: "The filament of a light bulb is made up of–",
      options: ["Platinum", "Tantalum", "Tungsten", "Antimony"],
      correct_answer: 2,
    },
    {
      question:
        "Who among the following became the first tourist to space by spending $20 million for 8 days in orbit?",
      options: [
        "Greg Olsen",
        "Charles Simonyi",
        "Dennis Tito",
        "Mark Shuttleworth",
      ],
      correct_answer: 2,
    },
    {
      question:
        "P bought an article for Rs. 1600 and sold it at a profit of 10%. What would have been the increase in the profit percent if it was sold for Rs. 1840?",
      options: ["5%", "10%", "12%", "15%"],
      correct_answer: 0,
    },
    {
      question:
        "Which of the following Ancient humanoid belongs to the Upper Paleolithic in Europe of modern human?",
      options: ["Ergaster line", "Cro-magnon", "Neanderthal", "Proconsul"],
      correct_answer: 1,
    },
    {
      question: "What is C₁₂H₂₂O₁₁ also known as–",
      options: ["Sand", "Sugar", "Salt", "Clay"],
      correct_answer: 1,
    },
    {
      question: "Select the correct set of symbols: 27 3 19 10 = 90",
      options: ["×, –, ÷", "+ , ÷, –", "+, –, ÷", "×, + , –"],
      correct_answer: 3,
    },
    {
      question: "Simplify: (2/7 + 3/5)÷ (2/5+2/7)",
      options: ["31/24", "24/31", "26/25", "12/13"],
      correct_answer: 0,
    },
    {
      question: "Select the correct set of symbols: 44 4 7 5 = 82",
      options: ["×, –, ÷", "+, ÷ , –", "+, –, ÷", "÷ , ×, +"],
      correct_answer: 3,
    },
    {
      question:
        "If R019 is divisible by 11, find the value of the smallest natural number R?",
      options: ["5", "6", "7", "8"],
      correct_answer: 3,
    },
    {
      question:
        "Malini said, “Rohit is my maternal aunt’s mother‘s only son’s son”. How is Malini related to Rohit?",
      options: ["Cousin", "Mother", "Sister", "Daughter"],
      correct_answer: 0,
    },
    {
      question:
        "In Tennis, hard court is the type of court whose surface is made of–",
      options: ["Concrete", "Clay", "Grass", "Carpet"],
      correct_answer: 0,
    },
    {
      question:
        "If ‘+’ means ×, ‘–’ means ÷ , ‘×’ means + and ‘÷ ’ means – ; compute the value of the expression: 36 – 4 + 7 × 8",
      options: ["72", "71", "74", "75"],
      correct_answer: 1,
    },
    {
      question: "The average of first 20 multiples of 12 is:",
      options: ["124", "120", "126", "130"],
      correct_answer: 2,
    },
    {
      question:
        "What is the median of the following list of numbers: 55, 53, 56, 59, 61, 69, and 31?",
      options: ["55", "56", "59", "61"],
      correct_answer: 1,
    },
    {
      question:
        "The sum of digits of a two-digit number is 9. When the digits are reversed, the number decreases by 45. Find the changed number.",
      options: ["45", "72", "63", "27"],
      correct_answer: 3,
    },
    {
      question: "If MENTOR=NVMGLI then PROFESSOR =",
      options: ["QSPGFTTPS", "KILUVHHLI", "KSLGVTHMI", "KILGFHHLI"],
      correct_answer: 1,
    },
    {
      question: "The national song of India was composed by–",
      options: [
        "Rabindranath Tagore",
        "Bankim Chandra Chatterjee",
        "Pydimarri Venkata Subba Rao",
        "Pingali Venkayya",
      ],
      correct_answer: 1,
    },
    {
      question: "Which of the following is NOT an effect of Noise Pollution?",
      options: [
        "Death of Animals",
        "Tinnitus",
        "Hypertension",
        "Ozone Depletion",
      ],
      correct_answer: 3,
    },
    {
      question:
        "The upstream speed of the boat is 40 km/hr and the speed of the boat in still water is 55 km/hr. What is the downstream speed of the boat?",
      options: ["75 km/hr", "70 km/hr", "60 km/hr", "65 km/hr"],
      correct_answer: 1,
    },
    {
      question:
        "Choose the pair which is related in the same way as the words in the first pair from the given choices. TENNIS: COURT ∷ BOXING: __________",
      options: ["STADIUM", "RING", "PITCH", "GROUND"],
      correct_answer: 1,
    },
    {
      question:
        "Six years ago, the ratio of the ages of the two persons P and Q was 3:2. Four years hence the ratio of their ages will be 8:7. What is P’s age?",
      options: ["10 years", "12 years", "14 years", "8 years"],
      correct_answer: 1,
    },
    {
      question:
        "Which of the following popular tourist destinations of India were built in 1911 to commemorate the visit of King George V and Queen Mary?",
      options: [
        "India Gate",
        "Gateway of India",
        "The Prince of Wales Museum",
        "Victoria Terminus",
      ],
      correct_answer: 1,
    },
  ],
  rrbntpc02: [
    {
      question:
        "On an average, how many taste buds are present in a human tongue?",
      options: [
        "2000 to 8000",
        "50000 to 100000",
        "1 million to 10 million",
        "More than 10 million",
      ],
      correct_answer: 0,
    },
    {
      question: "5.16 × 3.2 =",
      options: ["15.502", "16.512", "17.772", "17.52"],
      correct_answer: 1,
    },
    {
      question:
        "Choose the pair which is related in the same way as the words in the first pair from the given choices. DOG : KENNEL ∷ BEE:___________",
      options: ["HIVE", "BARN", "HOLE", "NEST"],
      correct_answer: 0,
    },
    {
      question:
        "Below are given statements followed by some conclusions. You have to take the given statements to be true even if they seem to be at variance with the commonly known facts and then decide which of the given conclusions logically follow(s) from the given statements. Statements: A. Some fruits are vegetables. B. All vegetables are plants. Conclusions: I. Some plants are vegetables. II. Some fruits are plants.",
      options: [
        "Only conclusion I follows",
        "Only conclusion II follows",
        "Both I and II follow",
        "Neither of them follows",
      ],
      correct_answer: 2,
    },
    {
      question:
        "A table was sold at a profit of 10%. If its cost price was 5% less and it was sold for Rs. 7 more, the gain would have been 20%. Find the cost price of the table.",
      options: ["Rs. 175", "Rs. 200", "Rs. 250", "Rs. 150"],
      correct_answer: 0,
    },
    {
      question:
        "Which of the following can reproduce only through a sexual method?",
      options: ["Coral", "Hydra", "Sponges", "Annelida"],
      correct_answer: 1,
    },
    {
      question: "Who had discovered Heliocentric theory?",
      options: [
        "Nicolaus Copernicus",
        "Galileo Galilei",
        "Johannes Kepler",
        "Friedrich Bessel",
      ],
      correct_answer: 0,
    },
    {
      question: "The ‘Father of Indian Space Program’ is–",
      options: [
        "Dr. A. P. J. Abdul Kalam",
        "Dr. Vikram A. Sarabhai",
        "Dr. K. Kasturirangan",
        "Prof. Satish Dhawan",
      ],
      correct_answer: 1,
    },
    {
      question: "The filament of a light bulb is made up of–",
      options: ["Platinum", "Tantalum", "Tungsten", "Antimony"],
      correct_answer: 2,
    },
    {
      question:
        "Who among the following became the first tourist to space by spending $20 million for 8 days in orbit?",
      options: [
        "Greg Olsen",
        "Charles Simonyi",
        "Dennis Tito",
        "Mark Shuttleworth",
      ],
      correct_answer: 2,
    },
    {
      question:
        "P bought an article for Rs. 1600 and sold it at a profit of 10%. What would have been the increase in the profit percent if it was sold for Rs. 1840?",
      options: ["5%", "10%", "12%", "15%"],
      correct_answer: 0,
    },
    {
      question:
        "Which of the following Ancient humanoid belongs to the Upper Paleolithic in Europe of modern human?",
      options: ["Ergaster line", "Cro-magnon", "Neanderthal", "Proconsul"],
      correct_answer: 1,
    },
    {
      question: "What is C₁₂H₂₂O₁₁ also known as–",
      options: ["Sand", "Sugar", "Salt", "Clay"],
      correct_answer: 1,
    },
    {
      question: "Select the correct set of symbols: 27 3 19 10 = 90",
      options: ["×, –, ÷", "+ , ÷, –", "+, –, ÷", "×, + , –"],
      correct_answer: 3,
    },
    {
      question: "Simplify: (2/7 + 3/5)÷ (2/5+2/7)",
      options: ["31/24", "24/31", "26/25", "12/13"],
      correct_answer: 0,
    },
    {
      question: "Select the correct set of symbols: 44 4 7 5 = 82",
      options: ["×, –, ÷", "+, ÷ , –", "+, –, ÷", "÷ , ×, +"],
      correct_answer: 3,
    },
    {
      question:
        "If R019 is divisible by 11, find the value of the smallest natural number R?",
      options: ["5", "6", "7", "8"],
      correct_answer: 3,
    },
    {
      question:
        "Malini said, “Rohit is my maternal aunt’s mother‘s only son’s son”. How is Malini related to Rohit?",
      options: ["Cousin", "Mother", "Sister", "Daughter"],
      correct_answer: 0,
    },
    {
      question:
        "In Tennis, hard court is the type of court whose surface is made of–",
      options: ["Concrete", "Clay", "Grass", "Carpet"],
      correct_answer: 0,
    },
    {
      question:
        "If ‘+’ means ×, ‘–’ means ÷ , ‘×’ means + and ‘÷ ’ means – ; compute the value of the expression: 36 – 4 + 7 × 8",
      options: ["72", "71", "74", "75"],
      correct_answer: 1,
    },
    {
      question: "The average of first 20 multiples of 12 is:",
      options: ["124", "120", "126", "130"],
      correct_answer: 2,
    },
    {
      question:
        "What is the median of the following list of numbers: 55, 53, 56, 59, 61, 69, and 31?",
      options: ["55", "56", "59", "61"],
      correct_answer: 1,
    },
    {
      question:
        "The sum of digits of a two-digit number is 9. When the digits are reversed, the number decreases by 45. Find the changed number.",
      options: ["45", "72", "63", "27"],
      correct_answer: 3,
    },
    {
      question: "If MENTOR=NVMGLI then PROFESSOR =",
      options: ["QSPGFTTPS", "KILUVHHLI", "KSLGVTHMI", "KILGFHHLI"],
      correct_answer: 1,
    },
    {
      question: "The national song of India was composed by–",
      options: [
        "Rabindranath Tagore",
        "Bankim Chandra Chatterjee",
        "Pydimarri Venkata Subba Rao",
        "Pingali Venkayya",
      ],
      correct_answer: 1,
    },
    {
      question: "Which of the following is NOT an effect of Noise Pollution?",
      options: [
        "Death of Animals",
        "Tinnitus",
        "Hypertension",
        "Ozone Depletion",
      ],
      correct_answer: 3,
    },
    {
      question:
        "The upstream speed of the boat is 40 km/hr and the speed of the boat in still water is 55 km/hr. What is the downstream speed of the boat?",
      options: ["75 km/hr", "70 km/hr", "60 km/hr", "65 km/hr"],
      correct_answer: 1,
    },
    {
      question:
        "Choose the pair which is related in the same way as the words in the first pair from the given choices. TENNIS: COURT ∷ BOXING: __________",
      options: ["STADIUM", "RING", "PITCH", "GROUND"],
      correct_answer: 1,
    },
    {
      question:
        "Six years ago, the ratio of the ages of the two persons P and Q was 3:2. Four years hence the ratio of their ages will be 8:7. What is P’s age?",
      options: ["10 years", "12 years", "14 years", "8 years"],
      correct_answer: 1,
    },
    {
      question:
        "Which of the following popular tourist destinations of India were built in 1911 to commemorate the visit of King George V and Queen Mary?",
      options: [
        "India Gate",
        "Gateway of India",
        "The Prince of Wales Museum",
        "Victoria Terminus",
      ],
      correct_answer: 1,
    },
  ],
  ch02: [
    {
      question:
        "The cost price of 36 books is equal to the selling price of 30 books. The gain percent is:",
      options: ["20%", "18%", "82%", "16%"],
      correct_answer: 0,
    },
    {
      question:
        "The cost price of same as the same price of 10 articles. The profit percent is:",
      options: ["30%", "40%", "50%", "45%"],
      correct_answer: 2,
    },
    {
      question:
        "The selling price of 5 articles is the same as cost price of 3 articles. The gain or loss percent is:",
      options: ["20% gain", "25% gain", "33.33% loss", "40% loss"],
      correct_answer: 3,
    },
    {
      question:
        "If the cost price of 15 tables be equal to the selling price of 20 tables. The loss percent is:",
      options: ["20%", "30%", "25%", "37.5%"],
      correct_answer: 2,
    },
    {
      question:
        "The ratio of the C.P and S.P of an article is 20:21. What is the gain percent?",
      options: ["5%", "5.5%", "6%", "6.25%"],
      correct_answer: 0,
    },
    {
      question:
        "The cost price of 25 articles is equal to selling price of 20 articles. the gain percent is.",
      options: ["20%", "22%", "24%", "25%"],
      correct_answer: 3,
    },
    {
      question:
        "If the cost price of 50 oranges is equal to the selling price of 40 oranges, then the percent is.",
      options: ["5", "10", "20", "25"],
      correct_answer: 3,
    },
    {
      question:
        "The ratio of the cost price and selling price of an article is 5:6. What is the percentage of profit?",
      options: ["20%", "15%", "12.5%", "10%"],
      correct_answer: 0,
    },
    {
      question:
        "In selling an article for Rs. 76, there is a profit of 52%. If it is sold for Rs. 75, the profit percent will be.",
      options: ["44", "46", "48", "50"],
      correct_answer: 3,
    },
    {
      question:
        "The cost price of 18 articles is equal to the selling price of 15 articles. The gain percent is:",
      options: ["15%", "20%", "25%", "18%"],
      correct_answer: 1,
    },
    {
      question:
        "The ratio of cost price and selling price is 5:4 the loss percent is:",
      options: ["20%", "25%", "40%", "50%"],
      correct_answer: 0,
    },
    {
      question:
        "If the cost price of 15 books is equal to the selling price of 20 books. The loss percent is.",
      options: ["16", "20", "24", "25"],
      correct_answer: 3,
    },
    {
      question:
        "If the cost price of 10 articles is equal to the selling price of 16 articles, then the loss percent is:",
      options: ["30", "37.5", "42.5", "45"],
      correct_answer: 1,
    },
    {
      question:
        "If the cost price of 15 articles is equal to the selling price of 12 articles, find gain%",
      options: ["20", "25", "18", "21"],
      correct_answer: 1,
    },
    {
      question:
        "A man sells 320 mangoes at the cost price of 400 mangoes. His gain percent is:",
      options: ["15", "20", "25", "10"],
      correct_answer: 2,
    },
    {
      question:
        "If toys are bought at Rs. 5 each and sold at Rs. 4.50 each, then the loss is:",
      options: ["10%", "115%", "12%", "13%"],
      correct_answer: 0,
    },
    {
      question:
        "If the ratio of cost price to selling price is 10:11, then the rate of percent of profit is.",
      options: ["1.1%", "10%", "0.1%", "1%"],
      correct_answer: 1,
    },
    {
      question:
        "If an article is sold for Rs. 178 at a loss of 11%, what should be its selling price in order to earn a profit of 11%",
      options: ["Rs. 222.50", "Rs. 267", "Rs. 222", "Rs. 220"],
      correct_answer: 2,
    },
    {
      question:
        "If an article is sold at a gain of 5% instead of being sold at a loss of 5%, one gets Rs. 5 more. What is the cost price of the article?",
      options: ["Rs. 100", "Rs. 105", "Rs. 50", "Rs. 110"],
      correct_answer: 2,
    },
    {
      question:
        "A man sells an article at 10% loss. If he had sold it at Rs. 10 more, he would have gained 10%. The cost price of the article is.",
      options: ["Rs. 50", "Rs. 55", "Rs. 100", "Rs. 110"],
      correct_answer: 0,
    },
    {
      question:
        "If a man were to sell his chair for Rs. 720, he would lose 25%. To gain 25% he should sell it for.",
      options: ["Rs. 1,200", "Rs. 1,000", "Rs. 960", "Rs. 900"],
      correct_answer: 0,
    },
    {
      question:
        "A man sells his typewriter at 5% loss. If he sells it for Rs. 80 more, he will gain 5%. The cost price of the typewriter is.",
      options: ["Rs. 1,600", "Rs. 1,200", "1,000", "Rs. 800"],
      correct_answer: 3,
    },
    {
      question:
        "By selling an article for Rs. 665, there is a loss of 5%. In order to make a profit of the article must be.",
      options: ["Option 1", "Option 2", "Option 3", "Option 4"],
      correct_answer: 3,
    },
    {
      question:
        "A man sold an article at a loss of 20%. If he had sold it for Rs. 50 more, he would have gained 5%. The cost price of the article was",
      options: ["Rs. 250", "Rs. 300", "Rs. 180", "Rs. 200"],
      correct_answer: 3,
    },
    {
      question:
        "By selling a basket for Rs. 19.50, a shopkeeper gains 30%. For how shopkeeper gains 30%. For how much should he sell it to gain 40%?",
      options: ["Rs. 21", "Rs. 21.50", "Rs. 24", "Rs. 23"],
      correct_answer: 0,
    },
    {
      question:
        "By selling an article for Rs. 700 a man lost 30%. At what price should he have sold it to gain 30%?",
      options: ["Rs. 910", "Rs. 1200", "Rs. 1232", "Rs. 1300"],
      correct_answer: 3,
    },
    {
      question:
        "If a man purchased a bedsheet for Rs. 720, he would lose 25%. To gain 25%, the selling price is",
      options: ["Rs. 960", "Rs. 1,200", "Rs. 1,000", "Rs. 1,200"],
      correct_answer: 1,
    },
    {
      question:
        "If an article is sold at 200% profit then the ratio of its cost price of its selling price will be.",
      options: ["1:2", "2:1", "1:3", "3:1"],
      correct_answer: 2,
    },
    {
      question:
        "An article is sold at 5% profit. The ratio of selling price and cost will be.",
      options: ["31:5", "20:21", "21:20", "5:1"],
      correct_answer: 2,
    },
    {
      question:
        "Aniruddha sold a bicycle at a gain of 8%. Had it been sold for Rs. 75 more, the gain would have been 14%. The cost price of the bicycle was.",
      options: ["Rs. 1200", "Rs. 1250", "Rs. 1350", "Rs. 1500"],
      correct_answer: 1,
    },
  ],
  sover: [
    {
      question:
        "In a certain code SOBER is written as RNADQ. How LOTUS can be written in that code?",
      options: ["KNSTR", "MPUWT", "KMSTR", "LMRST"],
      correct_answer: 0,
    },
    {
      question:
        "If ‘CARING’ is coded as ‘EDVGKC’, and ‘SHARES’ is coded as ‘UKEPBO’, then how will ‘CASKET’ be coded as in the same code?",
      options: ["EDXIBP", "EDWIAP", "EDWPAI", "EDWIBP"],
      correct_answer: 3,
    },
    {
      question:
        "In a certain code BELIEF is coded as AFKKDH. How would SELDOM be written in that code?",
      options: ["RDKCHL", "RFKENM", "RFKFNO", "TFKENP", "None of these"],
      correct_answer: 2,
    },
    {
      question:
        "If MACHINE is coded as ’19-7-9-14-15-20-11’, then how will you code DANGER in the same code?",
      options: [
        "11-7-20-16-11-24",
        "13-7-20-9-11-25",
        "10-7-20-13-11-24",
        "13-7-10-11-25",
      ],
      correct_answer: 3,
    },
    {
      question:
        "In a certain language SHORE is coded as QFMPC. In the same code ___________ will be coded as WNKGL.",
      options: ["NIMPY", "YPMIN", "ULIEJ", "ULIJE", "JEILU"],
      correct_answer: 1,
    },
    {
      question:
        "In a certain code language the word COSTLY is written as WORVMF and the word PRAISE is written as CVGDPS. How will the word SOCCER be written in that code language?",
      options: ["PHAFMV", "BJWQRA", "PGAENU", "AKXPS"],
      correct_answer: 0,
    },
    {
      question:
        "In a certain code SUBSTITUTION is written as ITSBUSNOITUT. How is DISTRIBUTION written in that code?",
      options: ["IRTDISNOIUTB", "IRTSIDNOIBUT", "IRTDISNOITUB", "IRTSIDNOITUB"],
      correct_answer: 3,
    },
    {
      question:
        "In a certain code language GERMINATION is written as IMGRENNOAIT. How is ESTABLISHED written in that code?",
      options: ["BATESLDEIHS", "BAETSLDEIHS", "BAETSLEDIHS", "BEATSLDEIHS"],
      correct_answer: 1,
    },
    {
      question:
        "If DEMOCRATICS is written as EDMORCATCI, then how CONTINUOUS will be written in the same code?",
      options: [
        "OCTNNIOUSU",
        "OCTNINUOUS",
        "OCNTNIUOSU",
        "OTNCINUOSU",
        "CONNITUOSU",
      ],
      correct_answer: 2,
    },
    {
      question:
        "In a certain code language COMPUTRONE is written as PMOCTUENOR. How is ADVANTAGES written in the same code?",
      options: [
        "IDUJLAIC",
        "UJIDLAIC",
        "UJIDICLA",
        "IDUJICLA",
        "None of these",
      ],
      correct_answer: 4,
    },
    {
      question:
        "In a certain code if DEMOCRACY is written as YCARCOMED, then how will the word PRESIDENT be coded?",
      options: ["EIETPRSDN", "NOSRPTEIE", "TNEDISERP", "RSDNPEIET"],
      correct_answer: 2,
    },
    {
      question:
        "In a certain code PATHOLOGIST is written as PIUBQKSRHFN. How is CONTROVERSY written in that code?",
      options: [
        "SUOPDNXRQDU",
        "SUOPDNZTSFW",
        "QSMNBPXRQDU",
        "QSMNBPZTSFW",
        "None of these",
      ],
      correct_answer: 0,
    },
    {
      question:
        "In a certain code language FRAME is written as KAPJB. How is BLOCK written in that code?",
      options: ["SQHFE", "QSHEF", "OQJCD", "QOJDC", "None of these"],
      correct_answer: 3,
    },
    {
      question:
        "In a certain code language SHORT is written as ITOUS and DWARF is written as XEAGS. How is MANGO written in that code?",
      options: ["NBNHP", "BNNPH", "BNNHP", "LBNPF", "None of these"],
      correct_answer: 1,
    },
    {
      question:
        "In a certain code CONFUSED is written as EMNBEFTV. How is SECLUDED written in that code?",
      options: [
        "RDBKEFEV",
        "KBDRCDCT",
        "KBDREFEV",
        "MDFTCDCT",
        "None of these",
      ],
      correct_answer: 2,
    },
    {
      question:
        "In a certain code TEMPORAL is written as OLDSMBSP. How is CONSIDER written in that code?",
      options: [
        "RMNBSFEJ",
        "BNMRSFEJ",
        "RMNBJEFS",
        "TOPDQDCH",
        "None of these",
      ],
      correct_answer: 0,
    },
    {
      question:
        "If ‘green’ means ‘yellow’; ‘yellow’ means ‘white’; ‘white’ means ‘red’; ‘red’ means ‘violet’; ‘violet’ means ‘black’; then which of the following will be the colour of human blood?",
      options: ["Red", "Black", "Green", "White", "None of these"],
      correct_answer: 4,
    },
    {
      question:
        "If ‘white’ is called ‘red’; ‘red’ is called ‘blue’; ‘blue’ is called ‘yellow’; ‘yellow’ is called ‘black’; ‘black’ is called ‘green’ and ‘green is called ‘grey’, then what is the colour of clear sky?",
      options: ["red", "blue", "yellow", "grey", "None of these"],
      correct_answer: 2,
    },
    {
      question:
        "In a certain code TREAD is written as ‘7%#94’ and PREY is written as ‘$%#8’. How is ARTERY written in that code?",
      options: ["9#7%#8", "9#%7#8", "9%7#8", "9%#7%8"],
      correct_answer: 2,
    },
    {
      question: "What does ‘ke’ stand for?",
      options: ["been", "has", "merit", "name", "list"],
      correct_answer: 4,
    },
    {
      question: "What is the code for ‘idea’?",
      options: ["fo", "la", "bu", "na", "Either bu or na"],
      correct_answer: 0,
    },
    {
      question: "Which of the following represents ‘name has been displayed’?",
      options: [
        "ya la ke si",
        "jo na ya la",
        "si jo ke na",
        "buy a ke la",
        "ya si jo zo",
      ],
      correct_answer: 1,
    },
    {
      question: "What does ‘zo’ stand for?",
      options: ["There", "Displayed", "Name", "Her", "Cannot be determined"],
      correct_answer: 0,
    },
    {
      question: "Which of the following may represent ‘her name is there’?",
      options: [
        "zo ya go wo",
        "bu ya zo go",
        "zo ya bu ke",
        "ya zo jo bu",
        "wo go zo ya",
      ],
      correct_answer: 3,
    },
    {
      question: "What is the code for ‘in’?",
      options: ["na", "ya", "go", "ke", "Cannot be determined"],
      correct_answer: 2,
    },
    {
      question: "What is the code for ‘him’?",
      options: ["ga", "ve", "ja", "se", "Cannot be determined"],
      correct_answer: 3,
    },
    {
      question: "What does ‘bi’ stand for?",
      options: ["Profit", "Order", "Place", "For", "Now"],
      correct_answer: 2,
    },
    {
      question: "‘fo ve du’ could be a code for which of the following?",
      options: [
        "in right spirits",
        "only in profit",
        "order only him",
        "place in right",
        "order only now",
      ],
      correct_answer: 0,
    },
    {
      question: "What is the code for ‘profit’?",
      options: ["ye", "ga", "bi", "ja", "ho"],
      correct_answer: 4,
    },
    {
      question: "Which of the following may represent ‘only for now’?",
      options: ["ja bi zo", "du zo ga", "zo ga ja", "zo ga ye", "du bi ja"],
      correct_answer: 2,
    },
  ],
};

const AllTestSeries = () => {
  const {
    isOpen: isquizOpen,
    onOpen: onquizOpen,
    onClose: onquizClose,
  } = useDisclosure();
  const {
    isOpen: isResultOpen,
    onOpen: onResultOpen,
    onClose: onResultClose,
  } = useDisclosure();
  const [currentQuiz, setCurrentQuiz] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(20 * 60);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const cancelRef = React.useRef();
  const [resultMessage, setResultMessage] = React.useState("");
  const [currentQuizType, setCurrentQuizType] = useState(null);
  const [quizState, setQuizState] = useState({
    gd1: { isDisabled: false, timeRemaining: 0 },
    gd2: { isDisabled: false, timeRemaining: 0 },
    ipsclerk1: { isDisabled: false, timeRemaining: 0 },
    ipsclerk2: { isDisabled: false, timeRemaining: 0 },
    cgl1: { isDisabled: false, timeRemaining: 0 },
    chsl1: { isDisabled: false, timeRemaining: 0 },
    chsl2: { isDisabled: false, timeRemaining: 0 },
    ibpspo1: { isDisabled: false, timeRemaining: 0 },
    mts1: { isDisabled: false, timeRemaining: 0 },
    rrbntpc01: { isDisabled: false, timeRemaining: 0 },
    rrbntpc02: { isDisabled: false, timeRemaining: 0 },
    ch02: { isDisabled: false, timeRemaining: 0 },
  });

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [courses, setCourses] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo?.token;
  const userid = userInfo?.user?.id;

  // Initialize cooldown timers
  useEffect(() => {
    const quizzes = [
      "gd1",
      "gd2",
      "ipsclerk1",
      "ipsclerk2",
      "cgl1",
      "chsl1",
      "chsl2",
      "ibpspo1",
      "mts1",
      "rrbntpc01",
      "rrbntpc02",
      "ch02",
      "sover",
    ];
    quizzes.forEach((quiz) => {
      const lastAttempt = localStorage.getItem(`lastAttemptTime_${quiz}`);
      if (lastAttempt) {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastAttempt;
        const remainingTime = 24 * 60 * 60 * 1000 - elapsedTime; // 24 hours in milliseconds
        if (remainingTime > 0) {
          setQuizState((prev) => ({
            ...prev,
            [quiz]: { isDisabled: true, timeRemaining: remainingTime },
          }));
        }
      }
    });
  }, []);

  useEffect(() => {
    const quizzes = Object.keys(quizState);

    quizzes.forEach((quiz) => {
      const lastAttempt = localStorage.getItem(`lastAttemptTime_${quiz}`);
      if (lastAttempt) {
        const updateCountdown = () => {
          const currentTime = Date.now();
          const timeElapsed = currentTime - parseInt(lastAttempt, 10);
          const remainingTime = 24 * 60 * 60 * 1000 - timeElapsed;

          if (remainingTime > 0) {
            setQuizState((prev) => ({
              ...prev,
              [quiz]: {
                isDisabled: true,
                timeRemaining: remainingTime,
              },
            }));
          } else {
            setQuizState((prev) => ({
              ...prev,
              [quiz]: {
                isDisabled: false,
                timeRemaining: 0,
              },
            }));
            localStorage.removeItem(`lastAttemptTime_${quiz}`);
          }
        };

        const countdownInterval = setInterval(updateCountdown, 1000);

        return () => clearInterval(countdownInterval);
      }
    });
  }, [quizState]);

  // Update quiz timer
  useEffect(() => {
    let timer;
    if (timeLeft > 0 && isquizOpen) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      handleFinishQuiz(); // Auto-submit the quiz when time is up
    }
    return () => clearInterval(timer);
  }, [timeLeft, isquizOpen]);

  const handleQuizStart = (quizType) => {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      toast({
        title: "Please log in to start the quiz.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      window.location.href = "/login";
      return;
    }

    const quiz = quizzes[quizType]; // Assuming quizzes is defined somewhere
    if (quiz && Array.isArray(quiz)) {
      setCurrentQuiz(quiz);
      setCurrentQuizType(quizType); // Set the current quiz type
      setCurrentQuestionIndex(0);
      setUserAnswers([]);
      setTimeLeft(3600); // Start the 20-minute timer
      setStartTime(new Date());
      onquizOpen();
    } else {
      setCurrentQuiz([]);
    }
  };

  const handleAnswer = (index) => {
    setUserAnswers((prevAnswers) => [...prevAnswers, index]);
    if (currentQuestionIndex < (currentQuiz || []).length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleFinishQuiz();
    }
  };

  const handleFinishQuiz = () => {
    setLoading(true);
    setEndTime(new Date());

    setTimeout(() => {
      setLoading(false);
      onquizClose();
      calculateResults();

      if (currentQuizType) {
        // Ensure currentQuizType is defined
        // Start 24-hour cooldown after quiz submission
        localStorage.setItem(`lastAttemptTime_${currentQuizType}`, Date.now());
        setQuizState((prev) => ({
          ...prev,
          [currentQuizType]: {
            isDisabled: true,
            timeRemaining: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
          },
        }));
      }
    }, 500); // Simulate loading delay
  };

  const resetQuizState = () => {
    setCurrentQuiz(null);
    setCurrentQuestionIndex(0);
    setUserAnswers([]);
    setTimeLeft(3600);
    setStartTime(null);
    setEndTime(null);
  };

  const calculateResults = () => {
    if (
      !currentQuiz ||
      !Array.isArray(currentQuiz) ||
      !Array.isArray(userAnswers)
    ) {
      return;
    }

    const correctAnswers = currentQuiz.filter((question, index) => {
      return question.answer === userAnswers[index];
    });

    const totalQuestions = currentQuiz.length;
    const numCorrect = correctAnswers.length;
    const numWrong = totalQuestions - numCorrect;

    const usedTime = endTime - startTime; // Time used in milliseconds
    const minutes = Math.floor(usedTime / 60000);
    const seconds = ((usedTime % 60000) / 1000).toFixed(0);
    const formattedTime =
      minutes > 0
        ? `${minutes} minute${minutes > 1 ? "s" : ""} and ${seconds} second${
            seconds !== 1 ? "s" : ""
          }`
        : `${seconds} second${seconds !== 1 ? "s" : ""}`;

    const message = `Total Questions: ${totalQuestions}\nCorrect Answers: ${numCorrect}\nWrong Answers: ${numWrong}\nTotal Time: ${formattedTime}`;

    setResultMessage(message);
    onResultOpen();
  };

  const formatTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading && (
        <Center height="100vh">
          <Spinner size="xl" />
        </Center>
      )}
      <Box style={{ maxWidth: "100%", margin: "0 auto", padding: "20px" }}>
        <div className="row" style={{ width: "100%", paddingTop: "50px" }}>
          <Text textAlign="center" fontSize="xx-large" fontWeight="700">
            Top Quizzes
          </Text>
          <>
            <div
              className="col-md-3"
              style={{
                marginBottom: "10px",
                border: "1px solid black",
                padding: "10px",
                borderRadius: "20px",
              }}
            >
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.gd1.isDisabled ? (
                <Center>
                  <Text>
                    Try again in: {formatTime(quizState.gd1.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("gd1")}>
                    Start gd1 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.gd2.isDisabled ? (
                <Center>
                  <Text>
                    Try again in: {formatTime(quizState.gd2.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("gd2")}>
                    Start gd2 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.ipsclerk1.isDisabled ? (
                <Center>
                  <Text>
                    Try again in:{" "}
                    {formatTime(quizState.ipsclerk1.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("ipsclerk1")}>
                    Start ipsclerk1 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.ipsclerk2.isDisabled ? (
                <Center>
                  <Text>
                    Try again in:{" "}
                    {formatTime(quizState.ipsclerk2.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("ipsclerk2")}>
                    Start ipsclerk2 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.chsl1.isDisabled ? (
                <Center>
                  <Text>
                    Try again in: {formatTime(quizState.chsl1.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("chsl1")}>
                    Start chsl1 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.chsl2.isDisabled ? (
                <Center>
                  <Text>
                    Try again in: {formatTime(quizState.chsl2.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("chsl2")}>
                    Start chsl2 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.ibpspo1.isDisabled ? (
                <Center>
                  <Text>
                    Try again in: {formatTime(quizState.ibpspo1.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("ibpspo1")}>
                    Start ibpspo1 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.mts1.isDisabled ? (
                <Center>
                  <Text>
                    Try again in: {formatTime(quizState.mts1.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("mts1")}>
                    Start mts1 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.rrbntpc01.isDisabled ? (
                <Center>
                  <Text>
                    Try again in:{" "}
                    {formatTime(quizState.rrbntpc01.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("rrbntpc01")}>
                    Start rrbntpc01 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.rrbntpc02.isDisabled ? (
                <Center>
                  <Text>
                    Try again in:{" "}
                    {formatTime(quizState.rrbntpc02.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("rrbntpc02")}>
                    Start rrbntpc02 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.cgl1.isDisabled ? (
                <Center>
                  <Text>
                    Try again in: {formatTime(quizState.cgl1.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("cgl1")}>
                    Start cgl1 Quiz
                  </Button>
                </Center>
              )}
            </div>
            <div className="col-md-3" style={{ marginBottom: "10px" }}>
              <Center>
                <Image
                  src={testimage}
                  alt="Science Quiz"
                  boxSize="150px"
                  objectFit="cover"
                  borderRadius="md"
                />
              </Center>
              {quizState.ch02.isDisabled ? (
                <Center>
                  <Text>
                    Try again in: {formatTime(quizState.ch02.timeRemaining)}
                  </Text>
                </Center>
              ) : (
                <Center>
                  <Button onClick={() => handleQuizStart("ch02")}>
                    Start ch01 Quiz
                  </Button>
                </Center>
              )}
            </div>
          </>
        </div>
        <div className="col-md-3" style={{ marginBottom: "10px" }}>
          <Center>
            <Image
              src={testimage}
              alt="Science Quiz"
              boxSize="150px"
              objectFit="cover"
              borderRadius="md"
            />
          </Center>
          {quizState.gd2.isDisabled ? (
            <Center>
              <Text>
                Try again in: {formatTime(quizState.sover.timeRemaining)}
              </Text>
            </Center>
          ) : (
            <Center>
              <Button onClick={() => handleQuizStart("sover")}>
                Start sover Quiz
              </Button>
            </Center>
          )}
        </div>
      </Box>

      <Modal isOpen={isquizOpen} onClose={onquizClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Quiz</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentQuiz && currentQuiz.length > 0 ? (
              <>
                <Text>
                  {`Question ${currentQuestionIndex + 1}: ${
                    currentQuiz[currentQuestionIndex].question
                  }`}
                </Text>
                <Box>
                  {currentQuiz[currentQuestionIndex].options.map(
                    (option, index) => (
                      <Button
                        key={index}
                        onClick={() => handleAnswer(index)}
                        variant="outline"
                        size="sm"
                        margin="5px"
                      >
                        {option}
                      </Button>
                    )
                  )}
                </Box>
                <Text mt={4}>
                  Time Left: {Math.floor(timeLeft / 60)}:{timeLeft % 60}
                </Text>
              </>
            ) : (
              <Text>No quiz questions available.</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onquizClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isResultOpen}
        leastDestructiveRef={cancelRef}
        onClose={onResultClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Quiz Results
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>{resultMessage}</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onResultClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default AllTestSeries;
