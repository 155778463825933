import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Image,
  Button,
  VStack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";

const Checkout = () => {
  const location = useLocation();
  const toast = useToast();
  const { courseId, price, currency, image } = location.state || {};
  const [amount, setAmount] = useState(price || "");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo?.token;
  const userId = userInfo.user.id;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo) {
      setCustomerEmail(userInfo.user.email || "");
      setCustomerPhone(userInfo.user.phone || "");
    }
  }, [userInfo]);

  function loadRazorpayScript() {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handlePayment = async () => {
    const res = await loadRazorpayScript();

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const orderResponse = await axios.post(
      `${mod.api_url}/api/course/ordercreate`,
      {
        amount: price,
        user: userId,
        course: courseId,
      }
    );
    const { orderId, amount, currency } = orderResponse.data;
    const options = {
      key: "rzp_live_C6ORZK7qoBd4pI",
      amount: amount,
      currency: currency,
      name: "Genius Grid",
      description: "Test Transaction",
      order_id: orderId,
      handler: async function (response) {
        const paymentResponse = await axios.post(
          `${mod.api_url}/api/course/payment-verification`,
          {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
          }
        );

        if (paymentResponse.data.success) {
          toast({
            title: "Payment successful!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate(`/user-dashboard`);
        } else {
          toast({
            title: "Payment verification failed!",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      },
      prefill: {
        name: userInfo?.user?.name,
        email: userInfo?.user?.email,
        contact: userInfo?.user?.phone,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const confirmPayment = () => {
    setLoading(true);
    handlePayment();
    setLoading(false);
    onClose();
  };

  return (
    <>
      <Box
        width="100%"
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <VStack spacing={4} align="center" textAlign="center">
          <Image src={image} alt={`Course ${courseId}`} boxSize="150px" />
          <Text fontSize="lg" fontWeight="bold">
            Course ID: {courseId}
          </Text>
          <Text fontSize="md" color="gray.600">
            Price: {currency} {amount}
          </Text>
          <Text>
            Are you sure you want to proceed with the payment to purchase this
            course?
          </Text>
          <Button onClick={onOpen} colorScheme="blue">
            Confirm Now
          </Button>
        </VStack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Your Payment</ModalHeader>
          <ModalBody>
            <Text>
              Are you sure you want to proceed with the payment to purchase this
              course?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={confirmPayment}
              isLoading={loading}
              ml={3}
            >
              Confirm & Pay
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Checkout;
