import React, { useEffect, useState } from "react";
import "./Conact.css";
import { Box, Button, FormLabel, Input, useToast } from "@chakra-ui/react";
import conactbanner from "./../Assets/contact-us1.jpg";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import dialler from "./../Assets/dialler.png";
import mail from "./../Assets/mail.png";
import whatsapp from "./../Assets/whatsapp.png";
import { Link } from "react-router-dom";
import sucessStroy from "./../Assets/success-story.jpg";
import axios from "axios";
import * as mod from "./../../url";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

const Conact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dec, setDec] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("dec", dec);

      const { data } = await axios.post(
        `${mod.api_url}/api/contact/creat_contacts`,
        formData
      );
      console.log("Feedback create success", data);
      toast({
        title: "enquiry send successfully!",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      setName("");
      setEmail("");
      setDec("");
      setMobile("");
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error occurred.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box position="fixed" top="0" left="0" right="0" zIndex="sticky">
        <Navbar />
      </Box>{" "}
      <Box marginTop="90px">
        <div>
          <div className="contact-banner">
            <img src={conactbanner} />
          </div>

          <section className="test-series-container">
            <div className="test-grid">
              <div className="card">
                <div className="test-box">
                  <Link to="tel:+919430715717">
                    <img src={dialler} alt="Quiz 1" className="quiz-icon" />
                    <Button
                      marginRight={2}
                      colorScheme="red"
                      color="black"
                      marginTop={2}
                      sx={{
                        _hover: {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      }}
                    >
                      +91 9430715717
                    </Button>
                  </Link>
                </div>
              </div>

              <div className="card">
                <div className="test-box">
                  <Link to="mailto:info@geniusgrid.in">
                    <img src={mail} alt="Quiz 2" className="quiz-icon" />
                    <Button
                      marginRight={2}
                      colorScheme="red"
                      color="black"
                      marginTop={2}
                      sx={{
                        _hover: {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      }}
                    >
                      info@geniusgrid.in
                    </Button>{" "}
                  </Link>
                </div>
              </div>
              <div className="card">
                <div className="test-box">
                  <Link to="https://wa.me/919430715717">
                    <img src={whatsapp} alt="Quiz 3" className="quiz-icon" />
                    <Button
                      marginRight={2}
                      colorScheme="red"
                      color="black"
                      marginTop={2}
                      sx={{
                        _hover: {
                          backgroundColor: "blue",
                          color: "white",
                        },
                      }}
                    >
                      +91 9430715717
                    </Button>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <ul
            className="social-media-platforms"
            style={{
              listStyleType: "none",
              padding: 0,
              display: "flex",
              justifyContent: "center",
              gap: "20px",
            }}
          >
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61561614774455&mibextid=ZbWKwL"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", fontSize: "24px" }}
              >
                <FaFacebookF style={{ width: "60px", height: "auto" }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", fontSize: "24px" }}
              >
                <FaTwitter style={{ width: "60px", height: "auto" }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/genuisgrid?igsh=MWd2M201aHZzYThuMg=="
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", fontSize: "24px" }}
              >
                <FaInstagram style={{ width: "60px", height: "auto" }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/rahul-kumar-banerjee-9aa40a258?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black", fontSize: "24px" }}
              >
                <FaLinkedinIn style={{ width: "60px", height: "auto" }} />
              </a>
            </li>
          </ul>
        </div>

        <div className="row" style={{ width: "100%" }}>
          <div className="col-md-12">
            {" "}
            <form className="contact-form">
              <FormLabel>Name:</FormLabel>
              <Input
                type="text"
                id="name"
                name="name"
                required
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <FormLabel>Email:</FormLabel>
              <Input
                type="email"
                id="email"
                name="email"
                required
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormLabel>Mobile:</FormLabel>
              <Input
                type="mobile"
                id="mobile"
                name="mobile"
                required
                placeholder="Enter your mobile"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />

              <FormLabel>Message:</FormLabel>
              <textarea
                id="message"
                name="message"
                rows="4"
                required
                placeholder="Enter your message"
                value={dec}
                onChange={(e) => setDec(e.target.value)}
              ></textarea>

              <Button colorScheme="green" onClick={handleSubmit}>
                Send Feedback
              </Button>
            </form>
          </div>
        </div>
      </Box>
      <Footer />
    </>
  );
};

export default Conact;
