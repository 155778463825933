import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  useToast,
  Image,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Center,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url";
import { useParams } from "react-router-dom";
import Navbar from "./../Navbar/Navbar";
import Footer from "./../Footer/Footer";
import ReactPlayer from "react-player";
import { FaFilePdf } from "react-icons/fa";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Import PDF viewer styles

const LearnCourse = () => {
  const [courses, setCourses] = useState("");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [loading, setLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo?.token;
  const params = useParams();
  const courseId = params.slug;

  const purchasedCourses = async () => {
    if (!token) {
      toast({
        title: "Unauthorized",
        description: "You must be logged in to view your purchased courses.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(
        `${mod.api_url}/api/orders/user/orders/${courseId}`,
        config
      );
      if (data) {
        setCourses(data);
      } else {
        toast({
          title: "Error fetching courses.",
          description: data.message || "Something went wrong!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
      toast({
        title: "Error fetching courses.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    purchasedCourses();
  }, []);

  // const handlePdfOpen = (pdfUrl) => {
  //   window.open(pdfUrl, "_blank", "noopener,noreferrer");
  // };

  const renderStars = (rating) => {
    const clampedRating = Math.max(0, Math.min(5, rating));
    const fullStars = Math.floor(clampedRating);
    const halfStar = clampedRating % 1 !== 0;
    const emptyStars = 5 - Math.ceil(clampedRating);

    return (
      <>
        {Array.from({ length: fullStars }, (_, index) => (
          <FaStar key={`full-${index}`} color="gold" />
        ))}
        {halfStar && <FaStarHalfAlt color="gold" />}
        {Array.from({ length: emptyStars }, (_, index) => (
          <FaRegStar key={`empty-${index}`} color="gold" />
        ))}
      </>
    );
  };

  const handlePdfOpen = (pdfUrl) => {
    setSelectedPdf(pdfUrl);
    onOpen(); // Open the modal when a PDF is selected
  };

  return (
    <>
      <Navbar />
      <Box p={5} maxW="1200px" mx="auto">
        {courses?.course ? (
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            <GridItem colSpan={[12, 6]}>
              <Image
                src={courses?.course?.image}
                alt={courses?.course?.name}
                boxSize="100%"
                objectFit="cover"
                borderRadius="md"
                shadow="md"
              />
            </GridItem>
            <GridItem colSpan={[12, 6]}>
              <VStack align="start" spacing={4}>
                <Heading as="h2" size="xl">
                  {courses?.course?.name}
                </Heading>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color="red"
                  textDecoration="line-through"
                >
                  Original Price Price: ₹{courses?.course?.price} (INR)
                </Text>
                <Text fontSize="lg" fontWeight="bold" color="green">
                  Purchse Price: ₹{courses?.amount} (INR)
                </Text>

                {courses?.course?.rating ? (
                  <>
                    <Text fontSize="lg" fontWeight="bold" display="flex">
                      Rating: {renderStars(courses?.course?.rating)}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text fontSize="lg" fontWeight="bold" display="flex">
                      Rating: {courses?.course?.rating}
                    </Text>
                  </>
                )}
                {/* <Text fontSize="lg" fontWeight="bold">
                  Rating: {renderStars(courses?.course?.rating)} / 5
                </Text> */}
                <Text>{courses?.course?.description}</Text>
                <br />

                <>
                  {/* Button to show each PDF */}
                  {courses?.course?.pdf && courses?.course?.pdf.length > 0 && (
                    <>
                      {courses?.course?.pdf.map((pdfUrl, index) => (
                        <Button
                          key={index}
                          width="100%"
                          height="50px"
                          colorScheme="teal"
                          mt={4}
                          onClick={() => handlePdfOpen(pdfUrl)}
                        >
                          <FaFilePdf
                            style={{
                              width: "20px",
                              height: "auto",
                              marginRight: "10px",
                            }}
                          />
                          View Course PDF {index + 1}
                        </Button>
                      ))}
                    </>
                  )}

                  {/* Modal for displaying the PDF */}
                  <Modal isOpen={isOpen} onClose={onClose} size="full">
                    <ModalOverlay />
                    <ModalContent>
                      <ModalHeader>View PDF</ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        {selectedPdf && (
                          <Worker
                            workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
                          >
                            <Viewer
                              fileUrl={selectedPdf}
                              plugins={[]} // No extra plugins to block download options
                            />
                          </Worker>
                        )}
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </>

                {courses?.course?.videourl && (
                  <Box mt={4} w="100%">
                    <Heading as="h3" size="lg" mb={2}>
                      Course Video
                    </Heading>
                    <ReactPlayer
                      url={courses?.course?.videourl}
                      controls={true}
                      width="100%"
                    />
                  </Box>
                )}
              </VStack>
            </GridItem>
          </Grid>
        ) : (
          <Center height="400px">
            <Spinner size="xl" />
          </Center>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default LearnCourse;
