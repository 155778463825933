import React, { useState } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Profile from "./Profile";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import LiveClass from "./LiveClass";
import Feedback from "./Feedback";
import Refferl from "./Refferl";
import AllTestSeries from "./AllTestSeries";

const Home = () => {
  const [selectedSection, setSelectedSection] = useState("dashboard");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const renderContent = () => {
    switch (selectedSection) {
      case "dashboard":
        return <Dashboard />;
      case "profile":
        return <Profile />;
      case "liveclass":
        return <LiveClass />;
      case "feedback":
        return <Feedback />;
      case "refferl":
        return <Refferl />;
      case "setting":
        return <Settings />;
      case "test-seris":
        return <AllTestSeries />;

      default:
        return <Dashboard />;
    }
  };

  return (
    <>
      {/* Fixed Header */}
      <Box position="fixed" top="0" left="0" right="0" zIndex="sticky">
        <Navbar />
      </Box>

      <Flex>
        {/* Mobile Hamburger Icon */}
        <IconButton
          aria-label="Open menu"
          icon={<HamburgerIcon />}
          display={{ base: "block", md: "none" }}
          onClick={onOpen}
          position="fixed"
          top="100px"
          left="20px"
          zIndex="overlay"
        />

        {/* Sidebar for Desktop */}
        <Box
          width="20%"
          bg="gray.200"
          p={4}
          boxShadow="md"
          display={{ base: "none", md: "block" }}
          position="fixed"
          top="100px" // Adjust this value according to the Navbar height
          left="0"
          height="calc(100vh - 100px)" // Adjust according to Navbar height
          overflowY="auto"
        >
          <Button
            width="100%"
            colorScheme="green"
            mb={4}
            onClick={() => {
              setSelectedSection("dashboard");
              onClose();
            }}
          >
            Dashboard
          </Button>
          <Button
            width="100%"
            colorScheme="green"
            mb={4}
            onClick={() => {
              setSelectedSection("profile");
              onClose();
            }}
          >
            Profile
          </Button>
          <Button
            width="100%"
            colorScheme="green"
            mb={4}
            onClick={() => {
              setSelectedSection("liveclass");
              onClose();
            }}
          >
            Live Class
          </Button>
          <Button
            width="100%"
            colorScheme="green"
            mb={4}
            onClick={() => {
              setSelectedSection("feedback");
              onClose();
            }}
          >
            Feedback
          </Button>
          <Button
            width="100%"
            colorScheme="green"
            mb={4}
            onClick={() => {
              setSelectedSection("refferl");
              onClose();
            }}
          >
            Referal
          </Button>
          <Button
            width="100%"
            colorScheme="green"
            mb={4}
            onClick={() => {
              setSelectedSection("test-seris");
              onClose();
            }}
          >
            All Test Series
          </Button>
          <Button
            width="100%"
            colorScheme="green"
            mb={4}
            onClick={() => {
              setSelectedSection("setting");
              onClose();
            }}
          >
            Settings
          </Button>

          {/* Add more buttons for different sections */}
        </Box>

        {/* Main Content */}
        <Box
          flex="1"
          ml={{ base: 0, md: "20%" }}
          pt="100px" // Adjust according to Navbar height
          p={6}
          marginTop="100px"
          overflowY="auto"
          maxHeight="calc(100% - 100px)" // Adjust according to Navbar height
        >
          {renderContent()}
        </Box>
      </Flex>

      {/* Drawer for Mobile */}
      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            <Button
              width="100%"
              mb={4}
              onClick={() => {
                setSelectedSection("dashboard");
                onClose();
              }}
            >
              Dashboard
            </Button>
            <Button
              width="100%"
              colorScheme="green"
              mb={4}
              onClick={() => {
                setSelectedSection("profile");
                onClose();
              }}
            >
              Profile
            </Button>
            <Button
              width="100%"
              colorScheme="green"
              mb={4}
              onClick={() => {
                setSelectedSection("liveclass");
                onClose();
              }}
            >
              Live Class
            </Button>
            <Button
              width="100%"
              colorScheme="green"
              mb={4}
              onClick={() => {
                setSelectedSection("feedback");
                onClose();
              }}
            >
              Feedback
            </Button>
            <Button
              width="100%"
              colorScheme="green"
              mb={4}
              onClick={() => {
                setSelectedSection("test-seris");
                onClose();
              }}
            >
              All Test Series
            </Button>
            <Button
              width="100%"
              colorScheme="green"
              mb={4}
              onClick={() => {
                setSelectedSection("refferl");
                onClose();
              }}
            >
              Referal
            </Button>
            <Button
              width="100%"
              colorScheme="green"
              mb={4}
              onClick={() => {
                setSelectedSection("setting");
                onClose();
              }}
            >
              Settings
            </Button>

            {/* Add more buttons for different sections */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Home;
