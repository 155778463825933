import {
  Box,
  Text,
  Heading,
  Divider,
  Flex,
  Avatar,
  Stack,
  Badge,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import React from "react";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
// console.log(userInfo, 'userInfo')
const Profile = () => {
  if (!userInfo || !userInfo.user) {
    return <Text color="red.500">No user data found.</Text>;
  }

  return (
    <Box maxWidth="800px" mx="auto" p={4}>
      <Heading as="h2" size="lg" mb={4}>
        User Profile
      </Heading>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Profile</Td>
            <Td>
              {" "}
              <Avatar src="https://bit.ly/broken-link" />
            </Td>
          </Tr>
          <Tr>
            <Td>Email</Td>
            <Td>{userInfo.user.email}</Td>
          </Tr>
          <Tr>
            <Td>First Name</Td>
            <Td>{userInfo.user.firstName}</Td>
          </Tr>
          <Tr>
            <Td>Last Name</Td>
            <Td>{userInfo.user.lastName}</Td>
          </Tr>
          <Tr>
            <Td>Mobile</Td>
            <Td>{userInfo.user.mobile}</Td>
          </Tr>
          <Tr>
            <Td>State</Td>
            <Td>{userInfo.user.state}</Td>
          </Tr>
          <Tr>
            <Td>Education</Td>
            <Td>{userInfo.user.education}</Td>
          </Tr>
          <Tr>
            <Td>City</Td>
            <Td>{userInfo.user.city}</Td>
          </Tr>
          <Tr>
            <Td>Pincode</Td>
            <Td>{userInfo.user.pincode}</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default Profile;
