import React, { useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
  List,
  ListItem,
  Text,
  Divider,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url";

const Feedback = () => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [des, setDes] = useState("");
  const [img, setImg] = useState(null);
  const [feedbackList, setFeedbackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.token;

  const toast = useToast();
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImg(file);
  };
  const handleSubmit = async () => {
    setLoading(true);

    if (!token) {
      toast({
        title: "Unauthorized",
        description: "You must be logged in to submit feedback.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const formData = new FormData();
      formData.append("name", name);
      formData.append("mobile", mobile);
      formData.append("feedback", des);
      formData.append("img", img);

      const { data } = await axios.post(
        `${mod.api_url}/api/feedback/create_feedback`,
        formData,
        config
      );
      console.log("Feedback create success", data);
      toast({
        title: "Feedback created successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setName("");
      setMobile("");
      setImg("");
      setDes("");
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error occurred.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box maxWidth="100%" height="100vh" p={4}>
      <Heading as="h1" mb={6}>
        Feedback
      </Heading>
      <VStack spacing={4} mb={6}>
        <FormControl id="name" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder="Your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormControl>
        <FormControl id="mobile" isRequired>
          <FormLabel>Mobile</FormLabel>
          <Input
            placeholder="Your Mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </FormControl>
        <FormControl id="img" isRequired>
          <FormLabel>Screen Shot</FormLabel>
          <Input
            type="file"
            placeholder="Your ScreenShot"
            onChange={handleFileChange}
            style={{ padding: "3px" }}
          />
        </FormControl>
        <FormControl id="feedback" isRequired>
          <FormLabel>Feedback</FormLabel>
          <Textarea
            placeholder="Your feedback"
            value={des}
            onChange={(e) => setDes(e.target.value)}
          />
        </FormControl>
        <Button colorScheme="blue" onClick={handleSubmit}>
          Submit Feedback
        </Button>
      </VStack>
      <Divider mb={6} />
      {/* <Heading as="h2" size="lg" mb={4}>
        Your Past Feedback
      </Heading>
      <List spacing={3}>
        {feedbackList.length === 0 ? (
          <Text>No feedback submitted yet.</Text>
        ) : (
          feedbackList.map((item, index) => (
            <ListItem key={index} border="1px" borderRadius="md" p={3}>
              <Text fontWeight="bold">{item.name}</Text>
              <Text fontSize="sm" color="gray.500">
                {item.date}
              </Text>
              <Text mt={2}>{item.feedback}</Text>
            </ListItem>
          ))
        )}
      </List> */}
    </Box>
  );
};

export default Feedback;
