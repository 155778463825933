import React, { useEffect, useState } from "react";
import sciencequiz from "./../Assets/sciencequiz.jpg";
import sscquiz from "./../Assets/sscquiz.jpg";
import mathquiz from "./../Assets/mathsquiz.jpg";
import bankingquiz from "./../Assets/bankingquiz.jpg";
import {
  Box,
  Center,
  Image,
  Text,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

const quizzes = {
  science: [
    {
      question: "Which one is matter?",
      options: ["Bag", "Love", "Dream", "Ambition"],
      answer: 0,
    },
    {
      question: "What is the biggest planet in the solar system?",
      options: ["Mars", "Venus", "Jupiter", "Saturn"],
      answer: 2,
    },
    {
      question: "Which is not part of the plants?",
      options: ["Roots", "Trunk", "Wings", "Leaves"],
      answer: 2,
    },
    {
      question: "These are parts of the eye except ______.",
      options: ["Retina", "Cornea", "Eyelid", "Cilia"],
      answer: 2,
    },
    {
      question: "Which is an example of solid?",
      options: ["Oxygen", "Water", "Bottle", "Ink"],
      answer: 2,
    },
    {
      question: "What is the SI unit (International System of Units) of speed?",
      options: ["M/s", "Cm/kg", "Km/h", "V/A"],
      answer: 2,
    },
    {
      question: "What is the process by which plants make their food?",
      options: [
        "Transpiration",
        "Photosynthesis",
        "Translocation",
        "Perspiration",
      ],
      answer: 1,
    },
    {
      question: "Plants do not breathe.",
      options: ["True", "False"],
      answer: 1,
    },
    {
      question: "What type of objects can we see through?",
      options: ["Opaque", "Translucent", "Transparent", "Any"],
      answer: 2,
    },
    {
      question: "Which one of these is not a microorganism?",
      options: ["Termite", "Bacteria", "Fungi", "Protozoa"],
      answer: 0,
    },
    {
      question: "Distance is a ________________ quantity.",
      options: ["Scalar", "Vector"],
      answer: 0,
    },
    {
      question: "Which non-metal is the most reactive?",
      options: ["Fluorine", "Oxygen", "Carbon", "Lead"],
      answer: 0,
    },
    {
      question: "Which of the following is the most ductile element?",
      options: ["Iron", "Copper", "Gold", "Carbon"],
      answer: 2,
    },
    {
      question: "What state of matter is ice found in?",
      options: ["Solid", "Liquid", "Gas", "Plasma"],
      answer: 0,
    },
    {
      question: "If a body covers a full circle, then the displacement is:",
      options: ["1", "0", "Distance", "Not sufficient data"],
      answer: 1,
    },
    {
      question:
        "If an atom has a charge of +1, which of the following must be true?",
      options: [
        "It has the same number of protons as electrons.",
        "It has two more protons than neutrons.",
        "It has one more electron than it does protons.",
        "It has one more proton than it does electrons.",
      ],
      answer: 3,
    },
    {
      question:
        "Which of the following properties of water is NOT affected by the strong cohesion between H2O molecules?",
      options: [
        "Heat Of vaporization",
        "Specfic heat",
        "Surface tension",
        "Ph",
      ],
      answer: 3,
    },
    {
      question:
        "A chemical reaction in which the product has more potential chemical energy than the reactants is called endergonic.",
      options: ["True", "False"],
      answer: 0,
    },
    {
      question:
        "One atom named Stan shares electrons with Betty. Between them they share 4 electrons. What kind of bond do they share?",
      options: ["Single Bond", "Valance Bond", "Double Bond", "Bond Of Love"],
      answer: 2,
    },
    {
      question: "When Sodium loses an electron to chlorine it becomes?",
      options: [
        "A cation",
        "As mad as @#$#@%^&",
        "An Anion",
        "Negatively charged",
      ],
      answer: 0,
    },
    {
      question:
        "Which one of the following is NOT a component of each monomer used to make proteins? a carboxyl group, COOHa side chain, Ra phosphorous atom, Pan amino functional group, NH2",
      options: [
        "A carboxyl group, COOH",
        "A side chain, R",
        "A phosphorous atom, P",
        "An amino functional group, NH2",
      ],
      answer: 2,
    },
    {
      question:
        "Why does a person sitting in a moving bus suddenly move forward when the bus suddenly stops?",
      options: [
        "Due to inertia",
        "Due to brakes",
        "Due to negative acceleration",
        "Due to the force exerted by the machine",
      ],
      answer: 0,
    },
    {
      question: "A person sitting in a moving car has _________energy.",
      options: [
        "Kinetic energy",
        "Potential energy",
        "Both kinetic and potential energy",
        "Elastic potential energy",
      ],
      answer: 2,
    },
    {
      question: "What are Jacobson's organs?",
      options: [
        "Ability to generate tables",
        "Speed of calculation",
        "Flexibility of moving entries",
        "Cost of initial setup",
      ],
      answer: 3,
    },
    {
      question:
        "Why on hitting the ball with the center of the bat more effective short is played?",
      options: [
        "Fx + fy= o",
        "The center of gravity remained within its base.",
        "It produces more torque.",
        "Both A and B",
      ],
      answer: 2,
    },
    {
      question:
        "Which toolbar allows you to create graphics in Microsoft Word?",
      options: ["Standard", "Formatting", "Tables", "Drawing"],
      answer: 3,
    },
    {
      question: "What does 'RAM' stand for?",
      options: [
        "Read Access Memory",
        "Random Access Memory",
        "Run Access Memory",
        "Random Allocation Memory",
      ],
      answer: 1,
    },
    {
      question:
        "What language does a web browser primarily use to display web pages?",
      options: ["HTML", "Java", "Python", "C++"],
      answer: 0,
    },
    {
      question: "What type of software is used to control computer hardware?",
      options: [
        "Application software",
        "System software",
        "Development software",
        "Performance software",
      ],
      answer: 1,
    },
  ],
  ssc: [
    {
      question:
        "The first Indian to receive the U Thant award for cultural understanding was ?",
      options: [
        "Indira Gandhi",
        "None of these",
        "Acharya Vinoba Bhave",
        "Jawaharlal Nehru",
      ],
      answer: 2,
    },
    {
      question:
        "Indian constitution has taken the concept of single citizenship from-",
      options: ["UK", "Ireland", "Australia", "None of these"],
      answer: 0,
    },
    {
      question: "WTO- headquarter located at-",
      options: ["UK", "GERMANY", "GENEVA", "AUSTRALIA"],
      answer: 2,
    },
    {
      question: "SAARC was founded in",
      options: ["None of these", "Dhaka", "Kathmandu", "New Delhi"],
      answer: 2,
    },
    {
      question:
        "In a certain code, BASIC is written as DDULE. How is LEADER written in that code?",
      options: ["NGCFGT", "OHDGHU", "OGDFHT", "NHCGGU"],
      answer: 2,
    },
    {
      question:
        "Which is best used as a sound absorbing material in partition walls ?",
      options: ["Glass-wool", "Glass pieces", "Stone chips", "Steel"],
      answer: 2,
    },
    {
      question: "Air pressure is usually highest when the air is ?",
      options: ["Elasticity", "Surface tension", "cool and moist", "Friction"],
      answer: 2,
    },
    {
      question: "G-7 describing by-",
      options: [
        "None of these",
        "Non-Industrialized Countries",
        "Industrialized countries",
        "Who can launch their own satellites",
      ],
      answer: 2,
    },
    {
      question:
        "If HARD is coded as 1357 and SOFT is coded as 2468, what do the figures 21448 stand for ?",
      options: ["SHOOT", "SCOOL", "SHOOL", "SHOOP"],
      answer: 2,
    },
    {
      question: "The headquarter of the ‘ICRC’ located at",
      options: ["India", "None of these", "Rome", "Geneva"],
      answer: 2,
    },
    {
      question: "INTERPOL means-",
      options: [
        "None of these",
        "International Political Conference",
        "International Criminal Police Organization",
        "International Association of Police Officers",
      ],
      answer: 2,
    },
    {
      question: "How many total seats are there in Rajya Sabha?",
      options: ["None of these", "260", "257", "250"],
      answer: 2,
    },
    {
      question: "Rajya Sabha seats are allotted on the basis of-",
      options: [
        "None of these",
        "Population",
        "Both A & B",
        "Area of the state",
      ],
      answer: 2,
    },
    {
      question: "If SUMMER is coded RUNNER, the code for WINTER-",
      options: ["SUITER", "WALKER", "VIOUER", "SUFFER"],
      answer: 2,
    },
    {
      question: "Nuclear sizes are expressed in a unit named ?",
      options: ["Fermi", "Newton", "Angstrom", "Tesla"],
      answer: 2,
    },
    {
      question:
        "Which of the following countries is not a member of the G-8 group?",
      options: ["United Kingdom", "France", "Japan", "Spain"],
      answer: 3,
    },
    {
      question: "Rajya Sabha dissolve by-",
      options: [
        "Chairman of Rajya Sabha",
        "None of these",
        "Prime Minister",
        "President",
      ],
      answer: 2,
    },
    {
      question:
        "In a certain language, WEAK is coded as 9%2$ and SKIT is coded as #$7@, then how will WAIT be coded in the same language?",
      options: ["92@6", "927@", "@926", "9@26"],
      answer: 2,
    },
    {
      question:
        "In a certain code language, GERMINATION is written as IMGRENNOAIT. How is ESTABLISHED written in that code?",
      options: ["BATESLDEIHS", "BAETSLEDIHS", "BAETSLDEIHS", "BEATSLDEIHS"],
      answer: 2,
    },
    {
      question:
        "Which of the following awards was conferred on Mrs. Kiran Bedi ?",
      options: ["Magsaysay", "Rani Lakshmi", "Golden Globe", "Saraswati"],
      answer: 2,
    },
    {
      question: "B.C.Roy Award is given in the field of ?",
      options: ["Environment", "Medicine", "Music", "Journalism"],
      answer: 2,
    },
    {
      question: "The most suitable unit for expressing nuclear radius is ?",
      options: ["Angstrom", "Nanometre", "Micro", "Fermi"],
      answer: 2,
    },
    {
      question:
        "In a certain code, CLOUD is written as GTRKF. How is SIGHT written in that code?",
      options: ["UHJFW", "WGJHV", "WFJGV", "UGHHT"],
      answer: 2,
    },
    {
      question:
        "If A = 4, K = 3, N = 2, P = 1, then the sum of which set of the letters makes the highest number?",
      options: ["KANPK", "NPAKN", "NAKNA", "PKANA"],
      answer: 2,
    },
    {
      question: "Skating on ice is possible because ?",
      options: [
        "Ice is cold",
        "The pressure on the ice due to skate melts the ice by lowering its melting point forming a thin film of water",
        "The Pressure on the ice due to skate raises its melting point",
        "The surface of ice is smooth",
      ],
      answer: 1,
    },
  ],
  math: [
    {
      question:
        "The decimal expansion of the rational number 23/(22 . 5) will terminate after",
      options: [
        "one decimal place",
        "two decimal places",
        "three decimal places",
        "more than 3 decimal places",
      ],
      answer: 2,
    },
    {
      question: "Given: Velocity = 60 miles per hour",
      options: ["n", "n + 1", "2n + 1", "2n"],
      answer: 2,
    },
    {
      question: "Which of the following is not irrational?",
      options: ["(3 + √7)", "(3 – √7)", "(3 + √7) (3 – √7)", "3√7"],
      answer: 2,
    },
    {
      question:
        "The least number that is divisible by all the numbers from 1 to 5 is:",
      options: ["70", "60", "80", "90"],
      answer: 1,
    },
    {
      question: "The sum or difference of two irrational numbers is always",
      options: [
        "irrational",
        "Rational Numbers",
        "Natural numbers",
        "Complex numbers",
      ],
      answer: 0,
    },
    {
      question: "The prime factorisation of 96 is",
      options: ["25 × 3", "26", "24 × 3", "24 × 32"],
      answer: 2,
    },
    {
      question: "The volume of a 1cm cube is",
      options: ["1 cm cube", "11 cm cube", "3 cm cube", "none of these"],
      answer: 0,
    },
    {
      question: "Profit = ?",
      options: ["S. P – C.P", "C.P. – S.P", "Loss + C.P", "none of these"],
      answer: 2,
    },
    {
      question: "The price at which an articles is bought is its",
      options: ["Cost Price", "Selling price", "Profit", "none of these"],
      answer: 0,
    },
    {
      question:
        "Rahim bought a table for 2,500 and sold, it for 3,000. How much profit did he earn?",
      options: ["500", "1500", "300", "none of these"],
      answer: 2,
    },
    {
      question:
        "An event is very unlikely to happen. Its probability is closest to",
      options: ["0.0001", "0.001", "0.01", "0.1"],
      answer: 2,
    },
    {
      question:
        "A card is drawn from a deck of 52 cards. The event E is that card is not an ace of hearts. The number of outcomes favourable to E is:",
      options: ["4", "13", "48", "51"],
      answer: 2,
    },
    {
      question:
        "The relationship between mean, median and mode for a moderately skewed distribution is",
      options: [
        "mode = median – 2 mean",
        "mode = 3 median – 2 mean",
        "mode = 2 median – 3 mean",
        "mode = median – mean",
      ],
      answer: 2,
    },
    {
      question:
        "Mode and mean of a data are 12k and 15A. Median of the data is",
      options: ["12k", "14k", "15k", "16k"],
      answer: 1,
    },
    {
      question:
        "Perimeter of a sector of a circle whose central angle is 90° and radius 7 cm is",
      options: ["35 cm", "25 cm", "77 cm", "7 cm"],
      answer: 2,
    },
    {
      question:
        "In a circle of diameter 42 cm, if an arc subtends an angle of 60° at the centre, where π = 22/7 then length of arc is",
      options: ["11 cm", "1 cm", "22 cm", "44 cm"],
      answer: 2,
    },
    {
      question: "sin (45° + θ) – cos (45° – θ) is equal to",
      options: ["2 cos θ", "0", "2 sin θ", "1"],
      answer: 2,
    },
    {
      question: "If √2 sin (60° – α) = 1 then α is",
      options: ["45°", "15°", "60°", "30°"],
      answer: 2,
    },
    {
      question:
        "If the surface areas of two spheres are in ratio 16 : 9, then their volumes will be in the ratio:",
      options: ["27 : 64", "64 : 27", "4 : 3", "3 : 4"],
      answer: 2,
    },
    {
      question:
        "A cylinder, a cone and a hemisphere are of equal base and have the same height. What is the ratio of their volumes?",
      options: ["3 : 1 : 2", "3 : 2 : 1", "1 : 2 : 3", "1 : 3 : 2"],
      answer: 2,
    },
    {
      question:
        "From a point P which is at a distance of 13 cm from the point O of a circle of radius 5 cm, the pair of tangents PQ and PR to the circle are drawn. Then the area of the quadrilateral PQOR is",
      options: ["60 cm²", "65 cm²", "30 cm²", "32.5 cm²"],
      answer: 2,
    },
    {
      question:
        "A person is flying a kite at a height of 30 m from the horizontal level. The length of string from the kite to the person is 60 m. Assuming that there is no slack in the string, the angle of elevation of kite to the horizontal level is:",
      options: ["45°", "30°", "60°", "90°"],
      answer: 2,
    },
  ],
  banking: [
    {
      question: "We should keep our savings with banks because",
      options: [
        "It is safe",
        "Earns interest",
        "Can be withdrawn anytime",
        "All of above",
      ],
      answer: 3,
    },
    {
      question: "Bank does not give loan against",
      options: ["Gold Ornaments", "LIC policy", "Lottery ticket", "NSC"],
      answer: 0,
    },
    {
      question: "Bank having maximum number of branches in India",
      options: [
        "Reserve Bank of India",
        "State Bank of India",
        "Punjab National Bank",
        "Bank of Baroda",
      ],
      answer: 1,
    },
    {
      question: "100/- Rupee note is signed by",
      options: [
        "Prime Minister",
        "Finance Minister",
        "RBI Governor",
        "None of above",
      ],
      answer: 2,
    },
    {
      question: "ATM password should be kept in",
      options: ["Personal diary", "Office diary", "Memory", "All of above"],
      answer: 2,
    },
    {
      question: "ATM password to be shared only with",
      options: ["Spouse", "Obedient son", "Obedient daughter", "None of above"],
      answer: 3,
    },
    {
      question: "Nomination can be done in",
      options: [
        "Savings Bank account",
        "Recurring Deposit account",
        "Fixed Deposit account",
        "All of above",
      ],
      answer: 0,
    },
    {
      question: "Minimum age required to open SB account in the bank",
      options: ["8 years", "10 years", "12 years", "None of above"],
      answer: 1,
    },
    {
      question: "KYC means",
      options: [
        "Know your customer",
        "Know your character",
        "Both of above",
        "None of above",
      ],
      answer: 0,
    },
    {
      question: "Loans from money lenders are",
      options: [
        "With High rate of interest",
        "No proper accounting",
        "No transparency",
        "All of above",
      ],
      answer: 0,
    },
    {
      question: "ATM means",
      options: [
        "Any Time Money",
        "Auto Truck of Mahindra",
        "Automated Teller Machine",
        "None of above",
      ],
      answer: 2,
    },
    {
      question: "Defaulter of loan means",
      options: [
        "Not paying loan instalments",
        "Bad reputation",
        "Illegal activities",
        "None of above",
      ],
      answer: 0,
    },
    {
      question: "Life insurance means",
      options: [
        "Insurance of human",
        "Insurance of life of human and cattle",
        "Insurance of Life of Machines",
        "All of above",
      ],
      answer: 0,
    },
    {
      question: "Coins issued by",
      options: [
        "Government of India",
        "NABARD",
        "Public sector banks",
        "State Bank of India",
      ],
      answer: 0,
    },
    {
      question: "Aadhaar is",
      options: [
        "12 digit card number",
        "Identity proof issued by UIDAI",
        "Both (a) & (b)",
        "None of above",
      ],
      answer: 0,
    },
    {
      question: "Bank provides loans for",
      options: ["Home", "Car", "Education", "All of above"],
      answer: 0,
    },
    {
      question: "Currency notes are issued by",
      options: ["RBI", "NABARD", "Public sector banks", "Central Government"],
      answer: 0,
    },
    {
      question: "PAN number is required for",
      options: [
        "Deposits less than Rs. 50,000/-",
        "Deposits in excess of Rs.1 lac",
        "Deposits in excess of Rs.50,000 and above",
        "All transactions",
      ],
      answer: 2,
    },
    {
      question: "TDS means",
      options: [
        "Time Deposits Scheme",
        "Total deposit Scheme",
        "Tax deducted at source",
        "None of above",
      ],
      answer: 2,
    },
    {
      question: "Under PMSBY, accidental death claim is available for:",
      options: ["Rs1 lac", "Rs2 lac", "Rs3 lac", "None of above"],
      answer: 0,
    },
  ],
};

const QuizComponents = () => {
  const {
    isOpen: isquizOpen,
    onOpen: onquizOpen,
    onClose: onquizClose,
  } = useDisclosure();
  const {
    isOpen: isResultOpen,
    onOpen: onResultOpen,
    onClose: onResultClose,
  } = useDisclosure();
  const [currentQuiz, setCurrentQuiz] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [timeLeft, setTimeLeft] = useState(20 * 60);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const cancelRef = React.useRef();
  const [resultMessage, setResultMessage] = React.useState("");
  const [currentQuizType, setCurrentQuizType] = useState(null);
  const [quizState, setQuizState] = useState({
    science: { isDisabled: false, timeRemaining: 0 },
    ssc: { isDisabled: false, timeRemaining: 0 },
    math: { isDisabled: false, timeRemaining: 0 },
    banking: { isDisabled: false, timeRemaining: 0 },
  });

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  // Initialize cooldown timers
  useEffect(() => {
    const quizzes = ["science", "ssc", "math", "banking"];
    quizzes.forEach((quiz) => {
      const lastAttempt = localStorage.getItem(`lastAttemptTime_${quiz}`);
      if (lastAttempt) {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastAttempt;
        const remainingTime = 24 * 60 * 60 * 1000 - elapsedTime; // 24 hours in milliseconds
        if (remainingTime > 0) {
          setQuizState((prev) => ({
            ...prev,
            [quiz]: { isDisabled: true, timeRemaining: remainingTime },
          }));
        }
      }
    });
  }, []);

  useEffect(() => {
    const quizzes = Object.keys(quizState);

    quizzes.forEach((quiz) => {
      const lastAttempt = localStorage.getItem(`lastAttemptTime_${quiz}`);
      if (lastAttempt) {
        const updateCountdown = () => {
          const currentTime = Date.now();
          const timeElapsed = currentTime - parseInt(lastAttempt, 10);
          const remainingTime = 24 * 60 * 60 * 1000 - timeElapsed;

          if (remainingTime > 0) {
            setQuizState((prev) => ({
              ...prev,
              [quiz]: {
                isDisabled: true,
                timeRemaining: remainingTime,
              },
            }));
          } else {
            setQuizState((prev) => ({
              ...prev,
              [quiz]: {
                isDisabled: false,
                timeRemaining: 0,
              },
            }));
            localStorage.removeItem(`lastAttemptTime_${quiz}`);
          }
        };

        const countdownInterval = setInterval(updateCountdown, 1000);

        return () => clearInterval(countdownInterval);
      }
    });
  }, [quizState]);

  // Update quiz timer
  useEffect(() => {
    let timer;
    if (timeLeft > 0 && isquizOpen) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      handleFinishQuiz(); // Auto-submit the quiz when time is up
    }
    return () => clearInterval(timer);
  }, [timeLeft, isquizOpen]);

  const handleQuizStart = (quizType) => {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      toast({
        title: "Please log in to start the quiz.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      window.location.href = "/login";
      return;
    }

    const quiz = quizzes[quizType];
    if (quiz && Array.isArray(quiz)) {
      setCurrentQuiz(quiz);
      setCurrentQuizType(quizType);
      setCurrentQuestionIndex(0);
      setUserAnswers([]);
      setTimeLeft(1200);
      setStartTime(new Date());
      onquizOpen();
    } else {
      setCurrentQuiz([]);
    }
  };

  const handleAnswer = (index) => {
    setUserAnswers((prevAnswers) => [...prevAnswers, index]);
    if (currentQuestionIndex < (currentQuiz || []).length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleFinishQuiz();
    }
  };

  const handleFinishQuiz = () => {
    setLoading(true);
    setEndTime(new Date());

    setTimeout(() => {
      setLoading(false);
      onquizClose();
      calculateResults();

      if (currentQuizType) {
        // Ensure currentQuizType is defined
        // Start 24-hour cooldown after quiz submission
        localStorage.setItem(`lastAttemptTime_${currentQuizType}`, Date.now());
        setQuizState((prev) => ({
          ...prev,
          [currentQuizType]: {
            isDisabled: true,
            timeRemaining: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
          },
        }));
      }
    }, 500); // Simulate loading delay
  };

  const resetQuizState = () => {
    setCurrentQuiz(null);
    setCurrentQuestionIndex(0);
    setUserAnswers([]);
    setTimeLeft(1200);
    setStartTime(null);
    setEndTime(null);
  };

  const calculateResults = () => {
    if (
      !currentQuiz ||
      !Array.isArray(currentQuiz) ||
      !Array.isArray(userAnswers)
    ) {
      return;
    }

    const correctAnswers = currentQuiz.filter((question, index) => {
      return question.answer === userAnswers[index];
    });

    const totalQuestions = currentQuiz.length;
    const numCorrect = correctAnswers.length;
    const numWrong = totalQuestions - numCorrect;

    const usedTime = endTime - startTime; // Time used in milliseconds
    const minutes = Math.floor(usedTime / 60000);
    const seconds = ((usedTime % 60000) / 1000).toFixed(0);
    const formattedTime =
      minutes > 0
        ? `${minutes} minute${minutes > 1 ? "s" : ""} and ${seconds} second${
            seconds !== 1 ? "s" : ""
          }`
        : `${seconds} second${seconds !== 1 ? "s" : ""}`;

    const message = `Total Questions: ${totalQuestions}\nCorrect Answers: ${numCorrect}\nWrong Answers: ${numWrong}\nTotal Time: ${formattedTime}`;

    setResultMessage(message);
    onResultOpen();
  };

  const formatTime = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {loading && (
        <Center height="100vh">
          <Spinner size="xl" />
        </Center>
      )}
      <Box style={{ maxWidth: "100%", margin: "0 auto", padding: "20px" }}>
        <div className="row" style={{ width: "100%", paddingTop: "50px" }}>
          <Text textAlign="center" fontSize="xx-large" fontWeight="700">
            Top Quizzes
          </Text>

          <div className="col-md-3">
            <Center>
              <Image
                src={sciencequiz}
                alt="Science Quiz"
                boxSize="150px"
                objectFit="cover"
                borderRadius="md"
              />
            </Center>
            {quizState.science.isDisabled ? (
              <Center>
                <Text>
                  Try again in: {formatTime(quizState.science.timeRemaining)}
                </Text>
              </Center>
            ) : (
              <Center>
                <Button onClick={() => handleQuizStart("science")}>
                  Start Science Quiz
                </Button>
              </Center>
            )}
          </div>
          <div className="col-md-3">
            <Center>
              <Image
                src={sscquiz}
                alt="SSC Quiz"
                boxSize="150px"
                objectFit="cover"
                borderRadius="md"
              />
            </Center>
            {quizState.ssc.isDisabled ? (
              <Center>
                <Text>
                  Try again in: {formatTime(quizState.ssc.timeRemaining)}
                </Text>
              </Center>
            ) : (
              <Center>
                <Button onClick={() => handleQuizStart("ssc")}>
                  Start SSC Quiz
                </Button>
              </Center>
            )}
          </div>
          <div className="col-md-3">
            <Center>
              <Image
                src={mathquiz}
                alt="Math Quiz"
                boxSize="150px"
                objectFit="cover"
                borderRadius="md"
              />
            </Center>
            {quizState.math.isDisabled ? (
              <Center>
                <Text>
                  Try again in: {formatTime(quizState.math.timeRemaining)}
                </Text>
              </Center>
            ) : (
              <Center>
                <Button onClick={() => handleQuizStart("math")}>
                  Start Math Quiz
                </Button>
              </Center>
            )}
          </div>
          <div className="col-md-3">
            <Center>
              <Image
                src={bankingquiz}
                alt="Banking Quiz"
                boxSize="150px"
                objectFit="cover"
                borderRadius="md"
              />
            </Center>
            {quizState.banking.isDisabled ? (
              <Center>
                <Text>
                  Try again in: {formatTime(quizState.banking.timeRemaining)}
                </Text>
              </Center>
            ) : (
              <Center>
                <Button onClick={() => handleQuizStart("banking")}>
                  Start Banking Quiz
                </Button>
              </Center>
            )}
          </div>
        </div>
      </Box>
      <Modal isOpen={isquizOpen} onClose={onquizClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Quiz</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentQuiz && currentQuiz.length > 0 ? (
              <>
                <Text>
                  {`Question ${currentQuestionIndex + 1}: ${
                    currentQuiz[currentQuestionIndex].question
                  }`}
                </Text>
                <Box>
                  {currentQuiz[currentQuestionIndex].options.map(
                    (option, index) => (
                      <Button
                        key={index}
                        onClick={() => handleAnswer(index)}
                        variant="outline"
                        size="sm"
                        margin="5px"
                      >
                        {option}
                      </Button>
                    )
                  )}
                </Box>
                <Text mt={4}>
                  Time Left: {Math.floor(timeLeft / 60)}:{timeLeft % 60}
                </Text>
              </>
            ) : (
              <Text>No quiz questions available.</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onquizClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={isResultOpen}
        leastDestructiveRef={cancelRef}
        onClose={onResultClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Quiz Results
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>{resultMessage}</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onResultClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default QuizComponents;
