import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Text,
  Image,
  useToast,
  Button,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url";
import { useParams, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const CoursesDetails = () => {
  const [course, setCourse] = useState(null);
  const toast = useToast();
  const params = useParams();
  const navigate = useNavigate();
  const courseId = params.slug;

  const fetchCourseDetails = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/course/get_course/${courseId}`
      );
      if (data.Course) {
        setCourse(data.Course);
      } else {
        console.error("Error fetching course details:", "No data found");
      }
    } catch (error) {
      console.error("Error fetching course details:", error);
      toast({
        title: "Error fetching course details.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (courseId) {
      fetchCourseDetails();
    }
  }, [courseId]);

  const renderStars = (rating) => {
    if (typeof rating !== "number" || rating < 0 || rating > 5) {
      return null;
    }

    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 !== 0 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <>
        {[...Array(fullStars)].map((_, index) => (
          <FaStar key={index} color="gold" />
        ))}
        {[...Array(halfStars)].map((_, index) => (
          <FaStarHalfAlt key={index} color="gold" />
        ))}
        {[...Array(emptyStars)].map((_, index) => (
          <FaRegStar key={index} color="gold" />
        ))}
      </>
    );
  };

  const calculateDiscountedPrice = (price, offer) => {
    if (typeof price !== "number" || typeof offer !== "number") {
      console.error("Invalid price or offer value");
      return price;
    }
    return price - (price * offer) / 100;
  };

  const isOfferValid = (offerDate) => {
    if (!offerDate) return false;
    const today = new Date();
    const offerEndDate = new Date(offerDate);
    return today <= offerEndDate;
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);

  const handleBuyNow = () => {
    const priceToPay = isOfferValid(course.offerDate)
      ? calculateDiscountedPrice(course.price, course.offers)
      : course.price;

    navigate(`/checkout/${course.slug}`, {
      state: {
        courseId: course._id,
        price: priceToPay,
        currency: course.currency,
        image: course.image,
      },
    });
  };

  return (
    <>
      <Navbar />
      <Container maxW="container.xl" p={4}>
        {course ? (
          <div className="row">
            <div className="col-md-6">
              <Image
                src={course.image || "placeholder.jpg"}
                alt={course.name}
                width={{ base: "100%", md: "100%" }}
                height={{ base: "auto", md: "500px" }}
                objectFit="cover"
              />
            </div>
            <div className="col-md-6">
              <Text fontSize="3xl" fontWeight="bold" textAlign="left">
                Course: {course.name}
              </Text>
              <Box
                style={{
                  fontSize: "xl",
                  maxHeight: "350px",
                  overflowY: "auto",
                  padding: "1",
                  border: "1px solid lightgray",
                  borderRadius: "md",
                  padding: "10px",
                }}
              >
                <VStack align="left" display="inline">
                  <Text
                    fontSize="xl"
                    fontWeight="bold"
                    textAlign="left"
                    display="flex"
                  >
                    Price:
                    <Text as="s" color="red" marginLeft="5px">
                      {" "}
                      {course.price} {course.currency}
                    </Text>
                    {isOfferValid(course.offerDate) && (
                      <p style={{ marginLeft: "10px", color: "green" }}>
                        {" "}
                        {calculateDiscountedPrice(
                          course.price,
                          course.offers
                        ).toFixed(2)}{" "}
                        {course.currency}
                      </p>
                    )}
                  </Text>
                </VStack>
                <Text fontSize="small" fontWeight="bold" textAlign="left">
                  Offer Date: {course.offerDate?.slice(0, 10)}
                </Text>
                <Text fontSize="small" fontWeight="bold" textAlign="left">
                  Offers: {course.offers}%
                </Text>
                <Text
                  fontSize="small"
                  fontWeight="bold"
                  textAlign="left"
                  display="flex"
                >
                  Rating: {renderStars(course?.rating)}
                </Text>
                <Box>
                  <Text fontSize="xl" fontWeight="bold" textAlign="left">
                    Description:
                  </Text>
                  {course.description?.split(";").map((part, index) => (
                    <React.Fragment key={index}>
                      <ul typeof="circle">
                        <li style={{ textAlign: "left" }}>{part}</li>
                      </ul>
                    </React.Fragment>
                  ))}{" "}
                </Box>
              </Box>
              <Button
                colorScheme="green"
                marginTop={1}
                width="100%"
                onClick={handleBuyNow}
              >
                Checkout
              </Button>
            </div>
          </div>
        ) : (
          <Text>Loading...</Text>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default CoursesDetails;
