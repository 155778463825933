import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Heading,
  Divider,
  Flex,
  VStack,
  HStack,
  StackDivider,
  Button,
  IconButton,
  useToast,
  Link,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  useBreakpointValue,
  Center,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import axios from "axios";
import * as mod from "./../../url";

const LiveClass = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 30;
  const toast = useToast();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo.token;
  useEffect(() => {
    setTotalPages(Math.ceil(data.length / itemsPerPage));
  }, [data]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLinks = data?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Navigate to the next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getliveclass = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `${mod.api_url}/api/auth/user/user/Class_url`,
        config
      );
      setData(res.data);
      console.log();
    } catch (error) {}
  };

  useEffect(() => {
    getliveclass();
  }, []);

  return (
    <Box maxWidth="100%" mx="auto" p={4}>
      <Heading as="h1" mb={6}>
        Live Class Links
      </Heading>
      <VStack spacing={4} align="stretch">
        {currentLinks.map((link, index) => (
          <Card key={index} p={4} shadow="md" borderWidth="1px">
            <CardHeader>
              <Text
                fontSize="lg"
                fontWeight="bold"
                background="whitesmoke"
                borderRadius="30px"
              >
                Date:{new Date(link.createdAt).toLocaleDateString()} <br />
                Time:{link.createdAt.slice(11, 16)}
              </Text>
            </CardHeader>
            <CardBody border="1px solid #c9c9c9" borderRadius="30px">
              <Text mb={2}>
                <strong>Class Name:</strong> {link.coursename}
              </Text>
              <Text mb={2}>
                <strong>Group:</strong> {link.classname}
              </Text>
            </CardBody>
            <CardFooter>
              <Link
                href={link.liveClassUrl}
                isExternal
                color="blue.500"
                fontWeight="bold"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button colorScheme="blue" width="100%">
                  Join Class
                </Button>
              </Link>
            </CardFooter>
          </Card>
        ))}
      </VStack>
      <HStack justifyContent="space-between" mt={4}>
        <IconButton
          background="green"
          color="black"
          icon={<ArrowBackIcon />}
          onClick={handlePrevPage}
          isDisabled={currentPage === 1}
        />
        <Text>
          Page {currentPage} of {totalPages}
        </Text>
        <IconButton
          background="green"
          color="black"
          icon={<ArrowForwardIcon />}
          onClick={handleNextPage}
          isDisabled={currentPage === totalPages}
        />
      </HStack>
    </Box>
  );
};

export default LiveClass;
