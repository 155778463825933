import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  HStack,
  useToast,
  Image,
  Button,
  Input,
  Spinner,
  Center,
} from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const token = userInfo?.token;
  const userid = userInfo?.user?.id;
  const navigate = useNavigate();

  const purchasedCourses = async () => {
    if (!token) {
      toast({
        title: "Unauthorized",
        description: "You must be logged in to view your purchased courses.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          userid: userid,
        },
      };

      const { data } = await axios.get(
        `${mod.api_url}/api/orders/orders`,
        config
      );

      if (data) {
        // Filter out courses with status "PAID" and ensure valid course data
        const validCourses = data.filter(
          (course) =>
            course.course && course.course._id && course.status === "PAID"
        );
        setCourses(validCourses);
        setFilteredCourses(validCourses); // Initialize the filtered courses with all valid courses
      } else {
        toast({
          title: "Error fetching courses.",
          description: data.message || "Something went wrong!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error fetching courses.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    purchasedCourses();
  }, []);

  useEffect(() => {
    // Filter courses based on the search query
    const result = courses.filter((course) =>
      course.course.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCourses(result);
  }, [searchQuery, courses]);

  const viewCourse = (orderId) => {
    navigate(`/learn_course/${orderId}`);
  };

  return (
    <Box maxWidth="1200px" mx="auto" p={4}>
      <Box mb={6}>
        <Heading as="h3" size="md" mb={4}>
          Purchased Courses
        </Heading>
        <Input
          placeholder="Search by course name..."
          mb={6}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <Grid
          templateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={6}
        >
          {filteredCourses?.length > 0 ? (
            filteredCourses?.map((course) => (
              <GridItem
                key={course._id}
                bg="white"
                boxShadow="md"
                borderRadius="md"
                overflow="hidden"
              >
                <Image
                  src={course?.course?.image}
                  alt={course?.course?.name}
                  width="100%"
                  height="200px"
                  objectFit="cover"
                  padding="15px"
                />
                <Box p={4}>
                  <Heading as="h4" size="md" mb={2}>
                    {course?.course?.name}
                  </Heading>
                  <HStack justifyContent="space-between" mb={2}>
                    <Text
                      fontWeight="bold"
                      textDecoration="line-through"
                      color="red"
                      fontSize="small"
                      // textDecoration={
                      //   course.status === "PENDING" ? "line-through" : "none"
                      // }
                    >
                      Total Amount: {course?.course?.price} {course?.currency}
                    </Text>
                    <Text fontWeight="bold" fontSize="small">
                      Paid: {course?.amount} {course?.currency}
                    </Text>
                    <Text fontSize="small" color="gray.500">
                      Purchased on:{" "}
                      {new Date(course?.course?.createdAt).toLocaleDateString()}
                    </Text>
                  </HStack>
                  {course?.status === "PENDING" ? (
                    <Text color="red.500" mb={2}>
                      Payment Pending
                    </Text>
                  ) : (
                    <Button
                      width="100%"
                      colorScheme="green"
                      onClick={() => {
                        if (userInfo) {
                          viewCourse(course?.orderId);
                        } else {
                          toast({
                            title: "Please log in to view this course.",
                            status: "error",
                            duration: 2000,
                            isClosable: true,
                          });
                          window.location.href = "/login";
                        }
                      }}
                    >
                      Learn Now
                    </Button>
                  )}
                </Box>
              </GridItem>
            ))
          ) : (
            <Box height="100vh">
              {" "}
              <Center height="400px">
                <Spinner size="xl" />
              </Center>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Profile;
