import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
} from "@chakra-ui/react";
import axios from "axios";
import * as mod from "./../../url"; // Make sure to adjust the import path as needed

const Settings = () => {
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    // Check if all fields are filled for OTP generation
    if (email && mobile) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [email, mobile]);

  const handlePasswordToggle = () => setShowPassword(!showPassword);

  const generateOTP = async () => {
    if (!isFormValid) return;

    setLoading(true);
    try {
      const { data } = await axios.post(
        `${mod.api_url}/api/auth/user/generate-otp`,
        { mobile, email, mode: "reset password" }
      );
      toast({
        title: "OTP Sent",
        description: "Check your email or mobile for the OTP.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onOpen();
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Error generating OTP",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const submitHandler = async () => {
    if (!otp || !password) {
      toast({
        title: "Error",
        description: "OTP and Password are required.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    try {
      await axios.post(`${mod.api_url}/api/auth/user/forgot-password`, {
        email,
        mobile,
        otp,
        newPassword: password,
      });
      toast({
        title: "Password updated successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Reset form fields
      setEmail("");
      setMobile("");
      setOtp("");
      setPassword("");
      onClose();
    } catch (error) {
      toast({
        title: "Error occurred.",
        description:
          error.response?.data?.message || "Failed to update password!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box maxWidth="100%" height="100vh" p={4}>
        <Heading as="h1" mb={6}>
          Settings
        </Heading>{" "}
        <Divider mb={4} />
        <Heading as="h1" mb={6}>
          Reset Your Password
        </Heading>
        <FormControl id="email" isRequired mb={4}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl id="mobile" isRequired mb={4}>
          <FormLabel>Mobile</FormLabel>
          <Input
            type="tel"
            placeholder="Enter your mobile number"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </FormControl>
        <Button
          colorScheme="blue"
          isFullWidth
          onClick={generateOTP}
          isLoading={loading}
          isDisabled={!isFormValid}
        >
          Generate OTP
        </Button>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Change Password</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl id="otp" isRequired mb={4}>
                <FormLabel>OTP</FormLabel>
                <Input
                  placeholder="Enter the OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </FormControl>
              <FormControl id="password" isRequired mb={4}>
                <FormLabel>New Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handlePasswordToggle}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={submitHandler}
                isLoading={loading}
              >
                Update Password
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default Settings;
